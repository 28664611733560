import { apiClass } from '@/common/class/api.class'
import { ENDPOINTS } from '@/common/const/endpoints.const'
import { RESPONSE_STATUS } from '@/common/const/response.const'

const state = {
  loading: false
}
const mutations = {
  SET_TERMS_LOADING (state, value) {
    state.loading = value
  }
}
const actions = {
  setUserTermsAgree ({ commit }) {
    commit('SET_TERMS_LOADING', true)
    const data = {
      regulationsAgreed: true
    }
    return apiClass.put(ENDPOINTS.user.setTermsAgree, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        commit('SET_TERMS_LOADING', false)
        return Promise.reject(error)
      })
  }
}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
