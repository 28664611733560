import { ToolsClass } from '@/common/class/tools.class'
import {
  HISTORY_FORM_MODEL,
  PAGINATION_PAGESIZE_DEFAULT
} from '@/common/const/topup.const'
import { apiClass } from '@/common/class/api.class'
import { ENDPOINTS } from '@/common/const/endpoints.const'
import { RESPONSE_STATUS } from '@/common/const/response.const'
import { DateClass } from '@/common/class/date.class'

const state = {
  loading: false,
  items: null,
  form: ToolsClass.deepExtend(HISTORY_FORM_MODEL),
  pagination: {
    page: null,
    totalItems: null,
    pageSize: PAGINATION_PAGESIZE_DEFAULT
  }
}

const mutations = {
  SET_HISTORY_LOADING (state, value) {
    state.loading = value
  },
  SET_HISTORY_FORM_DATA_FROM (state, value) {
    state.form.dateFrom = value
  },
  SET_HISTORY_FORM_DATA_TO (state, value) {
    state.form.dateTo = value
  },
  SET_HISTORY_ITEMS (state, data) {
    state.items = data
  },
  SET_HISTORY_PAGINATION_PAGE (state, value) {
    state.pagination.page = value
  },
  SET_HISTORY_PAGINATION_PAGE_SIZE (state, value) {
    state.pagination.pageSize = value
  },
  SET_HISTORY_PAGINATION_TOTAL_ITEMS (state, value) {
    state.pagination.totalItems = value
  },
  RESET_HISTORY (state) {
    state.loading = false
    state.items = null
    state.form = ToolsClass.deepExtend(HISTORY_FORM_MODEL)
    state.pagination = {
      page: null,
      totalItems: null,
      pageSize: PAGINATION_PAGESIZE_DEFAULT
    }
  }
}

const actions = {
  getHistory ({ commit, state }) {
    commit('SET_HISTORY_LOADING', true)
    const data = {
      dateFrom: DateClass.getFormattedDate(state.form.dateFrom, 'Y-m-d'),
      dateTo: DateClass.getFormattedDate(state.form.dateTo, 'Y-m-d')
    }
    return apiClass.post(ENDPOINTS.user.history, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          let transations = response.body.transactions
          let result = []

          commit('SET_HISTORY_PAGINATION_PAGE', 1)
          commit('SET_HISTORY_ITEMS', [])
          commit('SET_HISTORY_PAGINATION_TOTAL_ITEMS', 0)

          if (transations && transations.length) {
            transations.map(transaction => {
              let item = Object.assign({}, transaction)
              delete item.products
              transaction.products.forEach(product => {
                let topupItem = Object.assign({}, item, product)
                result.push(topupItem)
              })
            })
            commit('SET_HISTORY_ITEMS', result)
            commit('SET_HISTORY_PAGINATION_TOTAL_ITEMS', result.length)
          }
        }
        return Promise.resolve()
      })
      .finally(() => {
        commit('SET_HISTORY_LOADING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  resetHistory ({ commit }) {
    commit('RESET_HISTORY')
  }
}

const getters = {
  getHistoryList (state) {
    if (state.items && state.items.length) {
      let index = state.pagination.page - 1
      let pageSize = state.pagination.pageSize
      let start = index * pageSize
      let end = index * pageSize + pageSize
      return state.items.slice(start, end)
    }
    return []
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
