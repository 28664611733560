import { mapActions, mapMutations, mapState } from 'vuex'
import { AUTO_TOPUP_TYPE_DATE_CONDITION } from '@/common/const/topup.const'
import { hasUserPaymentMethodSet } from '@/common/guards/user.guard'
import { GoogleAnalytics } from '@/common/class/ga.class'
import { LANGS_TO_DISPLAY } from '@/common/const/lang.const'

export default {
  data () {
    return {
      isRegulationLoading: true,
      form: {
        agreement: null,
        payment: null,
        terms: null
      }
    }
  },
  computed: {
    ...mapState({
      autotopup: state => state.autotopup.data,
      isLoading: state => state.autotopup.loading,
      regulation: state => state.autotopup.regulations
    }),
    isConditionPeriodSet: function () {
      return this.autotopup.recurringTopupType === AUTO_TOPUP_TYPE_DATE_CONDITION.period
    },
    isConditionDaySet: function () {
      return this.autotopup.recurringTopupType === AUTO_TOPUP_TYPE_DATE_CONDITION.day
    },
    isPaymentMethodSet: function () {
      return hasUserPaymentMethodSet()
    },
    isRegulationsError: function () {
      return !this.isRegulationLoading && !this.regulation
    }
  },
  methods: {
    ...mapMutations([
      'SET_AUTOTOPUP_STAGE'
    ]),
    ...mapActions([
      'saveAutotopup',
      'getAutotopupRegulations'
    ]),
    isFormValid () {
      return !this.isRegulationLoading && this.form.agreement && this.form.payment && this.form.terms
    },
    goBack () {
      this.SET_AUTOTOPUP_STAGE(2)
    },
    getRegulationUrl () {
      if (this.regulation && this.regulation.url) {
        return this.regulation.url
      }
      return ''
    },
    submit () {
      if (!this.isFormValid()) {
        return
      }
      GoogleAnalytics.addFormSubmitEvent()
      this.saveAutotopup()
        .then(() => {
          this.$notify.success({
            message: this.$t('component.autotopup.stage4.messges.addTopupSuccess')
          })

          if (this.isPaymentMethodSet) {
            this.$router.push({ name: 'contacts' })
            return
          }
          this.$router.push({ name: 'creditCard' })
        })
        .catch(() => {
          this.$notify.error({
            title: this.$t('component.autotopup.stage4.messges.addTopupErrorHeader'),
            message: this.$t('component.autotopup.stage4.messges.addTopupError')
          })
        })
    }
  },
  created () {
    const lang = LANGS_TO_DISPLAY[this.$i18n.locale()]
    this.getAutotopupRegulations({ lang: lang })
      .finally(() => {
        this.isRegulationLoading = false
      })
  }
}
