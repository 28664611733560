import { APP_VIEW } from '@/pge/const/config.const'

const state = {
  view: APP_VIEW.full
}
const mutations = {
  setPgeView (state, value) {
    state.view = value
  }
}
const actions = {}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
