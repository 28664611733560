import { mapMutations, mapState } from 'vuex'
import { PAGINATION_PAGESIZES } from '@/common/const/topup.const'

export default {
  computed: {
    ...mapState({
      totalItems: state => state.history.pagination.totalItems || 0,
      isLoading: state => state.history.loading
    }),
    pageSizes: () => PAGINATION_PAGESIZES,
    pageSize: {
      get () {
        return this.$store.state.history.pagination.pageSize
      },
      set (value) {
        this.SET_HISTORY_PAGINATION_PAGE_SIZE(value)
        this.SET_HISTORY_PAGINATION_PAGE(1)
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_HISTORY_PAGINATION_PAGE',
      'SET_HISTORY_PAGINATION_PAGE_SIZE'
    ]),
    setCurrentPage (value) {
      this.SET_HISTORY_PAGINATION_PAGE(value)
    },
    canShowPagination () {
      return !this.isLoading && this.totalItems > 0
    }
  }
}
