import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ToolsClass } from '@/common/class/tools.class'
import { TOPUP_PHONE_WITHOUT_PREFIX_LENGTH } from '@/common/const/topup.const'
import { GoogleAnalytics } from '@/common/class/ga.class'

export default {
  data () {
    return {
      isLoading: false,
      checkPhoneInProgress: false,
      checkPhoneError: false,
      formErrors: false,
      phoneError: true,
      phoneEmpty: true,
      phoneInvalid: null,
      phoneAutotopupActive: false,
      contact: null,
      form: {
        phone: null,
        name: null
      }
    }
  },
  computed: {
    ...mapState({
      autotopup: state => state.autotopup.data
    }),
    ...mapGetters({
      contactsAll: 'getContactsList',
      contacts: 'getContactsWithoutAutotopupList'
    }),
    isSubmitButtonDisabled: function () {
      return this.isLoading || this.checkPhoneInProgress || this.isCheckPhoneError
    },
    canShowContacts: function () {
      return !!this.contacts.length
    },
    isAnyContactSet: function () {
      return !!this.contact
    },
    isPhoneError: function () {
      return this.phoneError
    },
    isCheckPhoneInProgress: function () {
      return !this.contact && this.checkPhoneInProgress
    },
    isCheckPhoneError: function () {
      return !this.contact && this.checkPhoneError
    },
    isPhoneEmpty: function () {
      return !this.contact && this.phoneError && this.phoneEmpty
    },
    isPhoneInvalid: function () {
      return !this.contact && this.phoneError && this.phoneInvalid
    },
    isPhoneAutotopupActive: function () {
      return this.phoneAutotopupActive
    },
    isNameError: function () {
      return !this.contact && (!this.form.name || !this.form.name.length)
    }
  },
  methods: {
    ...mapMutations([
      'SET_AUTOTOPUP_STAGE',
      'SET_AUTOTOPUP_CONTACT'
    ]),
    ...mapActions([
      'checkPhoneStatus'
    ]),
    toggleContact (contact) {
      this.formErrors = false
      this.form.phone = null
      this.form.name = null
      if (!this.contact || !this.isContactActive(contact.msisdn)) {
        this.contact = ToolsClass.deepExtend(contact)
        this.checkPhone(contact.msisdn)
      } else {
        this.contact = null
        this.checkPhone(this.form.phone)
      }
    },
    isContactChecking (phone) {
      return this.isContactActive(phone) && this.checkPhoneInProgress
    },
    isContactActive (phone) {
      return this.contact && this.contact.msisdn === phone
    },
    isContactError (phone) {
      return this.isContactActive(phone) && this.checkPhoneError
    },
    checkPhoneOnPaste (event) {
      let paste

      if (window.clipboardData && window.clipboardData.getData) {
        paste = window.clipboardData.getData('Text')
      } else if (event.clipboardData && event.clipboardData.getData) {
        paste = (event.clipboardData || window.clipboardData).getData('text')
      }

      if (paste && paste.length) {
        this.form.phone = paste
        this.checkPhone(this.form.phone)
      }
    },
    checkPhone (phone) {
      this.formErrors = false
      this.phoneError = false
      this.phoneEmpty = null
      this.phoneInvalid = null
      this.phoneAutotopupActive = false
      this.checkPhoneError = false

      // czy numer pusty
      if (!phone || !phone.length) {
        this.phoneError = true
        this.phoneEmpty = true
        return
      }

      // czy numer ma dobrą długość
      if (ToolsClass.cleanPhoneNumber(phone).length < TOPUP_PHONE_WITHOUT_PREFIX_LENGTH) {
        this.phoneError = true
        this.phoneInvalid = true
        return
      }

      // czy numer jest prawidłowy
      if (!ToolsClass.isPhoneNumberValid(phone)) {
        this.phoneError = true
        this.phoneInvalid = true
        return
      }

      if (!this.isAnyContactSet) {
        let contactItem = null
        // sprawdzamy czy numer nie jest już w książce adresowej dla autodoładowań
        contactItem = this.contacts.find(contact => {
          return ToolsClass.getPhoneNumberWithoutPrefix(ToolsClass.cleanPhoneNumber(contact.msisdn)) === ToolsClass.getPhoneNumberWithoutPrefix(ToolsClass.cleanPhoneNumber(phone))
        })
        if (contactItem) {
          this.toggleContact(contactItem)
          return
        }

        // sprawdzamy czy taki numer nie ma już aktywnego autodoładowania
        contactItem = this.contactsAll.find(contact => {
          return ToolsClass.getPhoneNumberWithoutPrefix(ToolsClass.cleanPhoneNumber(contact.msisdn)) === ToolsClass.getPhoneNumberWithoutPrefix(ToolsClass.cleanPhoneNumber(phone))
        })
        if (contactItem) {
          this.formErrors = true
          this.phoneError = true
          this.phoneAutotopupActive = true
          return
        }
      }

      // checkphone w backendzie
      this.checkPhoneInProgress = true
      let data = {
        operator: this.$VUE_APP_OPERATOR,
        account: `48${ToolsClass.getPhoneNumberWithoutPrefix(ToolsClass.cleanPhoneNumber(phone))}`,
        amount: 5.00
      }
      this.checkPhoneStatus(data)
        .then(response => {
          this.checkPhoneError = response.body.status !== 1
        })
        .catch(() => {
          this.checkPhoneError = true
        })
        .finally(() => {
          this.checkPhoneInProgress = false
        })
    },
    submit () {
      this.formErrors = false

      if (!this.isAnyContactSet && (this.isPhoneError || this.isNameError)) {
        this.formErrors = true
        return
      }

      const phone = this.isAnyContactSet ? this.contact.msisdn : `48${ToolsClass.cleanPhoneNumber(this.form.phone)}`
      const name = this.isAnyContactSet ? this.contact.description : this.form.name
      const data = {
        msisdn: phone,
        description: name
      }
      GoogleAnalytics.addFormSubmitEvent()
      this.SET_AUTOTOPUP_CONTACT(data)
      this.SET_AUTOTOPUP_STAGE(4)
    },
    goBack () {
      this.SET_AUTOTOPUP_STAGE(1)
    }
  },
  mounted () {
    if (this.autotopup && this.autotopup.contact) {
      this.form.phone = ToolsClass.getPhoneNumberWithoutPrefix(ToolsClass.cleanPhoneNumber(this.autotopup.contact.msisdn))
      this.form.name = this.autotopup.contact.description
      this.checkPhone(this.form.phone)
    }
  }
}
