import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data () {
    let instance = this
    let dateFromOptions = {
      disabledDate (time) {
        if (!instance.dateTo) {
          return false
        }
        return time.getTime() > instance.dateTo.getTime()
      }
    }
    let dateToOptions = {
      disabledDate (time) {
        if (!instance.dateFrom) {
          return false
        }
        return time.getTime() < instance.dateFrom.getTime()
      }
    }

    return {
      formError: false,
      dateFromOptions: dateFromOptions,
      dateToOptions: dateToOptions
    }
  },
  computed: {
    ...mapState({
      isDateFromError: state => !state.history.form.dateFrom,
      isDateToError: state => !state.history.form.dateTo
    }),
    dateFrom: {
      get () {
        return this.$store.state.history.form.dateFrom
      },
      set (value) {
        this.SET_HISTORY_FORM_DATA_FROM(value)
      }
    },
    dateTo: {
      get () {
        return this.$store.state.history.form.dateTo
      },
      set (value) {
        this.SET_HISTORY_FORM_DATA_TO(value)
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_HISTORY_FORM_DATA_FROM',
      'SET_HISTORY_FORM_DATA_TO'
    ]),
    ...mapActions([
      'getHistory'
    ]),
    isFormValid () {
      return !this.isDateFromError && !this.isDateToError
    },
    submit () {
      this.formError = false
      if (!this.isFormValid()) {
        this.formError = true
        return
      }
      this.getHistory().catch(() => {})
    }
  }
}
