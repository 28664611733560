import { validatePassword, validatePasswordStrength } from '@/common/const/validators.const'
import { mapActions, mapGetters } from 'vuex'
const FORM_REF = 'changePasswordForm'
export default {
  props: {
    token: {
      required: false
    },
    showOldPassword: {
      required: true
    }
  },
  data () {
    return {
      showConfirmation: false,
      showProcessError: false,
      form: {
        oldPassword: null,
        password: null,
        passwordConfirm: null
      },
      rules: {
        oldPassword: [
          { required: this.showOldPassword, message: this.$t('validation.fieldRequired'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('validation.fieldRequired'), trigger: 'blur' },
          { validator: validatePasswordStrength, message: this.$t('validation.passwordTooWeak'), trigger: 'blur' }
        ],
        passwordConfirm: [
          { required: true, message: this.$t('validation.fieldRequired'), trigger: 'blur' },
          { validator: validatePassword.bind(this), trigger: 'blur', message: this.$t('validation.passwordsNotMatch') }
        ]
      },
      formRef: FORM_REF
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isUserProcessing',
      isUserLogged: 'isUserLogged'
    })
  },
  methods: {
    ...mapActions([
      'doUserAccountRecoverySetPassword',
      'doUserPasswordChange'
    ]),
    goToLogin () {
      this.$router.push({ name: 'login' })
    },
    goToUserData () {
      this.$router.push({ name: 'userData' })
    },
    clearForm () {
      this.getForm().resetFields()
    },
    clearValidate () {
      this.getForm().clearValidate()
    },
    getForm () {
      return this.$refs[FORM_REF]
    },
    setNewPasswordForLoggedUser () {
      const data = {
        oldPassword: this.form.oldPassword,
        newPassword: this.form.password
      }
      this.doUserPasswordChange(data)
        .then(() => {
          this.$notify.success({
            message: this.$t('component.changePassword.messages.changePasswordSuccess')
          })
          this.goToUserData()
        })
        .catch(() => {
          this.showProcessError = true
        })
    },
    setNewPasswordForNotLoggedUser () {
      const data = {
        token: this.token,
        password: this.form.password
      }
      this.doUserAccountRecoverySetPassword(data)
        .then(() => {
          this.clearForm()
          this.showConfirmation = true
        })
        .catch(() => {
          this.showProcessError = true
        })
    },
    submit () {
      this.showConfirmation = false
      this.showProcessError = false
      this.getForm().validate((valid) => {
        if (!valid) {
          return false
        }
        if (this.isUserLogged) {
          this.setNewPasswordForLoggedUser()
        } else {
          this.setNewPasswordForNotLoggedUser()
        }
      })
    }
  }
}
