import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ToolsClass } from '@/common/class/tools.class'
import { TOPUP_PHONE_WITH_PREFIX_LENGTH, TOPUP_PHONE_WITHOUT_PREFIX_LENGTH } from '@/common/const/topup.const'

export default {
  data () {
    return {
      formError: false
    }
  },
  computed: {
    ...mapState({
      isFormVisible: state => state.contacts.formVisible,
      contactId: state => state.contacts.form.id,
      isPhoneValid: state => state.contacts.form.isPhoneValid === true,
      isPhoneError: state => {
        return state.contacts.form.isPhoneValid === false ||
          state.contacts.form.isPhoneEmpty === true ||
          state.contacts.form.isPhoneUnique === false ||
          state.contacts.form.isCheckPhoneStatusValid === false
      },
      isPhoneEmpty: state => state.contacts.form.isPhoneEmpty === true,
      isPhoneUnique: state => state.contacts.form.isPhoneUnique !== false,
      isNameEmpty: state => !state.contacts.form.description || !state.contacts.form.description.length,
      isCheckPhoneInProgress: state => state.contacts.form.checkPhoneInProgress,
      isCheckPhoneNotSet: state => state.contacts.form.isCheckPhoneStatusValid === null,
      isCheckPhoneError: state => state.contacts.form.isCheckPhoneStatusValid === false
    }),
    ...mapGetters({
      contactsList: 'getContactsList'
    }),
    msisdn: {
      get () {
        return this.$store.state.contacts.form.msisdn
      },
      set (value) {
        this.SET_CONTACTS_FORM_PHONE(value)
      }
    },
    description: {
      get () {
        return this.$store.state.contacts.form.description
      },
      set (value) {
        this.SET_CONTACTS_FORM_NAME(value)
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_CONTACTS_FORM_VISIBLE',
      'SET_CONTACTS_FORM_DATA',
      'SET_CONTACTS_FORM_PHONE',
      'SET_CONTACTS_FORM_NAME',
      'SET_CONTACTS_FORM_CHECK_PHONE_IN_PROGRESS',
      'SET_CONTACTS_FORM_CHECK_PHONE_STATUS_VALID',
      'SET_CONTACTS_FORM_PHONE_VALID',
      'SET_CONTACTS_FORM_PHONE_EMPTY',
      'SET_CONTACTS_FORM_PHONE_UNIQUE'
    ]),
    ...mapActions([
      'checkPhoneStatus',
      'addContact',
      'updateContact'
    ]),
    showForm () {
      this.formError = false
      this.SET_CONTACTS_FORM_DATA(null)
      this.SET_CONTACTS_FORM_VISIBLE(true)
    },
    hideForm () {
      this.formError = false
      this.SET_CONTACTS_FORM_DATA(null)
      this.SET_CONTACTS_FORM_VISIBLE(false)
    },
    checkPhone (event, submitOnSuccess = false) {
      this.formError = false
      let phone = this.msisdn !== null ? String(this.msisdn) : ''
      this.SET_CONTACTS_FORM_PHONE_UNIQUE(null)
      this.SET_CONTACTS_FORM_PHONE_VALID(null)
      this.SET_CONTACTS_FORM_CHECK_PHONE_STATUS_VALID(null)
      this.SET_CONTACTS_FORM_CHECK_PHONE_IN_PROGRESS(false)

      if (!phone || !phone.length) {
        this.SET_CONTACTS_FORM_PHONE_EMPTY(true)
        return
      }

      this.SET_CONTACTS_FORM_PHONE_EMPTY(false)

      if (ToolsClass.cleanPhoneNumber(phone).length < TOPUP_PHONE_WITHOUT_PREFIX_LENGTH) {
        this.SET_CONTACTS_FORM_PHONE_VALID(false)
        return
      }
      this.SET_CONTACTS_FORM_PHONE_VALID(true)

      if (!ToolsClass.isPhoneNumberValid(phone)) {
        this.SET_CONTACTS_FORM_PHONE_VALID(false)
        return
      }

      if (!this.isPhoneNumberUnique(this.msisdn)) {
        this.SET_CONTACTS_FORM_PHONE_UNIQUE(false)
        return
      } else {
        this.SET_CONTACTS_FORM_PHONE_UNIQUE(true)
      }

      this.SET_CONTACTS_FORM_CHECK_PHONE_IN_PROGRESS(true)

      phone = ToolsClass.cleanPhoneNumber(this.msisdn)
      if (phone.length < TOPUP_PHONE_WITH_PREFIX_LENGTH) {
        phone = '48' + phone
      }
      let data = {
        operator: this.$VUE_APP_OPERATOR,
        account: phone,
        amount: 5.00
      }
      this.checkPhoneStatus(data)
        .then(response => {
          if (response.body.status === 1) {
            this.SET_CONTACTS_FORM_CHECK_PHONE_STATUS_VALID(true)
            if (submitOnSuccess) {
              this.saveContact()
            }
          } else {
            this.SET_CONTACTS_FORM_CHECK_PHONE_STATUS_VALID(false)
            this.formError = true
          }
        })
        .finally(() => {
          this.SET_CONTACTS_FORM_CHECK_PHONE_IN_PROGRESS(false)
        })
        .catch(() => {
          this.SET_CONTACTS_FORM_CHECK_PHONE_STATUS_VALID(false)
          this.$notify.error({
            title: this.$t('component.contacts.messges.phoneVerifyHeader'),
            message: this.$t('component.contacts.messges.phoneVerifyError')
          })
        })
    },
    isPhoneNumberUnique (phone) {
      phone = ToolsClass.cleanPhoneNumber(phone)
      phone = ToolsClass.getPhoneNumberWithoutPrefix(phone)
      let matchedPhone = this.contactsList.find(contact => ToolsClass.getPhoneNumberWithoutPrefix(contact.msisdn) === phone)
      if (!matchedPhone) {
        return true
      }
      return this.contactId && matchedPhone.id === this.contactId
    },
    submitAddContact () {
      this.addContact()
        .then(() => {
          this.hideForm()
          this.$notify({
            type: 'success',
            message: this.$t('component.contacts.messges.addContactSuccess')
          })
        })
        .catch(() => {
          this.$notify.error({
            title: this.$t('component.contacts.messges.addContactErrorHeader'),
            message: this.$t('component.contacts.messges.addContactError')
          })
        })
    },
    submitUpdateContact () {
      this.updateContact()
        .then(() => {
          this.hideForm()
          this.$notify({
            type: 'success',
            message: this.$t('component.contacts.messges.updateContactSuccess')
          })
        })
        .catch(() => {
          this.$notify.error({
            title: this.$t('component.contacts.messges.updateContactErrorHeader'),
            message: this.$t('component.contacts.messges.updateContactError')
          })
        })
    },
    isFormValid () {
      return !(this.isNameEmpty || this.isPhoneError)
    },
    saveContact () {
      this.formError = false
      if (!this.isFormValid()) {
        this.formError = true
        return
      }

      if (this.isCheckPhoneNotSet) {
        this.checkPhone(null, true)
        return
      }

      if (this.contactId) {
        this.submitUpdateContact()
      } else {
        this.submitAddContact()
      }
    }
  }
}
