export const VOUCHER_MODEL = {
  product: null,
  productId: null,
  amount: '',
  notificationEmail: null,
  language: 'PL',
  agreeTerms: false,
  agreeContact: false,
  agreeShare: false
}
