import { DateClass } from '@/common/class/date.class'

export const TOPUP_CONFIG_COMMON = {
  minTopups: 1,
  maxTopups: 5,
  topupAmounts: [10, 20, 30, 50, 100],
  topupAmountRecommend: 30,
  topupAmountDefault: 30,
  topupAmountMin: 5,
  topupAmountMax: 500,
  hasMarketingAgree: false,
  hasReminderEnabled: false,
  hasLoginEnabled: false,
  useWhiteLabelPaywall: false
}
export const TOPUP_TEMPLATE = {
  phone: null, // numer telefonu z prefixem
  amount: null, // kwota doładowania
  amountOther: null, // kwota doładowania wpisana ręcznie
  isAmountOtherSet: false, // flaga, czy kwota doładowania jest wpisana ręcznie
  isPhoneValid: null, // flaga, czy numer telefonu jest prawidłowy
  isPhoneEmpty: true, // flaga, czy numer telefonu jest pusty
  isPhoneDirty: false, // flaga, czy numer telefonu był edytowany
  isPhoneTouched: false, // flaga, czy numer telefonu był edytowany
  checkPhoneInProgress: false, // flaga, czy chekphone jest w trakcie przetwarzania
  isCheckPhoneStatusValid: null, // flaga, wynik checkphone
  isContact: false, // flaga, czy numer jest dodany do kontaktów
  saveContact: false, // flaga, czy zapisać jako kontakt
  setReminder: false, // flaga, czy ustawić przypomnienie o doładowaniu
  reminderTime: null, // czas przypomnienia
  contact: {}, // obiekt opisujący kontakt
  topupType: null, // typ doładowania (dla Orange - zwykłe, pakiet)
  reloadType: null // typ doładowania dla backendu (Orange)
}
export const TOPUP_COOKIE_NAME = 'CookieInfoScript'
export const TOPUP_COOKIE_TEXT = 'Wyrażam zgodę na przechowywanie w moim urządzeniu plików cookies.'
export const TOPUP_COOKIE_BACK_URL_NAME = 'topupBackUrl'
export const TOPUP_LANG_DEFAULT = 'pl'
export const TOPUP_LANG_COOKIE = 'TopupLang'
export const TOPUP_LANG_COOKIE_EXPIRY = 7
export const TOPUP_RESPONSE_SUCCESS = 1
export const TOPUP_PHONE_WITH_PREFIX_LENGTH = 11
export const TOPUP_PHONE_WITHOUT_PREFIX_LENGTH = 9
export const TOPUP_REMINDER_TIME = [5, 10, 15, 30, 60, 90]
export const TOPUP_REMINDER_TIME_DEFAULT = 30
export const TOPUP_STATUS = {
  ok: 1,
  wait: 0,
  error: -1
}
export const TOPUP_STATUS_TAG_CLASS = {
  [TOPUP_STATUS.ok]: 'success',
  [TOPUP_STATUS.wait]: 'info',
  [TOPUP_STATUS.error]: 'warning'
}
export const TOPUP_PAYMENT_STATUS = {
  ok: 1,
  wait: 0,
  error: -1
}
export const TOPUP_PAYMENT_STATUS_TAG_CLASS = {
  [TOPUP_PAYMENT_STATUS.ok]: 'success',
  [TOPUP_PAYMENT_STATUS.wait]: 'info',
  [TOPUP_PAYMENT_STATUS.error]: 'warning'
}
export const CONTACTS_FORM_MODEL = {
  id: null,
  msisdn: null,
  description: null,
  checkPhoneInProgress: false,
  isCheckPhoneStatusValid: null,
  isPhoneValid: null,
  isPhoneEmpty: true,
  isPhoneUnique: null
}
export const HISTORY_FORM_MODEL = {
  dateFrom: DateClass.getPrevMonthFirstDay(),
  dateTo: DateClass.getCurrentDate()
}
export const PAGINATION_PAGESIZES = [10, 20, 50, 100]
export const PAGINATION_PAGESIZE_DEFAULT = 10
export const TOPUP_TYPES = {
  single: 'SINGLE',
  recurring: 'RECURRING'
}

export const AUTOTOPUP_DATA_MODEL = {
  day: null,
  amount: null,
  recurringTopupType: null,
  nextTopupDate: null,
  contact: {
    msisdn: null,
    description: null
  },
  regulationsVersion: null,
  threshold: null,
  smsCodeId: null
}
export const AUTO_TOPUP_TYPES = {
  date: 'DATE',
  balance: 'BALANCE',
  package: 'PACKAGE'
}
export const AUTO_TOPUP_TYPE_DATE_CONDITION = {
  period: 'DAY_INTERVAL',
  day: 'DAY_OF_MONTH',
  balance: 'PREPAID_BALANCE'
}

export const CREDIT_CARD_STATUS = {
  pending: 'PENDING',
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  failure: 'FAILURE'
}

export const CREDIT_CARD_ISSUER = {
  visa: 'VISA',
  mastercard: 'MASTERCARD'
}

export const BLIK_STATUS = {
  active: 'ACTIVE',
  incactive: 'INACTIVE'
}
