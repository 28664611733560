export const ENDPOINTS = {
  phone: {
    checkPhone: '/api/sites/rest/checkphone',
    checkToken: '/api/sites/rest/token/msisdn/translate',
    topup: '/api/sites/rest/reload/start'
  },
  energy: {
    check: '/api/sites/rest/energy_account/check',
    prepare: '/api/sites/rest/energy_reload/prepare',
    startPayment: '/api/sites/rest/energy_reload/start_payment'
  },
  transaction: {
    getStatus: '/api/sites/rest/transaction/status/get'
  },
  user: {
    session: '/api/sites/rest/user_info',
    info: '/api/user-account/account',
    register: '/api/user-account/account',
    update: '/api/user-account/account',
    login: '/api/user-account/login',
    logout: '/api/user-account/logout',
    remove: '/api/sites/rest/user_info',
    activate: '/api/user-account/account-activation',
    accountRecovery: '/api/user-account/account-recovery',
    changePassword: '/api/user-account/password-change',
    history: '/api/sites/rest/user_transactions/get',
    getContactsList: '/api/sites/rest/user_contacts/get',
    addContact: '/api/sites/rest/user_contacts/add',
    updateContact: '/api/sites/rest/user_contacts/edit',
    deleteContact: '/api/sites/rest/user_contacts/delete',
    newsletterUnsubscribe: '/api/sites/rest/newsletter/unsubscribe/{EMAIL}/{HASH}',
    setTermsAgree: '/api/sites/rest/user_info/regulations_agreed',
    deletePaymentMethod: '/api/sites/rest/user_info/active_payment_method'
  },
  promo: {
    getOrder: '/api/sites/rest/promo/get_for_order',
    receiveAward: '/api/sites/rest/promo/receive_award'
  },
  autotopup: {
    add: '/api/sites/rest/recurring_topup/mobile',
    update: '/api/sites/rest/recurring_topup/mobile',
    remove: '/api/sites/rest/recurring_topup/mobile/{ID}',
    getRegulations: '/api/sites/rest/regulations',
    refreshRegulationsCache: '/api/sites/rest/regulations/refreshCache',
    getCheckPhone: '/api/sites/rest/recurring_topup_checkphone',
    createSmsCode: '/api/sites/rest/send_authorization_sms',
    confirmSmsCode: '/api/sites/rest/confirm_authorization_sms'
  },
  creditCard: {
    add: '/api/sites/rest/prepare_payment_method',
    getPaymentStatus: '/api/sites/rest/activation_transaction/status/get'
  },
  voucher: {
    check: '/api/sites/rest/voucher/check',
    startPayment: '/api/sites/rest/voucher/start_payment'
  },
  gateway: {
    list: '/api/sites/rest/gateway/list'
  },
  registerSim: {
    sendRegisterData: '/api/simregister/registration',
    getRegisterStatus: '/api/simregister/registration/{ID}',
    getBankList: '/api/simregister/bank'
  }
}
