import { ToolsClass } from '@/common/class/tools.class'

export const PAYWALL_RECURRING_ACTION = {
  auto: 'AUTO',
  manual: 'MANUAL',
  initWithRefund: 'INIT_WITH_REFUND',
  initWithPayment: 'INIT_WITH_PAYMENT'
}

export const PAYMENT_GROUP = {
  fastTransfer: 'FAST_TRANSFER',
  creditCard: 'CREDIT_CARD',
  wallet: 'WALLET',
  blik: 'BLIK'
}
export const PAYWAY_GROUP_MODEL = {
  id: null,
  type: null,
  header: null,
  description: null,
  payways: []
}

export const PAYWALL_BLIK_ID = 509
export const PAYWALL_BLIK_CODE_LENGTH = 6
export const BLIK_ID_LIST = [
  PAYWALL_BLIK_ID
]

export const WALLET_TYPES = {
  visaCheckout: {
    id: 1511,
    label: 'Visa Checkout'
  },
  googlePay: {
    id: 1512,
    label: 'Google Pay'
  },
  applePay: {
    id: 1513,
    label: 'Apple Pay'
  },
  masterPass: {
    id: 1505,
    label: 'MasterPass'
  },
  visaMobile: {
    id: 1523,
    label: 'Visa Mobile'
  }
}
export const WALLET_ID_LIST = [
  WALLET_TYPES.visaCheckout.id,
  WALLET_TYPES.googlePay.id,
  WALLET_TYPES.applePay.id,
  WALLET_TYPES.masterPass.id,
  WALLET_TYPES.visaMobile.id
]

export const CARD_TYPES = {
  creditCard: {
    id: 1500,
    label: 'Credit Card'
  },
  autoCreditCard: {
    id: 1503,
    label: 'Credit Card Recurring'
  }
}
export const CARD_ID_LIST = [
  CARD_TYPES.creditCard.id,
  CARD_TYPES.autoCreditCard.id
]

export const PIS_PAYWAY_RANGE = {
  start: 1800,
  end: 1899
}

export const paywayGroupsList = () => {
  const PAYWAY_GROUPS = [
    {
      type: PAYMENT_GROUP.fastTransfer,
      header: 'Szybki przelew',
      description: 'Wybierz bank'
    },
    {
      type: PAYMENT_GROUP.creditCard,
      header: 'Karta płatnicza',
      description: 'Podaj dane karty płatniczej'
    },
    {
      type: PAYMENT_GROUP.wallet,
      header: 'Portfele internetowe',
      description: 'Wybierz portfel'
    },
    {
      type: PAYMENT_GROUP.blik,
      header: 'BLIK',
      description: 'Podaj kod BLIK'
    }
  ]
  const groups = []
  PAYWAY_GROUPS.forEach(group => {
    const newGroupModel = ToolsClass.deepExtend(PAYWAY_GROUP_MODEL)
    const newGroup = Object.assign({}, newGroupModel, group)
    newGroup.id = ToolsClass.generateUUID()
    groups.push(newGroup)
  })
  return groups
}
