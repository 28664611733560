import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ToolsClass } from '../../class/tools.class'
import { TOPUP_LANG_DEFAULT, TOPUP_RESPONSE_SUCCESS } from '../../const/topup.const'
import { GoogleAnalytics } from '../../class/ga.class'
import {
  CARD_TYPES,
  PAYWALL_BLIK_CODE_LENGTH,
  PAYWALL_BLIK_ID,
  PAYWALL_RECURRING_ACTION,
  PIS_PAYWAY_RANGE
} from '@/common/const/paywall.const'
import { WidgetConnection, widgetEvents } from '@bm/widget-connection'

export default {
  data () {
    return {
      widget: null,
      form: {
        agreement: false,
        payment: false,
        marketing: false,
        pisAgree: false,
        paywayId: null,
        authorizationCode: null,
        saveBlikCredentials: false,
        saveCardCredentials: false,
        saveCardCredentialsTerms: false,
        saveBlikCredentialsTerms: false
      },
      formError: false,
      topupError: false
    }
  },
  watch: {
    lang: function () { this.changeLanguage() }
  },
  computed: {
    ...mapState({
      isLoading: state => state.topup.loading || state.paywall.loading,
      userData: state => state.user.data
    }),
    ...mapGetters({
      isUserLogged: 'isUserLogged',
      formData: 'getData',
      paywallGatewayList: 'getPaywallGatewayList',
      paymentGroupList: 'getPaymentGroupList',
      isPaymentMethodBlikSet: 'isUserPaymentMethodBlikSet',
      isPaymentMethodCreditCardSet: 'isUserPaymentMethodCreditCardSet'
    }),
    checkAll: {
      get: function () {
        let result = this.form.agreement && this.form.payment
        if (this.isMarketingEnabled()) {
          result = result && this.form.agreement && this.form.marketing
        }
        if (this.isPaywayPisType()) {
          result = result && this.form.pisAgree
        }
        if (this.isSaveCardCredentials()) {
          result = result && this.form.saveCardCredentialsTerms
        }
        if (this.isSaveBlikCredentials()) {
          result = result && this.form.saveBlikCredentialsTerms
        }
        return result
      },
      set: function (value) {
        this.form.agreement = value
        this.form.payment = value
        this.isMarketingEnabled() ? this.form.marketing = value : this.form.marketing = false
        this.isPaywayPisType() ? this.form.pisAgree = value : this.form.pisAgree = false
        this.isSaveCardCredentials() ? this.form.saveCardCredentialsTerms = value : this.form.saveCardCredentialsTerms = false
        this.isSaveBlikCredentials() ? this.form.saveBlikCredentialsTerms = value : this.form.saveBlikCredentialsTerms = false
      }
    },
    isWhiteLabelPaywallEnabled: function () {
      return this.$VUE_TOPUP_CONFIG.useWhiteLabelPaywall
    },
    lang: function () {
      return this.$i18n ? this.$i18n.locale() : TOPUP_LANG_DEFAULT
    }
  },
  methods: {
    ...mapMutations([
      'SET_TOPUP_LOADING'
    ]),
    ...mapActions([
      'goBack',
      'sendTopup',
      'getPaywallGatewayListFromApi'
    ]),
    getIframeSrc () {
      return this.$VUE_APP_CONFIG.CARD_WIDGET_URL
    },
    changeLanguage () {
      this.widget && this.widget.changeLanguage(this.lang)
    },
    onIframeLoaded () {
      if (!this.$refs.iframe || !this.$refs.iframe[0] || !this.$refs.iframe[0].src) {
        return
      }
      this.widget = new WidgetConnection(null, process.env.VUE_APP_USE_MOCK === 'true')
      this.widget.startConnection(this.$refs.iframe[0])
        .then(() => {
          this.widget.on(widgetEvents.formSuccess, (message) => {
            this.sendToken(message)
          })
          this.widget.on(widgetEvents.enterKey, () => {
            this.submit(new Event('iframeConfirm'))
          })
        })
      this.widget.changeLanguage(this.lang)
    },
    isMarketingEnabled () {
      return this.$VUE_TOPUP_CONFIG.hasMarketingAgree
    },
    getTopupAmountSum () {
      return this.formData.topup.reduce((acc, topup) => {
        return acc + topup.amount
      }, 0)
    },
    setPaywayId (id) {
      this.form.paywayId = id
      if (!this.isPaywayBlik()) {
        this.form.authorizationCode = null
      }
      if (!this.isPaywayPisType()) {
        this.form.pisAgree = false
      }
    },
    isPaywayActive (id) {
      return this.form.paywayId === id
    },
    isPaywayBlik () {
      return this.form.paywayId && this.form.paywayId === PAYWALL_BLIK_ID
    },
    isPaywayCreditCard () {
      return this.form.paywayId && this.form.paywayId === CARD_TYPES.creditCard.id
    },
    isPaywayPisType () {
      return !!this.form.paywayId && this.form.paywayId >= PIS_PAYWAY_RANGE.start && this.form.paywayId <= PIS_PAYWAY_RANGE.end
    },
    isSaveCardCredentials () {
      return this.form.saveCardCredentials
    },
    isSaveBlikCredentials () {
      return this.form.saveBlikCredentials
    },
    isFormValid () {
      let result = this.form.agreement && this.form.payment
      if (this.isWhiteLabelPaywallEnabled) {
        if (this.isPaywayPisType()) {
          result = result && !!this.form.pisAgree
        }
        if (this.isSaveCardCredentials()) {
          result = result && !!this.form.saveCardCredentialsTerms
        }
        if (this.isSaveBlikCredentials()) {
          result = result && !!this.form.saveBlikCredentialsTerms
        }
        result = result && !!this.form.paywayId
        if (this.isPaywayBlik()) {
          result = result && !!this.form.authorizationCode && ToolsClass.cleanBlikCode(this.form.authorizationCode).length === PAYWALL_BLIK_CODE_LENGTH
        }
      }
      return result
    },
    goToErrorPage () {
      // mock do indywidualnej implementacji
    },
    goToSuccessPage () {
      // mock do indywidualnej implementacji
    },
    sendGaEvents () {
      GoogleAnalytics.addFormSubmitEvent()
    },
    sendToken (token) {
      this.submit(new Event('iframeConfirm'), token)
    },
    submit (e, token) {
      e.preventDefault()
      this.formError = false

      if (this.isPaywayCreditCard() && !token) {
        this.widget && this.widget.validateForm()
      }

      if (!this.isFormValid()) {
        this.formError = true
        return
      }

      if (this.isPaywayCreditCard() && !token) {
        this.widget && this.widget.sendForm()
        return
      }

      let totalAmount = 0
      let data = {
        products: [],
        email: this.formData.email,
        language: this.$i18n ? String(this.$i18n.locale()).toUpperCase() : TOPUP_LANG_DEFAULT.toUpperCase(),
        pisAgree: this.form.pisAgree
      }

      if (token) {
        if (!data.payment) {
          data.payment = {}
        }
        data.payment.paymentToken = token
      }

      if (this.isWhiteLabelPaywallEnabled) {
        if (!data.payment) {
          data.payment = {}
        }
        data.payment.authorizationCode = null
        if (this.isPaywayBlik()) {
          data.payment.authorizationCode = ToolsClass.cleanBlikCode(this.form.authorizationCode)
          if (this.form.saveBlikCredentials) {
            data.payment.saveBlikCredentials = true
            data.blikRecurringAgree = this.form.saveBlikCredentialsTerms
          }
        }

        if (this.form.saveCardCredentials) {
          data.payment.recurringAction = PAYWALL_RECURRING_ACTION.initWithPayment
          data.payment.paywayId = CARD_TYPES.autoCreditCard.id
          data.cardRecurringAgree = this.form.saveCardCredentialsTerms
        } else {
          data.payment.paywayId = this.form.paywayId
          data.payment.recurringAction = null
        }
      }

      if (this.$VUE_TOPUP_CONFIG.hasMarketingAgree) {
        data.marketingAgree = this.form.marketing
      }

      this.formData.topup.map(item => {
        let amount = parseInt(item.amount, 10)
        totalAmount = totalAmount + amount

        data.products.push({
          account: `48${ToolsClass.cleanPhoneNumber(item.phone)}`,
          amount: amount,
          operator: this.$VUE_APP_OPERATOR,
          remindReload: item.setReminder,
          remindDays: item.setReminder ? parseInt(item.reminderTime, 10) : 0,
          saveContact: item.saveContact,
          contactName: item.saveContact ? item.contact.name : null,
          reloadType: item.reloadType
        })

        /* global _paq */
        if (window._paq) {
          _paq.push([
            'addEcommerceItem',
            amount,
            `Doładowanie ${this.$VUE_APP_OPERATOR} ${amount}`,
            'Doładowania',
            amount,
            1
          ])
        }
      })

      this.topupError = false
      this.SET_TOPUP_LOADING(true)
      this.sendGaEvents()
      this.sendTopup(data)
        .then(response => {
          if (response.body.status !== TOPUP_RESPONSE_SUCCESS) {
            this.SET_TOPUP_LOADING(false)
            this.topupError = true
            this.goToErrorPage()
            return
          }
          if (window._paq && response.body.orderId) {
            _paq.push(['trackEcommerceOrder',
              response.body.orderId,
              totalAmount,
              false
            ])
          }
          GoogleAnalytics.addTransactionEvent(this.$VUE_APP_OPERATOR, response.body.orderId, this.formData.topup, totalAmount)
          window.location.href = response.body.paymentLink
        })
        .catch(() => {
          this.SET_TOPUP_LOADING(false)
          this.topupError = true
          this.widget && this.widget.removeLoader()
          this.goToErrorPage()
        })
    }
  },
  created () {
    if (this.isWhiteLabelPaywallEnabled) {
      this.getPaywallGatewayListFromApi()
    }
  },
  beforeDestroy () {
    this.widget && this.widget.stopConnection()
  }
}
