import { ToolsClass } from '@/common/class/tools.class'
import { CONTACTS_FORM_MODEL, TOPUP_PHONE_WITH_PREFIX_LENGTH } from '@/common/const/topup.const'
import { apiClass } from '@/common/class/api.class'
import { ENDPOINTS } from '@/common/const/endpoints.const'
import { RESPONSE_STATUS } from '@/common/const/response.const'

const state = {
  loading: false,
  items: null,
  form: ToolsClass.deepExtend(CONTACTS_FORM_MODEL),
  formVisible: false
}

const mutations = {
  SET_CONTACTS_LOADING (state, value) {
    state.loading = value
  },
  SET_CONTACTS_ITEMS (state, data) {
    state.items = data
  },
  SET_CONTACTS_FORM_VISIBLE (state, value) {
    state.formVisible = value
  },
  SET_CONTACTS_FORM_DATA (state, data) {
    let contact = ToolsClass.deepExtend(CONTACTS_FORM_MODEL)
    if (!data) {
      state.form = contact
    } else {
      state.form = Object.assign(contact, data)
      state.form.msisdn = ToolsClass.cleanPhoneNumber(ToolsClass.getPhoneNumberWithoutPrefix(data.msisdn))
      state.form.isPhoneEmpty = false
      state.form.isPhoneValid = true
    }
  },
  SET_CONTACTS_FORM_PHONE (state, value) {
    state.form.msisdn = value
  },
  SET_CONTACTS_FORM_NAME (state, value) {
    state.form.description = value
  },
  SET_CONTACTS_FORM_CHECK_PHONE_IN_PROGRESS (state, value) {
    state.form.checkPhoneInProgress = value
  },
  SET_CONTACTS_FORM_CHECK_PHONE_STATUS_VALID (state, value) {
    state.form.isCheckPhoneStatusValid = value
  },
  SET_CONTACTS_FORM_PHONE_VALID (state, value) {
    state.form.isPhoneValid = value
  },
  SET_CONTACTS_FORM_PHONE_EMPTY (state, value) {
    state.form.isPhoneEmpty = value
  },
  SET_CONTACTS_FORM_PHONE_UNIQUE (state, value) {
    state.form.isPhoneUnique = value
  },
  RESET_CONTACTS (state) {
    state.loading = false
    state.items = null
    state.form = ToolsClass.deepExtend(CONTACTS_FORM_MODEL)
    state.formVisible = false
  }
}

const actions = {
  getContacts ({ commit }) {
    commit('SET_CONTACTS_LOADING', true)
    return apiClass.get(ENDPOINTS.user.getContactsList)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          commit('SET_CONTACTS_ITEMS', response.body.contacts)
        }
        return Promise.resolve()
      })
      .finally(() => {
        commit('SET_CONTACTS_LOADING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  addContact ({ commit, dispatch, state }) {
    let phone = ToolsClass.cleanPhoneNumber(state.form.msisdn)
    if (phone.length !== TOPUP_PHONE_WITH_PREFIX_LENGTH) {
      phone = '48' + phone
    }
    const data = {
      contact: {
        msisdn: phone,
        description: state.form.description
      }
    }
    commit('SET_CONTACTS_LOADING', true)
    return apiClass.post(ENDPOINTS.user.addContact, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          dispatch('getContacts')
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        commit('SET_CONTACTS_LOADING', false)
        return Promise.reject(error)
      })
  },
  updateContact ({ commit, dispatch, state }) {
    let phone = ToolsClass.cleanPhoneNumber(state.form.msisdn)
    if (phone.length !== TOPUP_PHONE_WITH_PREFIX_LENGTH) {
      phone = '48' + phone
    }
    const data = {
      contact: {
        id: state.form.id,
        msisdn: phone,
        description: state.form.description
      }
    }
    commit('SET_CONTACTS_LOADING', true)
    return apiClass.put(ENDPOINTS.user.updateContact, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          dispatch('getContacts')
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        commit('SET_CONTACTS_LOADING', false)
        return Promise.reject(error)
      })
  },
  removeContact ({ commit, dispatch }, id) {
    const data = {
      id: id
    }
    const options = {
      body: data
    }
    return apiClass.delete(ENDPOINTS.user.deleteContact, options)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          dispatch('getContacts')
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        commit('SET_CONTACTS_LOADING', false)
        return Promise.reject(error)
      })
  },
  resetContacts ({ commit }) {
    commit('RESET_CONTACTS')
  }
}

const getters = {
  getContactsList (state) {
    let result = []
    if (state.items) {
      state.items.map(contact => {
        result.push(contact)
      })
    }
    return result
  },
  getContactsWithAutotopupList (state) {
    let result = []
    if (state.items) {
      state.items.map(contact => {
        if (contact.recurringTopup) {
          result.push(contact)
        }
      })
    }
    return result
  },
  getContactsWithoutAutotopupList (state) {
    let result = []
    if (state.items) {
      state.items.map(contact => {
        if (!contact.recurringTopup) {
          result.push(contact)
        }
      })
    }
    return result
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
