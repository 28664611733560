import { Raven } from 'vue-raven'
import { mapActions, mapState } from 'vuex'
import { CREDITCARD_PAYMENT_STATUS } from '@/common/const/creditCard.const'

export default {
  computed: {
    ...mapState({
      isLoading: state => state.creditcard.loading,
      status: state => state.creditcard.paymentStatus
    }),
    isPaymentOk: function () {
      return this.status === CREDITCARD_PAYMENT_STATUS.ok
    },
    isPaymentError: function () {
      return this.status === CREDITCARD_PAYMENT_STATUS.error
    },
    isPaymentWaitingForStatus: function () {
      return this.status === CREDITCARD_PAYMENT_STATUS.wait
    }
  },
  methods: {
    ...mapActions([
      'getCreditCardPaymentStatus',
      'getUserSession'
    ]),
    goToTopup () {
      this.$router.push({ name: 'home' })
    },
    getPaymentStatus () {
      let data = {
        serviceId: this.$route.query.ServiceID,
        orderId: this.$route.query.OrderID,
        hash: this.$route.query.Hash
      }
      this.getCreditCardPaymentStatus(data)
        .then(() => {
          if (this.isPaymentWaitingForStatus) {
            setTimeout(this.getPaymentStatus, 1000)
          } else {
            this.getUserSession()
          }
        })
        .catch(error => {
          Raven.captureMessage('CREDIT CARD BACK getPaymentStatus error', {
            extra: error
          })
        })
    }
  },
  created () {
    if (!this.$route.query.ServiceID && !this.$route.query.OrderID) {
      this.goToTopup()
      return
    }
    this.getPaymentStatus()
  }
}
