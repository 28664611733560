import { ToolsClass } from '../../class/tools.class'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { TOPUP_REMINDER_TIME, TOPUP_REMINDER_TIME_DEFAULT } from '@/common/const/topup.const'
import { GoogleAnalytics } from '@/common/class/ga.class'

export default {
  data () {
    return {
      form: null,
      TOPUP_AMOUNTS: this.$VUE_TOPUP_CONFIG.topupAmounts,
      TOPUP_AMOUNT_MIN: this.$VUE_TOPUP_CONFIG.topupAmountMin,
      TOPUP_AMOUNT_MAX: this.$VUE_TOPUP_CONFIG.topupAmountMax,
      TOPUP_REMINDER_TIME: TOPUP_REMINDER_TIME,
      showAmountMinMessage: [],
      showAmountMaxMessage: []
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.topup.loading
    }),
    ...mapGetters({
      formData: 'getData',
      isUserLogged: 'isUserLogged',
      isPaymentMethodActive: 'isUserPaymentMethodActive',
      isPaymentMethodBlikSet: 'isUserPaymentMethodBlikSet',
      isPaymentMethodCreditCardSet: 'isUserPaymentMethodCreditCardSet'
    }),
    lang: function () {
      return this.$i18n.locale()
    }
  },
  methods: {
    ...mapMutations([
      'SET_TOPUP_STAGE'
    ]),
    ...mapActions([
      'goBack',
      'setStage2Data'
    ]),
    isReminderEnabled () {
      return this.$VUE_TOPUP_CONFIG.hasReminderEnabled
    },
    setReminderTime (topup) {
      if (topup.setReminder) {
        topup.reminderTime = TOPUP_REMINDER_TIME_DEFAULT
      } else {
        topup.reminderTime = null
      }
    },
    enableOtherAmount (topupIndex) {
      if (this.isAmountOtherSet(topupIndex)) {
        return
      }

      this.form.topup[topupIndex].isAmountOtherSet = true
      this.form.topup[topupIndex].amount = null
      this.form.topup[topupIndex].amountOther = ''

      setTimeout(() => {
        let input = this.$refs.topupAmountOther[topupIndex].querySelector('input[type="text"]')
        if (input) {
          input.focus()
        }
      }, 100)
    },
    disableOtherAmount (topupIndex) {
      this.form.topup[topupIndex].isAmountOtherSet = false
      this.form.topup[topupIndex].amountOther = null
    },
    isAmountOtherSet (topupIndex) {
      return this.form.topup[topupIndex].isAmountOtherSet
    },
    isAmountMinMessage (topupIndex) {
      let minAmountIndex = this.showAmountMinMessage.indexOf(topupIndex)
      return minAmountIndex !== -1
    },
    isAmountMaxMessage (topupIndex) {
      let maxAmountIndex = this.showAmountMaxMessage.indexOf(topupIndex)
      return maxAmountIndex !== -1
    },
    hideAmountMessages (topupIndex) {
      let minAmountIndex = this.showAmountMinMessage.indexOf(topupIndex)
      if (minAmountIndex !== -1) {
        this.showAmountMinMessage.splice(minAmountIndex, 1)
      }

      let maxAmountIndex = this.showAmountMaxMessage.indexOf(topupIndex)
      if (maxAmountIndex !== -1) {
        this.showAmountMaxMessage.splice(maxAmountIndex, 1)
      }
    },
    setReloadType (type, topupIndex) {
      this.form.topup[topupIndex].reloadType = type
    },
    setTopupAmount (amount, topupIndex) {
      this.form.topup[topupIndex].amount = amount
      this.form.topup[topupIndex].amountOther = null
      this.disableOtherAmount(topupIndex)
      this.hideAmountMessages(topupIndex)
    },
    validateAmount (topupIndex) {
      let amount = parseInt(this.form.topup[topupIndex].amountOther, 10)
      if (isNaN(amount)) {
        this.form.topup[topupIndex].amountOther = null
        return
      }

      this.form.topup[topupIndex].amountOther = amount
    },
    formatAmount (topupIndex) {
      this.hideAmountMessages(topupIndex)
      let amount = parseInt(this.form.topup[topupIndex].amountOther, 10)
      if (isNaN(amount)) {
        this.form.topup[topupIndex].amountOther = null
        return
      }

      if (amount < this.$VUE_TOPUP_CONFIG.topupAmountMin) {
        amount = this.$VUE_TOPUP_CONFIG.topupAmountMin
        this.showAmountMinMessage.push(topupIndex)
      }

      if (amount > this.$VUE_TOPUP_CONFIG.topupAmountMax) {
        amount = this.$VUE_TOPUP_CONFIG.topupAmountMax
        this.showAmountMaxMessage.push(topupIndex)
      }

      this.form.topup[topupIndex].amount = amount

      if (this.$VUE_TOPUP_CONFIG.topupAmounts.includes(amount)) {
        this.disableOtherAmount(topupIndex)
      } else {
        this.form.topup[topupIndex].amountOther = amount
      }
    },
    isAmountPromo (amount) {
      if (!this.$VUE_TOPUP_CONFIG.topupAmountsPromo) {
        return false
      }
      return this.$VUE_TOPUP_CONFIG.topupAmountsPromo.includes(amount)
    },
    isAmountRecommended (amount) {
      return amount === this.$VUE_TOPUP_CONFIG.topupAmountRecommend
    },
    isAmountSet (amount, topupIndex) {
      return parseInt(this.form.topup[topupIndex].amount, 10) === amount
    },
    isFormValid () {
      const isAllAmountSet = this.form.topup.map(topup => Boolean(topup.amount)).reduce((acc, status) => acc && status, true)
      const isAllContactNamesSet = this.form.topup.map(topup => topup.saveContact ? !!topup.contact.name : true).reduce((acc, status) => acc && status, true)
      return isAllAmountSet && isAllContactNamesSet
    },
    submit (e) {
      e.preventDefault()

      if (!this.isFormValid()) {
        return
      }
      GoogleAnalytics.addFormSubmitEvent()
      this.setStage2Data(this.form)
        .then(() => {
          this.SET_TOPUP_STAGE(3)
        })
    }
  },
  created () {
    if (!this.formData) {
      this.SET_TOPUP_STAGE(1)
      return
    }
    this.form = ToolsClass.deepExtend(this.formData)
    this.form.topup.forEach((topup, index) => {
      if (!topup.amount) {
        this.setTopupAmount(this.$VUE_TOPUP_CONFIG.topupAmountDefault, index)
      }
    })
  }
}
