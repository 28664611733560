import { ToolsClass } from '@/common/class/tools.class'
import { apiClass } from '@/common/class/api.class'
import { RESPONSE_STATUS } from '@/common/const/response.const'
import { PROMO_STATUS } from '@/tmobile/const/promo.const'
import { ENDPOINTS } from '@/common/const/endpoints.const'

const PROMO_FORM_MODEL = {
  serviceId: null,
  orderId: null,
  hash: null,
  firstName: null,
  lastName: null,
  address: null,
  house: null,
  flat: null,
  postCode: null,
  city: null,
  msisdn: null,
  email: null,
  termsAgree: false,
  personalDataAgree: false
}

const state = {
  promo: {
    loading: true,
    processing: false,
    info: null,
    status: null,
    form: ToolsClass.deepExtend(PROMO_FORM_MODEL)
  }
}

const mutations = {
  SET_TMOBILE_PROMO_LOADING (state, value) {
    state.promo.loading = value
  },
  SET_TMOBILE_PROMO_PROCESSING (state, value) {
    state.promo.processing = value
  },
  SET_TMOBILE_PROMO_INFO (state, data) {
    state.promo.info = data
  },
  SET_TMOBILE_PROMO_STATUS (state, data) {
    state.promo.status = data
  },
  SET_TMOBILE_PROMO_FORM_SERVICE_ID (state, value) {
    state.promo.form.serviceId = value
  },
  SET_TMOBILE_PROMO_FORM_ORDER_ID (state, value) {
    state.promo.form.orderId = value
  },
  SET_TMOBILE_PROMO_FORM_HASH (state, value) {
    state.promo.form.hash = value
  },
  SET_TMOBILE_PROMO_FORM_FIRST_NAME (state, value) {
    state.promo.form.firstName = value
  },
  SET_TMOBILE_PROMO_FORM_LAST_NAME (state, value) {
    state.promo.form.lastName = value
  },
  SET_TMOBILE_PROMO_FORM_ADDRESS (state, value) {
    state.promo.form.address = value
  },
  SET_TMOBILE_PROMO_FORM_HOUSE (state, value) {
    state.promo.form.house = value
  },
  SET_TMOBILE_PROMO_FORM_FLAT (state, value) {
    state.promo.form.flat = value
  },
  SET_TMOBILE_PROMO_FORM_POST_CODE (state, value) {
    state.promo.form.postCode = value
  },
  SET_TMOBILE_PROMO_FORM_CITY (state, value) {
    state.promo.form.city = value
  },
  SET_TMOBILE_PROMO_FORM_MSISDN (state, value) {
    state.promo.form.msisdn = value
  },
  SET_TMOBILE_PROMO_FORM_EMAIL (state, value) {
    state.promo.form.email = value
  },
  SET_TMOBILE_PROMO_FORM_TERMS_AGREE (state, value) {
    state.promo.form.termsAgree = value
  },
  SET_TMOBILE_PROMO_FORM_PERSONAL_DATA_AGREE (state, value) {
    state.promo.form.personalDataAgree = value
  },
  CLEAR_TMOBILE_PROMO_FORM (state) {
    state.promo.form = {}
    state.promo.form = ToolsClass.deepExtend(PROMO_FORM_MODEL)
  }
}

const actions = {
  getTMobilePromoOrder ({ state, commit }, data) {
    commit('SET_TMOBILE_PROMO_LOADING', true)
    return apiClass.post(ENDPOINTS.promo.getOrder, data)
      .then(response => {
        if (response.body.status === RESPONSE_STATUS.ok) {
          commit('SET_TMOBILE_PROMO_INFO', response.body)
          commit('SET_TMOBILE_PROMO_FORM_EMAIL', response.body.email)
          if (response.body.promoStatus !== PROMO_STATUS.waitingForPayment) {
            commit('SET_TMOBILE_PROMO_LOADING', false)
          }
          return Promise.resolve()
        }
        commit('SET_TMOBILE_PROMO_LOADING', false)
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        commit('SET_TMOBILE_PROMO_LOADING', false)
        return Promise.reject(error)
      })
  },
  setTMobilePromoAward ({ state, commit }) {
    commit('SET_TMOBILE_PROMO_PROCESSING', false)
    let data = ToolsClass.deepExtend(state.promo.form)
    data.address = `${data.address} ${data.house}`
    if (data.flat && String(data.flat).length > 0) {
      data.address = `${data.address} / ${data.flat}`
    }
    delete data.house
    delete data.flat
    return apiClass.post(ENDPOINTS.promo.receiveAward, data)
      .then(response => {
        if (response.body.status === RESPONSE_STATUS.ok) {
          commit('SET_TMOBILE_PROMO_STATUS', response.body)
        }
        return Promise.resolve(response.body)
      })
      .finally(() => {
        commit('SET_TMOBILE_PROMO_PROCESSING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
