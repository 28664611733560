import {
  validateCheckbox,
  validateEmail,
  validatePassword,
  validatePasswordStrength
} from '@/common/const/validators.const'
import { mapActions, mapGetters } from 'vuex'
const FORM_REF = 'registerForm'
export default {
  data () {
    const RULES = {
      email: [
        { required: true, message: this.$t('validation.fieldRequired'), trigger: 'blur' },
        { validator: validateEmail, message: this.$t('validation.emailNotValid'), trigger: 'blur' }
      ],
      password: [
        { required: true, message: this.$t('validation.fieldRequired'), trigger: 'blur' },
        { validator: validatePasswordStrength, message: this.$t('validation.passwordTooWeak'), trigger: 'blur' }
      ],
      passwordConfirm: [
        { required: true, message: this.$t('validation.fieldRequired'), trigger: 'blur' },
        { validator: validatePassword.bind(this), trigger: 'blur', message: this.$t('validation.passwordsNotMatch') }
      ],
      termsAgree: [
        { type: 'boolean', validator: validateCheckbox, message: this.$t('validation.fieldRequired'), trigger: 'blur' }
      ]
    }
    return {
      showConfirmation: false,
      showProcessError: false,
      form: {
        email: null,
        password: null,
        passwordConfirm: null,
        termsAgree: false
      },
      rules: RULES,
      formRef: FORM_REF
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isUserProcessing'
    })
  },
  methods: {
    ...mapActions([
      'doUserRegister'
    ]),
    goToHomePage () {
      this.$router.push({ name: 'home' })
    },
    goToLogin () {
      this.$router.push({ name: 'login' })
    },
    clearForm () {
      this.getForm().resetFields()
    },
    getForm () {
      return this.$refs[FORM_REF]
    },
    submit () {
      this.showConfirmation = false
      this.showProcessError = false
      this.getForm().validate((valid) => {
        if (!valid) {
          return false
        }

        const data = {
          email: this.form.email,
          password: this.form.password
        }
        this.doUserRegister(data)
          .then(() => {
            this.clearForm()
            this.showConfirmation = true
          })
          .catch(() => {
            this.showProcessError = true
          })
      })
    }
  }
}
