import Vue from 'vue'
import { Raven } from 'vue-raven'
import { isUserLogged } from '@/common/guards/user.guard'
import { RESPONSE_STATUS } from '@/common/const/response.const'
import store from '@/common/vuex/store'
import eventBus from '@/common/events/eventBus'
import { EVENTS } from '@/common/const/events.const'

const OPTIONS = {
  credentials: true
}
const RESPONSE_VALID_TYPES = [
  'string',
  'number',
  'object'
]

function isResponseValidType (response) {
  return RESPONSE_VALID_TYPES.includes(typeof response.body)
}

function isUserSessionTimeOut (response) {
  return isUserLogged() && response.body.status === RESPONSE_STATUS.userNotLogged
}

function logoutAndRedirectUser (response) {
  return store.dispatch('doUserLogout')
    .catch(() => {})
    .finally(() => {
      eventBus.$emit(EVENTS.userSessionTimeOut)
      return Promise.reject(response)
    })
}

export class ApiClass {
  get (endpoint, options = {}) {
    Object.assign(options, OPTIONS)

    let url = ApiClass.getBackendUrl(endpoint)
    return Vue.http.get(url, options)
      .then(response => {
        if (!isResponseValidType(response)) {
          return Promise.reject(response)
        }
        if (isUserSessionTimeOut(response)) {
          return logoutAndRedirectUser(response)
        }
        return Promise.resolve(response)
      })
      .catch(error => {
        Raven.captureMessage('ApiClass GET Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  }
  post (endpoint, data = {}, options = {}) {
    Object.assign(options, OPTIONS)

    let url = ApiClass.getBackendUrl(endpoint)
    return Vue.http.post(url, data, options)
      .then(response => {
        if (!isResponseValidType(response)) {
          return Promise.reject(response)
        }
        if (isUserSessionTimeOut(response)) {
          return logoutAndRedirectUser(response)
        }
        return Promise.resolve(response)
      })
      .catch(error => {
        Raven.captureMessage('ApiClass POST Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  }
  put (endpoint, data = {}, options = {}) {
    Object.assign(options, OPTIONS)

    let url = ApiClass.getBackendUrl(endpoint)
    return Vue.http.put(url, data, options)
      .then(response => {
        if (!isResponseValidType(response)) {
          return Promise.reject(response)
        }
        if (isUserSessionTimeOut(response)) {
          return logoutAndRedirectUser(response)
        }
        return Promise.resolve(response)
      })
      .catch(error => {
        Raven.captureMessage('ApiClass PUT Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  }
  delete (endpoint, options = {}) {
    Object.assign(options, OPTIONS)

    let url = ApiClass.getBackendUrl(endpoint)
    return Vue.http.delete(url, options)
      .then(response => {
        if (!isResponseValidType(response)) {
          return Promise.reject(response)
        }
        if (isUserSessionTimeOut(response)) {
          return logoutAndRedirectUser(response)
        }
        return Promise.resolve(response)
      })
      .catch(error => {
        Raven.captureMessage('ApiClass DELETE Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  }
  static getBackendUrl (endpoint) {
    return Vue.prototype.$VUE_APP_CONFIG.API + endpoint
  }
}

export let apiClass = new ApiClass()
