import { mapState } from 'vuex'
import { CookieClass } from '@/common/class/cookie.class'
import { TOPUP_COOKIE_BACK_URL_NAME } from '@/common/const/topup.const'

export default {
  computed: {
    ...mapState({
      stage: state => state.topup.stage
    })
  },
  methods: {
    isStageActive (stage) {
      return this.stage === stage
    }
  },
  created () {
    CookieClass.removeCookie(TOPUP_COOKIE_BACK_URL_NAME)
  }
}
