import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      stage: state => state.autotopup.stage
    })
  },
  methods: {
    isStepActive (stage) {
      return this.stage >= stage
    },
    isStepCompleted (stage) {
      return this.stage > stage
    },
    isCurrentStep (stage) {
      return this.stage === stage
    }
  }
}
