import { mapGetters, mapMutations, mapState } from 'vuex'
import { ToolsClass } from '@/common/class/tools.class'
import {
  TOPUP_PAYMENT_STATUS,
  TOPUP_PAYMENT_STATUS_TAG_CLASS, TOPUP_STATUS,
  TOPUP_STATUS_TAG_CLASS,
  TOPUP_TEMPLATE, TOPUP_TYPES
} from '@/common/const/topup.const'

export default {
  computed: {
    ...mapState({
      email: state => state.user.data.email,
      isLoading: state => state.history.loading
    }),
    ...mapGetters({
      items: 'getHistoryList',
      contacts: 'getContactsList'
    })
  },
  methods: {
    ...mapMutations([
      'RESET_TOPUP',
      'SET_TOPUP_DATA',
      'SET_TOPUP_STAGE'
    ]),
    topupAgain (historyItem) {
      const topupData = {
        phone: ToolsClass.getPhoneNumberWithoutPrefix(historyItem.msisdn),
        amount: historyItem.amount,
        isPhoneValid: true,
        isPhoneEmpty: false,
        checkPhoneInProgress: false,
        isCheckPhoneStatusValid: true
      }

      if (this.isPhoneInContacts(historyItem.msisdn)) {
        let contact = this.getContactByPhone(historyItem.msisdn)
        topupData.isContact = true
        topupData.contact = contact
      }

      const formData = {
        email: this.email,
        isEmailEmpty: false,
        isEmailValid: true,
        topup: []
      }
      formData.topup.push(Object.assign({}, TOPUP_TEMPLATE, topupData))
      this.RESET_TOPUP()
      this.SET_TOPUP_DATA(formData)
      this.SET_TOPUP_STAGE(3)
      this.$router.push({ name: 'singleTopup' })
    },
    getContactByPhone (phone) {
      if (!this.contacts || !this.contacts.length) {
        return false
      }
      phone = ToolsClass.cleanPhoneNumber(phone)
      phone = ToolsClass.getPhoneNumberWithoutPrefix(phone)
      return this.contacts.find(contact => ToolsClass.getPhoneNumberWithoutPrefix(contact.msisdn) === phone)
    },
    isPhoneInContacts (phone) {
      return !!this.getContactByPhone(phone)
    },
    getPaymentStatusTagClass (status) {
      if (status > 0) {
        return TOPUP_PAYMENT_STATUS_TAG_CLASS[TOPUP_PAYMENT_STATUS.ok]
      }
      if (status < 0) {
        return TOPUP_PAYMENT_STATUS_TAG_CLASS[TOPUP_PAYMENT_STATUS.error]
      }
      return TOPUP_PAYMENT_STATUS_TAG_CLASS[TOPUP_PAYMENT_STATUS.wait]
    },
    getPaymentStatusTagDesc (status) {
      const TOPUP_PAYMENT_STATUS_TAG_DESC = {
        [TOPUP_PAYMENT_STATUS.ok]: this.$t('component.history.list.paymentStatusOk'),
        [TOPUP_PAYMENT_STATUS.wait]: this.$t('component.history.list.paymentStatusWait'),
        [TOPUP_PAYMENT_STATUS.error]: this.$t('component.history.list.paymentStatusError')
      }

      if (status > 0) {
        return TOPUP_PAYMENT_STATUS_TAG_DESC[TOPUP_PAYMENT_STATUS.ok]
      }
      if (status < 0) {
        return TOPUP_PAYMENT_STATUS_TAG_DESC[TOPUP_PAYMENT_STATUS.error]
      }
      return TOPUP_PAYMENT_STATUS_TAG_DESC[TOPUP_PAYMENT_STATUS.wait]
    },
    getTopupStatusTagClass (status) {
      if (status > 0) {
        return TOPUP_STATUS_TAG_CLASS[TOPUP_STATUS.ok]
      }
      if (status < 0) {
        return TOPUP_STATUS_TAG_CLASS[TOPUP_STATUS.error]
      }
      return TOPUP_STATUS_TAG_CLASS[TOPUP_STATUS.wait]
    },
    getTopupStatusTagDesc (status) {
      const TOPUP_STATUS_TAG_DESC = {
        [TOPUP_STATUS.ok]: this.$t('component.history.list.topupStatusOk'),
        [TOPUP_STATUS.wait]: this.$t('component.history.list.topupStatusWait'),
        [TOPUP_STATUS.error]: this.$t('component.history.list.topupStatusError')
      }

      if (status > 0) {
        return TOPUP_STATUS_TAG_DESC[TOPUP_STATUS.ok]
      }
      if (status < 0) {
        return TOPUP_STATUS_TAG_DESC[TOPUP_STATUS.error]
      }
      return TOPUP_STATUS_TAG_DESC[TOPUP_STATUS.wait]
    },
    isTopupTypeSingle (type) {
      return type === TOPUP_TYPES.single
    },
    isTopupTypeRecurring (type) {
      return type === TOPUP_TYPES.recurring
    }
  }
}
