import { apiClass } from '@/common/class/api.class'
import { ENDPOINTS } from '@/common/const/endpoints.const'
import { TOPUP_RESPONSE_SUCCESS } from '@/common/const/topup.const'

const state = {
  data: null,
  loading: false
}

const mutations = {
  SET_TRANSACTION_DATA (state, data) {
    state.data = data
  },
  SET_TRANSACTION_LOADING (state, value) {
    state.loading = value
  }
}

const actions = {
  getTransactionStatus ({ state, commit }, data) {
    commit('SET_TRANSACTION_LOADING', true)
    return apiClass
      .post(ENDPOINTS.transaction.getStatus, data)
      .then(response => {
        if (response.body.status === TOPUP_RESPONSE_SUCCESS) {
          commit('SET_TRANSACTION_DATA', response.body)
        }
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_TRANSACTION_LOADING', false)
      })
  }
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
