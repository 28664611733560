import { CookieClass } from '../../class/cookie.class'
import { TOPUP_COOKIE_BACK_URL_NAME } from '../../const/topup.const'

export default {
  methods: {
    supportBackUrlParam () {
      let backUrl = this.$route.query.backUrl
      if (!backUrl) {
        return
      }

      CookieClass.setCookie(TOPUP_COOKIE_BACK_URL_NAME, backUrl, 1)
    }
  },
  created () {
    this.supportBackUrlParam()
  }
}
