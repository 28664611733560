import { apiClass } from '@/common/class/api.class'
import { ENDPOINTS } from '@/common/const/endpoints.const'
import { RESPONSE_STATUS } from '@/common/const/response.const'

const state = {
  loading: false,
  paymentStatus: null
}
const mutations = {
  SET_CREDITCARD_LOADING (state, value) {
    state.loading = value
  },
  SET_CREDIT_CARD_PAYMENT_STATUS (state, value) {
    state.paymentStatus = value
  }
}
const actions = {
  verifyCreditCard ({ commit }, data) {
    commit('SET_CREDITCARD_LOADING', true)
    return apiClass.post(ENDPOINTS.creditCard.add, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve(response.body.redirectUrl)
        }
        commit('SET_CREDITCARD_LOADING', false)
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        commit('SET_CREDITCARD_LOADING', false)
        return Promise.reject(error)
      })
  },
  deleteCreditCard ({ commit }) {
    commit('SET_CREDITCARD_LOADING', true)
    return apiClass.delete(ENDPOINTS.user.deletePaymentMethod)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_CREDITCARD_LOADING', false)
      })
  },
  getCreditCardPaymentStatus ({ commit }, data) {
    commit('SET_CREDITCARD_LOADING', true)
    return apiClass
      .post(ENDPOINTS.creditCard.getPaymentStatus, data)
      .then(response => {
        if (response.body.status === RESPONSE_STATUS.ok) {
          commit('SET_CREDIT_CARD_PAYMENT_STATUS', response.body.paymentStatus)
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_CREDITCARD_LOADING', false)
      })
  }
}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
