import { mapActions, mapMutations, mapState } from 'vuex'
import { GoogleAnalytics } from '@/common/class/ga.class'
import { WidgetConnection, widgetEvents } from '@bm/widget-connection'

export default {
  data () {
    return {
      widget: null
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.creditcard.loading
    }),
    lang: function () {
      return this.$i18n.locale()
    },
    widgetAvailable: function () {
      return !this.isLoading && this.widget && this.widget.ready
    }
  },
  watch: {
    lang: function () { this.changeLanguage() }
  },
  beforeDestroy () {
    this.widget && this.widget.stopConnection()
  },
  methods: {
    ...mapMutations([
      'SET_CREDITCARD_LOADING'
    ]),
    ...mapActions([
      'verifyCreditCard'
    ]),
    getIframeSrc () {
      return this.$VUE_APP_CONFIG.CARD_WIDGET_URL
    },
    changeLanguage () {
      this.widget && this.widget.changeLanguage(this.lang)
    },
    onIframeLoaded () {
      if (!this.$refs.iframe || !this.$refs.iframe.src) {
        return
      }
      this.widget = new WidgetConnection(null, process.env.VUE_APP_USE_MOCK === 'true')
      this.widget.startConnection(this.$refs.iframe)
        .then(() => {
          this.widget.on(widgetEvents.formSuccess, (message) => {
            this.sendToken(message)
          })
          this.widget.on(widgetEvents.enterKey, () => {
            this.submit()
          })
        })
      this.widget.changeLanguage(this.lang)
    },
    submit () {
      if (!this.widget) {
        return
      }
      if (this.widget.invalid) {
        this.widget.validateForm()
        return
      }
      this.SET_CREDITCARD_LOADING(true)
      this.widget.sendForm()
    },
    sendToken (token) {
      GoogleAnalytics.addFormSubmitEvent()
      const data = {
        paymentToken: token
      }
      this.verifyCreditCard(data)
        .then(redirectUrl => {
          window.location.href = redirectUrl
        })
        .catch(() => {
          this.widget && this.widget.removeLoader()
          this.$notify.error({
            title: this.$t('component.creditcard.messges.addTokenErrorHeader'),
            message: this.$t('component.creditcard.messges.addTokenError')
          })
        })
    }
  }
}
