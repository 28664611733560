export const ROUTING = {
  main: '/',
  back: '/powrot',
  backCreditCard: '/powrot-weryfikacja-karty',
  contacts: '/kontakty',
  contactEdit: '/kontakty/edycja',
  history: '/historia-doladowan',
  userData: '/moje-dane',
  unregister: '/potwierdzenie-wyrejestrowania-email',
  singleTopup: '/doladowanie',
  autoTopup: '/doladowanie-cykliczne',
  creditCard: '/karta-platnicza',
  userAccountTerms: '/regulamin-konta'
}
