import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    token: {
      required: false
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isUserLoading',
      isUserLogged: 'isUserLogged'
    })
  },
  methods: {
    ...mapActions([
      'doUserAccountRecoveryCheckToken'
    ]),
    goToHomePage () {
      this.$router.push({ name: 'home' })
    }
  },
  mounted () {
    if (this.isUserLogged) {
      return
    }

    if (!this.token || !this.token.length) {
      this.goToHomePage()
      return
    }

    this.doUserAccountRecoveryCheckToken(this.token)
      .catch(() => {
        this.goToHomePage()
      })
  }
}
