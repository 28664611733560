const state = {
  visible: false
}

const mutations = {
  toggleInvoiceInfoVisible (state) {
    state.visible = !state.visible
  }
}

const actions = {}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
