import { mapActions, mapState } from 'vuex'
import { CREDIT_CARD_STATUS } from '@/common/const/topup.const'

export default {
  computed: {
    ...mapState({
      userData: state => state.user.data
    }),
    payment: function () {
      return [this.userData.activePaymentMethod]
    },
    isPaymentMethodSet: function () {
      return !!this.userData.activePaymentMethod
    }
  },
  methods: {
    ...mapActions([
      'deleteCreditCard',
      'getUserSession'
    ]),
    isCardActive (status) {
      return status === CREDIT_CARD_STATUS.active
    },
    isCardInactive (status) {
      return status === CREDIT_CARD_STATUS.inactive
    },
    isCardPending (status) {
      return status === CREDIT_CARD_STATUS.pending
    },
    isCardFailure (status) {
      return status === CREDIT_CARD_STATUS.failure
    },
    goToAddPaymentMethod () {
      this.$router.push({ name: 'creditCard' })
    },
    removeCard () {
      this.$confirm(this.$t('component.userData.payment.messages.removeConfirm'), this.$t('component.userData.payment.messages.removeHeader'), {
        confirmButtonText: this.$t('component.userData.payment.messages.removeConfirmButton'),
        cancelButtonText: this.$t('component.userData.payment.messages.removeCancelButton'),
        type: 'info'
      })
        .then(() => {
          this.deleteCreditCard()
            .then(this.getUserSession)
            .then(() => {
              this.$notify.success({
                message: this.$t('component.userData.payment.messages.removeSuccess')
              })
            })
            .catch(() => {
              this.$notify.error({
                message: this.$t('component.userData.payment.messages.removeError')
              })
            })
        })
        .catch(() => {})
    }
  },
  mounted () {
    this.getUserSession().catch(() => {})
  }
}
