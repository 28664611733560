import { DateClass } from '@/common/class/date.class'

export const MAINTENANCE_GLOBAL_INFO_START = 'Wed Nov 13 2024 20:00:00 GMT+0100'
export const MAINTENANCE_GLOBAL_INFO_END = 'Thu Nov 14 2024 07:00:00 GMT+0100'
export const MAINTENANCE_GLOBAL = [
  { start: 'Thu Nov 14 2024 00:00:00 GMT+0100', end: 'Thu Nov 14 2024 07:00:00 GMT+0100' }
]

function isMaintenanceGlobal () {
  let result = false
  MAINTENANCE_GLOBAL.forEach(entry => {
    if (DateClass.isDateActive(entry.start, entry.end)) {
      result = true
    }
  })
  return result
}
export const IS_MAINTENANCE_GLOBAL = isMaintenanceGlobal()
