import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions([
      'getContacts'
    ])
  },
  mounted () {
    this.getContacts()
  }
}
