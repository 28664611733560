import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ToolsClass } from '@/common/class/tools.class'
import { AUTO_TOPUP_TYPE_DATE_CONDITION, AUTO_TOPUP_TYPES, TOPUP_TEMPLATE } from '@/common/const/topup.const'

export default {
  computed: {
    ...mapState({
      email: state => state.user.data.email,
      isLoading: state => state.contacts.loading
    }),
    ...mapGetters({
      contacts: 'getContactsList'
    })
  },
  methods: {
    ...mapMutations([
      'SET_CONTACTS_FORM_VISIBLE',
      'SET_CONTACTS_FORM_DATA',
      'SET_TOPUP_DATA',
      'SET_TOPUP_STAGE',
      'RESET_AUTOTOPUP',
      'SET_AUTOTOPUP_TYPE',
      'SET_AUTOTOPUP_CONTACT'
    ]),
    ...mapActions([
      'removeContact',
      'resetTopup'
    ]),
    topupContact (contact) {
      const topupData = {
        phone: ToolsClass.getPhoneNumberWithoutPrefix(contact.msisdn),
        isPhoneValid: true,
        isPhoneEmpty: false,
        checkPhoneInProgress: false,
        isCheckPhoneStatusValid: true,
        isContact: true,
        contact: contact
      }
      const formData = {
        email: this.email,
        isEmailEmpty: false,
        isEmailValid: true,
        topup: []
      }
      formData.topup.push(Object.assign({}, TOPUP_TEMPLATE, topupData))
      this.resetTopup()
      this.SET_TOPUP_DATA(formData)
      this.SET_TOPUP_STAGE(2)
      this.$router.push({ name: 'singleTopup' })
    },
    editContactItem (contact) {
      this.$router.push({ name: 'contactEdit', params: { id: contact.id } })
    },
    removeContactItem (id) {
      this.$confirm(this.$t('component.contacts.messges.removeConfirm'), this.$t('component.contacts.messges.removeHeader'), {
        confirmButtonText: this.$t('component.contacts.messges.removeConfirmButton'),
        cancelButtonText: this.$t('component.contacts.messges.removeCancelButton'),
        type: 'warning'
      })
        .then(() => {
          this.removeContact(id)
            .then(() => {
              this.$notify({
                type: 'success',
                message: this.$t('component.contacts.messges.removeSuccess')
              })
            })
            .catch(() => {
              this.$notify.error({
                title: this.$t('component.contacts.messges.removeHeader'),
                message: this.$t('component.contacts.messges.removeError')
              })
            })
        })
        .catch(() => {})
    },
    goToAutotopup ({ msisdn, description }) {
      this.RESET_AUTOTOPUP()
      this.SET_AUTOTOPUP_TYPE(AUTO_TOPUP_TYPES.date)
      this.SET_AUTOTOPUP_CONTACT({ msisdn, description })
      this.$router.push({ name: 'autoTopup' })
    },
    isAutotopupConditionPeriodSet (condition) {
      return condition === AUTO_TOPUP_TYPE_DATE_CONDITION.period
    },
    isAutotopupConditionDaySet (condition) {
      return condition === AUTO_TOPUP_TYPE_DATE_CONDITION.day
    }
  }
}
