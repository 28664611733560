export const REGEXPS = {
  emailValidator: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,24}$/,
  password: {
    letters: /(.*[a-zA-Z].*)/,
    symbols: /(.*[\\!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*)/,
    numbers: /(.*\d.*)/
  },
  phoneNumber: /^(\d{7}|\d{9}|\d{11}|\d{2} \d{3} \d{2} \d{2})$/,
  simNumber: /^\d{3,19}F?$/
}
