import { apiClass } from '../../class/api.class'
import { ENDPOINTS } from '../../const/endpoints.const'
import { ENERGY_CALCULATION_STATUS, ENERGY_CALCULATION_STATUS_LIST } from '../../const/energy.const'
import { TOPUP_RESPONSE_SUCCESS } from '../../const/topup.const'

const state = {
  loading: false,
  formData: null,
  codeVerified: false,
  codeError: false,
  checkData: null,
  calculationStatus: null,
  recalculatedAmount: null,
  calculationData: null,
  topupStatus: null,
  modal: {
    visible: false
  }
}

const mutations = {
  SET_ENERGY_FORM_DATA (state, data) {
    if (state.formData === null) {
      state.formData = {}
    }

    Object.assign(state.formData, data)
  },
  SET_ENERGY_CHECK_DATA (state, data) {
    if (data === null) {
      state.checkData = null
    } else {
      state.checkData = {}
      Object.assign(state.checkData, data)
    }
  },
  SET_ENERGY_CODE_VERIFIED (state, value) {
    state.codeVerified = value
  },
  SET_ENERGY_CODE_ERROR (state, value) {
    state.codeError = value
  },
  SET_ENERGY_CALCULATION_DATA (state, data) {
    if (state.calculationData === null) {
      state.calculationData = {}
    }

    Object.assign(state.calculationData, data)
  },
  SET_ENERGY_LOADING (state, value) {
    state.loading = value
  },
  SET_ENERGY_MODAL_VISIBLE (state, value) {
    state.modal.visible = value
  },
  SET_ENERGY_CALCULATION_STATUS (state, value) {
    state.calculationStatus = value
  },
  SET_ENERGY_RECALCULATED_AMOUNT (state, value) {
    state.recalculatedAmount = value
  },
  SET_ENERGY_TOPUP_STATUS (state, data) {
    state.topupStatus = data
  },
  RESET_ENERGY_CALCULATION (state) {
    state.calculationData = null
    state.calculationStatus = null
    state.recalculatedAmount = null
  }
}

const actions = {
  checkEnergyAccount ({ commit }, data) {
    commit('SET_ENERGY_LOADING', true)
    return apiClass
      .post(ENDPOINTS.energy.check, data)
      .then(response => {
        if (response.body.status === ENERGY_CALCULATION_STATUS_LIST[ENERGY_CALCULATION_STATUS.ok]) {
          commit('SET_ENERGY_CODE_VERIFIED', true)
          commit('SET_ENERGY_CODE_ERROR', false)
          commit('SET_ENERGY_CHECK_DATA', response.body)
          return Promise.resolve()
        }

        commit('SET_ENERGY_CHECK_DATA', null)

        if (response.body.status === ENERGY_CALCULATION_STATUS_LIST[ENERGY_CALCULATION_STATUS.codeError]) {
          commit('SET_ENERGY_CODE_ERROR', true)
          return Promise.reject(response.body)
        }

        commit('SET_ENERGY_CALCULATION_STATUS', response.body.status)
        commit('SET_ENERGY_MODAL_VISIBLE', true)
        return Promise.reject(response.body)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_ENERGY_LOADING', false)
      })
  },
  getEnergyCalculation ({ commit }, data) {
    commit('SET_ENERGY_LOADING', true)
    return apiClass
      .post(ENDPOINTS.energy.prepare, data)
      .then(response => {
        commit('SET_ENERGY_CALCULATION_STATUS', response.body.status)
        commit('SET_ENERGY_RECALCULATED_AMOUNT', null)

        if (response.body.status === ENERGY_CALCULATION_STATUS_LIST[ENERGY_CALCULATION_STATUS.ok]) {
          commit('SET_ENERGY_CALCULATION_DATA', response.body)
          return Promise.resolve()
        } else {
          if (response.body.recalculatedAmount) {
            commit('SET_ENERGY_RECALCULATED_AMOUNT', response.body.recalculatedAmount)
          }
          commit('SET_ENERGY_MODAL_VISIBLE', true)
          return Promise.reject(response.body)
        }
      }, error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_ENERGY_LOADING', false)
      })
  },
  startEnergyPayment ({ commit }, data) {
    commit('SET_ENERGY_LOADING', true)
    return apiClass
      .post(ENDPOINTS.energy.startPayment, data)
      .then(response => {
        if (response.body.status === TOPUP_RESPONSE_SUCCESS && response.body.paymentLink) {
          return Promise.resolve(response.body.paymentLink)
        }
        commit('SET_ENERGY_LOADING', false)
        return Promise.reject(response)
      })
      .catch(error => {
        commit('SET_ENERGY_LOADING', false)
        return Promise.reject(error)
      })
  },
  getEnergyTopupStatus ({ commit }, data) {
    commit('SET_ENERGY_LOADING', true)
    return apiClass
      .post(ENDPOINTS.transaction.getStatus, data)
      .then(response => {
        if (response.body.status === TOPUP_RESPONSE_SUCCESS) {
          commit('SET_ENERGY_TOPUP_STATUS', response.body.paymentStatus)
        }
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_ENERGY_LOADING', false)
      })
  }
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
