import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      topupError: false
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.energy.loading,
      formData: state => state.energy.formData,
      calculationData: state => state.energy.calculationData,
      isManyZones: state => state.energy.checkData.numberOfZones > 1
    })
  },
  methods: {
    ...mapActions([
      'startEnergyPayment'
    ]),
    submit () {
      this.topupError = false
      let data = {
        transactionId: this.calculationData.transactionId
      }

      this.startEnergyPayment(data)
        .then(paymentLink => {
          window.parent.location.href = paymentLink
        }, () => {
          this.topupError = true
        })
    }
  }
}
