import { APP_ENV } from '@/common/const/env.const'

export const APP_CONFIG = {
  OPERATOR: 'PGE',
  [APP_ENV.development]: {
    API: '',
    SENTRY: ''
  },
  [APP_ENV.production]: {
    API: '',
    SENTRY: 'https://1de692eee5de496c8ebaeeae98ff30f5@error.blue.pl/172'
  }
}

export const APP_VIEW = {
  iframe: 'IFRAME',
  full: 'FULL'
}
