import { CookieClass } from '../../class/cookie.class'
import { TOPUP_COOKIE_BACK_URL_NAME, TOPUP_PAYMENT_STATUS } from '../../const/topup.const'
import { mapActions, mapState } from 'vuex'
import { ToolsClass } from '@/common/class/tools.class'

export default {
  data () {
    return {
      backUrl: null
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.transaction.loading,
      transactionData: state => state.transaction.data
    }),
    transaction: function () {
      return {
        orderId: this.$route.query.OrderID
      }
    },
    topups: function () {
      const result = []
      if (this.transactionData && this.transactionData.products) {
        this.transactionData.products.forEach(product => {
          result.push({
            phone: ToolsClass.getPhoneNumberWithoutPrefix(product.msisdn),
            amount: product.amount
          })
        })
      }
      return result
    }
  },
  methods: {
    ...mapActions([
      'getTransactionStatus'
    ]),
    goBack () {
      document.location.href = this.backUrl
    },
    goToTopup () {
      this.$router.push({ name: 'home' }).catch(() => {})
    },
    isTopupOk () {
      return this.transactionData.paymentStatus === TOPUP_PAYMENT_STATUS.ok
    },
    isTopupError () {
      return this.transactionData.paymentStatus === TOPUP_PAYMENT_STATUS.error
    },
    isTopupWaitingForPayment () {
      return this.transactionData.paymentStatus === TOPUP_PAYMENT_STATUS.wait
    },
    getStatus () {
      let data = {
        serviceId: this.$route.query.ServiceID,
        orderId: this.$route.query.OrderID,
        hash: this.$route.query.Hash
      }
      this.getTransactionStatus(data)
    }
  },
  created () {
    if (!this.$route.query.ServiceID || !this.$route.query.OrderID || !this.$route.query.Hash) {
      this.goToTopup()
    }

    let backUrl = CookieClass.getCookie(TOPUP_COOKIE_BACK_URL_NAME)
    if (backUrl && backUrl.length) {
      this.backUrl = backUrl
      CookieClass.removeCookie(TOPUP_COOKIE_BACK_URL_NAME)
    }
    this.getStatus()
  }
}
