import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export class DateClass {
  /**
   * @returns {number}
   */
  static getCurrentTimestamp () {
    return new Date().getTime()
  }

  /**
   * @param {string|number} timestamp
   * @returns {string}
   */
  static getTimeFromTimestamp (timestamp) {
    let date = new Date(timestamp)
    let minutes = DateClass.addZeroToNumberIfNeeded(date.getMinutes())
    let seconds = DateClass.addZeroToNumberIfNeeded(date.getSeconds())
    return minutes + ':' + seconds
  }

  /**
   * @param {number} number
   * @returns {string|boolean}
   */
  static addZeroToNumberIfNeeded (number) {
    number = parseInt(number, 10)

    if (isNaN(number)) {
      return false
    }
    return number < 10 ? '0' + number : '' + number
  }

  /**
   * @param {string|null} dateStart
   * @param {string|null} dateEnd
   * @returns {boolean}
   */
  static isDateActive (dateStart, dateEnd) {
    const now = DateClass.getCurrentTimestamp()
    const start = dateStart ? new Date(dateStart.replace(/-/g, '/')) : now
    const end = dateEnd ? new Date(dateEnd.replace(/-/g, '/')) : now
    return now >= start && now <= end
  }

  /**
   * @param {Date} date
   * @param {string} format
   * @returns {string}
   */
  static getFormattedDate (date, format = 'Y-m-d H:i:s') {
    if (!date || !(date instanceof Date)) {
      return
    }

    let Y = date.getFullYear().toString()
    let m = ('0' + (date.getMonth() + 1)).slice(-2)
    let d = ('0' + date.getDate()).slice(-2)
    let H = ('0' + date.getHours()).slice(-2)
    let i = ('0' + date.getMinutes()).slice(-2)
    let s = ('0' + date.getSeconds()).slice(-2)

    switch (format) {
      case 'Y-m-d H:i:s':
        return [Y, '-', m, '-', d, ' ', H, ':', i, ':', s].join('')

      case 'Y-m-d':
        return [Y, '-', m, '-', d].join('')

      case 'H:i:s':
        return [H, ':', i, ':', s].join('')

      case 'YmdHis':
      default:
        return [Y, m, d, H, i, s].join('')
    }
  }

  /**
   *
   * @returns {Date}
   */
  static getPrevMonthFirstDay () {
    const date = new Date()
    return new Date(date.getFullYear() - (date.getMonth() > 0 ? 0 : 1), (date.getMonth() - 1 + 12) % 12, 1)
  }

  /**
   *
   * @returns {Date}
   */
  static getCurrentDate () {
    return new Date()
  }

  /**
   * @param {number} value
   * @param {OpUnitType} unit
   * @returns {Date}
   */
  static getDateInPast (value, unit = 'day') {
    return dayjs().subtract(value, unit).toDate()
  }

  /**
   * @param {string} date
   * @param {string} format
   * @returns {Date}
   */
  static parseDate (date, format = 'YYYY-MM-DD') {
    return dayjs(date, format).toDate()
  }
}
