import { ToolsClass } from '@/common/class/tools.class'
import { RESPONSE_STATUS } from '@/common/const/response.const'
import { apiClass } from '@/common/class/api.class'
import { ENDPOINTS } from '@/common/const/endpoints.const'
import { sessionTimeClass } from '@/common/class/sessionTime.class'
import { BLIK_STATUS, CREDIT_CARD_ISSUER, CREDIT_CARD_STATUS } from '@/common/const/topup.const'

const USER_MODEL = {
  status: null,
  loginUrl: null,
  logoutUrl: null,
  email: null,
  firstName: null,
  lastName: null,
  lastMsisdn: null,
  lastAmount: null,
  regulationsAgreed: null,
  activePaymentMethod: null,
  blikStatus: null
}

const state = {
  loading: true,
  processing: false,
  id: null,
  data: ToolsClass.deepExtend(USER_MODEL)
}

const mutations = {
  SET_USER_LOADING (state, value) {
    state.loading = value
  },
  SET_USER_PROCESSING (state, value) {
    state.processing = value
  },
  SET_USER_DATA (state, data) {
    state.data = ToolsClass.deepExtend(USER_MODEL)
    state.data = ToolsClass.deepExtend(data)
  },
  SET_USER_ID (state, value) {
    state.id = value
  },
  SET_USER_EMAIL (state, value) {
    state.data.email = value
  },
  RESET_USER (state) {
    state.loading = false
    state.processing = false
    state.id = null
    state.data = ToolsClass.deepExtend(USER_MODEL)
  }
}

const actions = {
  getUserInitData ({ dispatch }) {
    return Promise.all([
      dispatch('getUserSession')
    ])
  },
  getUserSession ({ commit, dispatch }) {
    commit('SET_USER_LOADING', true)
    return apiClass.get(ENDPOINTS.user.session)
      .then(response => {
        commit('SET_USER_DATA', response.body)
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          dispatch('getContacts')
          sessionTimeClass.startIntervalSessionCheck()
        }
        return Promise.resolve()
      })
      .finally(() => {
        commit('SET_USER_LOADING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  checkUserSession ({ commit, dispatch }) {
    return apiClass.get(ENDPOINTS.user.session)
      .then(response => {
        if (!response.body || response.body.status !== RESPONSE_STATUS.ok) {
          dispatch('doUserLogout')
        }
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getUserInfo ({ commit, state }) {
    commit('SET_USER_PROCESSING', true)
    const url = `${ENDPOINTS.user.info}/${state.id}`
    return apiClass.get(url)
      .then(response => {
        if (response.body && response.body.email) {
          commit('SET_USER_EMAIL', response.body.email)
        }
        return Promise.resolve()
      })
      .finally(() => {
        commit('SET_USER_PROCESSING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  doUserLogin ({ commit, dispatch }, { email, password }) {
    const data = {
      email: email,
      password: password
    }
    commit('SET_USER_PROCESSING', true)
    return apiClass.post(ENDPOINTS.user.login, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          commit('SET_USER_ID', response.body.userId)
          sessionTimeClass.startIntervalSessionCheck()
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .finally(() => {
        commit('SET_USER_PROCESSING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  doUserLogout ({ commit, dispatch }) {
    commit('SET_USER_PROCESSING', true)
    return apiClass.get(ENDPOINTS.user.logout)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          commit('RESET_USER')
          dispatch('resetTopup')
          dispatch('resetAutotopup')
          dispatch('resetContacts')
          dispatch('resetHistory')
          sessionTimeClass.stopIntervalSessionCheck()
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .finally(() => {
        commit('SET_USER_PROCESSING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  doUserRegister ({ commit, dispatch }, { email, password }) {
    const data = {
      email: email,
      password: password
    }
    commit('SET_USER_PROCESSING', true)
    return apiClass.post(ENDPOINTS.user.register, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .finally(() => {
        commit('SET_USER_PROCESSING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  doUserAccountUpdate ({ commit, state }, password) {
    const url = `${ENDPOINTS.user.update}/${state.id}`
    const data = {
      email: state.data.email,
      password: password
    }
    commit('SET_USER_PROCESSING', true)
    return apiClass.put(url, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .finally(() => {
        commit('SET_USER_PROCESSING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  doUserAccountRemove ({ commit, state, dispatch }) {
    const data = {
      email: state.data.email
    }
    commit('SET_USER_PROCESSING', true)
    return apiClass.delete(ENDPOINTS.user.remove, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return dispatch('doUserLogout')
        }
        return Promise.reject(response.body.status)
      })
      .finally(() => {
        commit('SET_USER_PROCESSING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  doUserAccountRecoveryGenerateToken ({ commit }, { email }) {
    const data = {
      email: email
    }
    commit('SET_USER_PROCESSING', true)
    return apiClass.post(ENDPOINTS.user.accountRecovery, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .finally(() => {
        commit('SET_USER_PROCESSING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  doUserAccountRecoveryCheckToken ({ commit }, token) {
    const url = `${ENDPOINTS.user.accountRecovery}/${token}`
    commit('SET_USER_LOADING', true)
    return apiClass.get(url)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .finally(() => {
        commit('SET_USER_LOADING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  doUserAccountRecoverySetPassword ({ commit }, { token, password }) {
    const url = `${ENDPOINTS.user.accountRecovery}/${token}`
    const data = {
      newPassword: password
    }
    commit('SET_USER_PROCESSING', true)
    return apiClass.put(url, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .finally(() => {
        commit('SET_USER_PROCESSING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  doUserPasswordChange ({ commit }, { newPassword, oldPassword }) {
    const data = {
      newPassword,
      oldPassword
    }
    commit('SET_USER_PROCESSING', true)
    return apiClass.post(ENDPOINTS.user.changePassword, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .finally(() => {
        commit('SET_USER_PROCESSING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  doUserAccountActivate ({ commit }, token) {
    const url = `${ENDPOINTS.user.activate}/${token}`
    commit('SET_USER_LOADING', true)
    return apiClass.put(url)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .finally(() => {
        commit('SET_USER_LOADING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

const getters = {
  isUserLogged (state) {
    return state.data.status === RESPONSE_STATUS.ok
  },
  getUserEmail (state) {
    return state.data.email
  },
  isUserLoading (state) {
    return !!state.loading
  },
  isUserProcessing (state) {
    return !!state.processing
  },
  isUserPaymentMethodSet (state) {
    return !!state.data.activePaymentMethod || state.data.blikStatus === BLIK_STATUS.active
  },
  isUserPaymentMethodActive (state) {
    return state.data.activePaymentMethod?.status === CREDIT_CARD_STATUS.active || state.data.blikStatus === BLIK_STATUS.active
  },
  isUserPaymentMethodBlikSet (state) {
    return state.data.blikStatus === BLIK_STATUS.active
  },
  isUserPaymentMethodCreditCardSet (state) {
    return !!state.data.activePaymentMethod
  },
  isUserPaymentMethodCreditCardActive (state) {
    return state.data.activePaymentMethod?.status === CREDIT_CARD_STATUS.active
  },
  isUserPaymentMethodCreditCardInactive (state) {
    return state.data.activePaymentMethod?.status === CREDIT_CARD_STATUS.inactive
  },
  isUserPaymentMethodCreditCardPending (state) {
    return state.data.activePaymentMethod?.status === CREDIT_CARD_STATUS.pending
  },
  isUserPaymentMethodCreditCardFailure (state) {
    return state.data.activePaymentMethod?.status === CREDIT_CARD_STATUS.failure
  },
  isUserPaymentMethodCreditCardVisaSet (state) {
    return !!state.data.activePaymentMethod && state.data.activePaymentMethod?.cardData?.issuer === CREDIT_CARD_ISSUER.visa
  },
  isUserPaymentMethodCreditCardMastercardSet (state) {
    return !!state.data.activePaymentMethod && state.data.activePaymentMethod?.cardData?.issuer === CREDIT_CARD_ISSUER.mastercard
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
