const getQueryFormUrl = () => {
  const queryObj = {}
  window.location.search.slice(1).split('&').forEach(queryData => {
    const query = queryData.split('=')
    queryObj[query[0]] = query[1]
  })
  return queryObj
}

export const isEmailInQuery = () => {
  const query = getQueryFormUrl()
  return !!query.email
}

export const isHashInQuery = () => {
  const query = getQueryFormUrl()
  return !!query.hash
}

export const EmailQueryGuard = function (to, from, next) {
  if (!isEmailInQuery()) {
    next({ name: 'home' })
    return
  }
  next()
}

export const HashQueryGuard = function (to, from, next) {
  if (!isHashInQuery()) {
    next({ name: 'home' })
    return
  }
  next()
}
