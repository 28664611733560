import { APP_ENV } from '@/common/const/env.const'

export class GoogleAnalytics {
  /**
   * @param {string} operator
   * @param {string|number} transactionID
   * @param {array} products
   * @param {number} totalAmount
   * @returns {boolean}
   */
  static addTransactionEvent (operator, transactionID, products = [], totalAmount = 0) {
    if (!operator || !transactionID) {
      return false
    }

    if (typeof operator !== 'string' || !['string', 'number'].includes(typeof transactionID)) {
      return false
    }

    if (!Array.isArray(products) || !products.length) {
      return false
    }

    let transactionProducts = []
    products.forEach(product => {
      let amount = parseInt(product.amount, 10)
      transactionProducts.push({
        'sku': amount,
        'name': `Doładowanie ${operator} ${amount}`,
        'price': amount,
        'quantity': 1
      })
    })

    const data = {
      'event': 'transaction',
      'transactionId': transactionID,
      'transactionTotal': totalAmount,
      'transactionProducts': transactionProducts
    }
    GoogleAnalytics.sendEvent(data)
    return true
  }

  static addFormSubmitEvent () {
    const data = {
      'event': 'formSubmitSuccess'
    }
    GoogleAnalytics.sendEvent(data)
  }

  static sendEvent (data) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
    if (process.env.NODE_ENV !== APP_ENV.production) {
      console.log('GoogleAnalytics.sendEvent', data)
    }
  }
}
