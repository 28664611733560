import { mapActions, mapMutations, mapGetters } from 'vuex'
import { CookieClass } from '@/common/class/cookie.class'
import { TOPUP_LANG_COOKIE, TOPUP_LANG_COOKIE_EXPIRY } from '@/common/const/topup.const'
import { ToolsClass } from '@/common/class/tools.class'
import { LANGS_TO_DISPLAY } from '@/common/const/lang.const'

export default {
  data () {
    return {
      drawer: false,
      isPhoneResolution: ToolsClass.isPhoneResolution()
    }
  },
  computed: {
    ...mapGetters({
      isUserLogged: 'isUserLogged',
      isUserLoading: 'isUserLoading'
    })
  },
  methods: {
    ...mapMutations([
      'SET_LANGUAGE'
    ]),
    ...mapActions([
      'resetTopup',
      'resetAutotopup'
    ]),
    closeDrawer () {
      this.drawer = false
    },
    toggleDrawer () {
      this.drawer = !this.drawer
    },
    checkIsPhoneResolution: function () {
      this.isPhoneResolution = ToolsClass.isPhoneResolution()
      if (this.isPhoneResolution) {
        this.closeDrawer()
      }
    },
    addEventListeners () {
      document.querySelectorAll('.menu-mobile a').forEach(link => {
        link.addEventListener('click', this.closeDrawer)
      })
    },
    removeEventListeners () {
      document.querySelectorAll('.menu-mobile a').forEach(link => {
        link.removeEventListener('click', this.closeDrawer)
      })
    },
    setLang (lang) {
      this.$i18n.set(lang)
      this.SET_LANGUAGE(lang)
      CookieClass.setCookie(TOPUP_LANG_COOKIE, lang, TOPUP_LANG_COOKIE_EXPIRY)
    },
    getLangToDisplay (lang) {
      return LANGS_TO_DISPLAY[lang]
    },
    goToMainPage () {
      this.resetTopup()
      this.resetAutotopup()
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      }
    }
  },
  created () {
    window.addEventListener('resize', this.checkIsPhoneResolution)
  },
  destroyed () {
    window.removeEventListener('resize', this.checkIsPhoneResolution)
  }
}
