import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isUserLoading: 'isUserLoading'
    })
  },
  methods: {
    ...mapActions([
      'doUserLogout',
      'getUserSession',
      'resetTopup'
    ]),
    goToHomePage () {
      this.$router.push({ name: 'singleTopup' })
    }
  },
  mounted () {
    this.doUserLogout()
      .then(this.getUserSession)
      .then(this.resetTopup)
      .then(this.goToHomePage)
      .catch(() => {
        this.$notify.error({
          title: this.$t('component.logout.messges.logoutErrorHeader'),
          message: this.$t('component.logout.messges.logoutError')
        })
      })
  }
}
