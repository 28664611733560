import { rest } from 'msw'
import { ENDPOINTS } from '@/common/const/endpoints.const'
import {
  NEWSLETTER_UNSUBSCRIBE_SUCCESS,
  PHONE_CHECKPHONE_SUCCESS,
  PHONE_CHECKTOKEN_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_REGISTER_SUCCESS,
  USER_CONTACT_ADD_SUCCESS,
  USER_CONTACT_DELETE_SUCCESS,
  USER_CONTACT_UPDATE_SUCCESS,
  USER_CONTACTS_LIST,
  USER_LOGGED,
  USER_NOT_LOGGED,
  USER_TRANSACTIONS_LIST,
  USER_CONTACTS_EMPTY,
  USER_TRANSACTIONS_EMPTY,
  USER_LOGGED_INFO,
  USER_NOT_LOGGED_INFO,
  USER_UPDATE_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_ACTIVATE_SUCCESS,
  USER_ACCOUNT_RECOVERY_SUCCESS,
  USER_ACCOUNT_RECOVERY_CHECK_SUCCESS,
  USER_ACCOUNT_RECOVERY_SET_PASSWORD_SUCCESS,
  PHONE_TOPUP_ERROR,
  AUTOTOPUP_ADD_SUCCESS,
  AUTOTOPUP_UPDATE_SUCCESS,
  AUTOTOPUP_REMOVE_SUCCESS,
  STATUS_OK,
  CREDIT_CARD_ADD_SUCCESS,
  CREDIT_CARD_PAYMENT_OK,
  AUTOTOPUP_REGULATIONS,
  PAYWALL_LIST,
  AUTOTOPUP_ORANGE_CHECKPHONE_SUCCESS,
  AUTOTOPUP_ORANGE_CREATE_SMS_CODE_SUCCESS,
  AUTOTOPUP_ORANGE_CONFIRM_SMS_CODE_SUCCESS
} from '@/mock/fixtures'

const USER_SESSION_KEY = 'is-authenticated'

export default [
  rest.get(ENDPOINTS.user.session, (req, res, ctx) => {
    const isAuthenticated = sessionStorage.getItem(USER_SESSION_KEY)
    if (!isAuthenticated) {
      return res(ctx.json(USER_NOT_LOGGED))
    }
    return res(ctx.json(USER_LOGGED))
  }),
  rest.get(ENDPOINTS.user.info, (req, res, ctx) => {
    const isAuthenticated = sessionStorage.getItem(USER_SESSION_KEY)
    if (!isAuthenticated) {
      return res(ctx.json(USER_NOT_LOGGED_INFO))
    }
    return res(ctx.json(USER_LOGGED_INFO))
  }),
  rest.put(ENDPOINTS.user.update + '/:id', (req, res, ctx) => {
    return res(ctx.json(USER_UPDATE_SUCCESS))
  }),
  rest.put(ENDPOINTS.user.activate + '/:token', (req, res, ctx) => {
    return res(ctx.json(USER_ACTIVATE_SUCCESS))
  }),
  rest.post(ENDPOINTS.user.register, (req, res, ctx) => {
    return res(ctx.json(USER_REGISTER_SUCCESS))
  }),
  rest.post(ENDPOINTS.user.login, (req, res, ctx) => {
    sessionStorage.setItem(USER_SESSION_KEY, 'true')
    return res(ctx.json(USER_LOGIN_SUCCESS))
  }),
  rest.get(ENDPOINTS.user.logout, (req, res, ctx) => {
    sessionStorage.removeItem(USER_SESSION_KEY)
    return res(ctx.json(USER_LOGOUT_SUCCESS))
  }),
  rest.delete(ENDPOINTS.user.remove + '/:id', (req, res, ctx) => {
    return res(ctx.json(USER_DELETE_SUCCESS))
  }),
  rest.delete(ENDPOINTS.user.deletePaymentMethod, (req, res, ctx) => {
    return res(ctx.json(STATUS_OK))
  }),
  rest.post(ENDPOINTS.user.history, (req, res, ctx) => {
    const isAuthenticated = sessionStorage.getItem(USER_SESSION_KEY)
    if (!isAuthenticated) {
      return res(ctx.json(USER_TRANSACTIONS_EMPTY))
    }
    return res(ctx.json(USER_TRANSACTIONS_LIST))
  }),
  rest.post(ENDPOINTS.user.accountRecovery, (req, res, ctx) => {
    return res(ctx.json(USER_ACCOUNT_RECOVERY_SUCCESS))
  }),
  rest.get(ENDPOINTS.user.accountRecovery + '/:token', (req, res, ctx) => {
    return res(ctx.json(USER_ACCOUNT_RECOVERY_CHECK_SUCCESS))
  }),
  rest.put(ENDPOINTS.user.accountRecovery + '/:token', (req, res, ctx) => {
    return res(ctx.json(USER_ACCOUNT_RECOVERY_SET_PASSWORD_SUCCESS))
  }),
  rest.put(ENDPOINTS.user.setTermsAgree, (req, res, ctx) => {
    return res(ctx.json(STATUS_OK))
  }),
  rest.get(ENDPOINTS.user.getContactsList, (req, res, ctx) => {
    const isAuthenticated = sessionStorage.getItem(USER_SESSION_KEY)
    if (!isAuthenticated) {
      return res(ctx.json(USER_CONTACTS_EMPTY))
    }
    return res(ctx.json(USER_CONTACTS_LIST))
  }),
  rest.post(ENDPOINTS.user.addContact, (req, res, ctx) => {
    return res(ctx.json(USER_CONTACT_ADD_SUCCESS))
  }),
  rest.put(ENDPOINTS.user.updateContact, (req, res, ctx) => {
    return res(ctx.json(USER_CONTACT_UPDATE_SUCCESS))
  }),
  rest.delete(ENDPOINTS.user.deleteContact, (req, res, ctx) => {
    return res(ctx.json(USER_CONTACT_DELETE_SUCCESS))
  }),
  rest.get(ENDPOINTS.user.newsletterUnsubscribe, (req, res, ctx) => {
    return res(ctx.json(NEWSLETTER_UNSUBSCRIBE_SUCCESS))
  }),
  rest.post(ENDPOINTS.transaction.getStatus, (req, res, ctx) => {
    return res(ctx.json({}))
  }),
  rest.post(ENDPOINTS.phone.checkPhone, (req, res, ctx) => {
    return res(ctx.json(PHONE_CHECKPHONE_SUCCESS))
  }),
  rest.post(ENDPOINTS.phone.checkToken, (req, res, ctx) => {
    return res(ctx.json(PHONE_CHECKTOKEN_SUCCESS))
  }),
  rest.post(ENDPOINTS.phone.topup, (req, res, ctx) => {
    return res(ctx.json(PHONE_TOPUP_ERROR))
  }),
  rest.post(ENDPOINTS.energy.check, (req, res, ctx) => {
    return res(ctx.json({}))
  }),
  rest.post(ENDPOINTS.energy.prepare, (req, res, ctx) => {
    return res(ctx.json({}))
  }),
  rest.post(ENDPOINTS.energy.startPayment, (req, res, ctx) => {
    return res(ctx.json({}))
  }),
  rest.post(ENDPOINTS.promo.getOrder, (req, res, ctx) => {
    return res(ctx.json({}))
  }),
  rest.post(ENDPOINTS.promo.receiveAward, (req, res, ctx) => {
    return res(ctx.json({}))
  }),
  rest.post(ENDPOINTS.autotopup.add, (req, res, ctx) => {
    return res(ctx.json(AUTOTOPUP_ADD_SUCCESS))
  }),
  rest.put(ENDPOINTS.autotopup.update, (req, res, ctx) => {
    return res(ctx.json(AUTOTOPUP_UPDATE_SUCCESS))
  }),
  rest.delete(ENDPOINTS.autotopup.remove, (req, res, ctx) => {
    return res(ctx.json(AUTOTOPUP_REMOVE_SUCCESS))
  }),
  rest.post(ENDPOINTS.creditCard.add, (req, res, ctx) => {
    return res(ctx.json(CREDIT_CARD_ADD_SUCCESS))
  }),
  rest.post(ENDPOINTS.creditCard.getPaymentStatus, (req, res, ctx) => {
    return res(ctx.json(CREDIT_CARD_PAYMENT_OK))
  }),
  rest.get(ENDPOINTS.autotopup.getRegulations, (req, res, ctx) => {
    return res(ctx.json(AUTOTOPUP_REGULATIONS))
  }),
  rest.get(ENDPOINTS.autotopup.refreshRegulationsCache, (req, res, ctx) => {
    return res(ctx.json(STATUS_OK))
  }),
  rest.get(ENDPOINTS.gateway.list, (req, res, ctx) => {
    return res(ctx.json(PAYWALL_LIST))
  }),
  rest.post(ENDPOINTS.autotopup.getCheckPhone, (req, res, ctx) => {
    return res(ctx.json(AUTOTOPUP_ORANGE_CHECKPHONE_SUCCESS))
  }),
  rest.post(ENDPOINTS.autotopup.createSmsCode, (req, res, ctx) => {
    return res(ctx.json(AUTOTOPUP_ORANGE_CREATE_SMS_CODE_SUCCESS))
  }),
  rest.post(ENDPOINTS.autotopup.confirmSmsCode, (req, res, ctx) => {
    return res(ctx.json(AUTOTOPUP_ORANGE_CONFIRM_SMS_CODE_SUCCESS))
  })
]
