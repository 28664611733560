export const LANG = {
  pl: 'pl',
  en: 'en',
  uk: 'uk',
  be: 'be',
  ka: 'ka',
  tr: 'tr',
  ro: 'ro'
}
export const LANGS = [
  LANG.pl,
  LANG.en,
  LANG.uk,
  LANG.be,
  LANG.ka,
  LANG.tr,
  LANG.ro
]

export const LANGS_TO_DISPLAY = {
  [LANG.pl]: 'PL',
  [LANG.en]: 'EN',
  [LANG.uk]: 'UA',
  [LANG.be]: 'BE',
  [LANG.ka]: 'KA',
  [LANG.tr]: 'TR',
  [LANG.ro]: 'RO'
}
