import { apiClass } from '@/common/class/api.class'
import { ENDPOINTS } from '@/common/const/endpoints.const'
import { ToolsClass } from '@/common/class/tools.class'
import { VOUCHER_MODEL } from '@/common/const/voucher.const'

const state = {
  stage: 1,
  data: ToolsClass.deepExtend(VOUCHER_MODEL),
  loading: false
}

const mutations = {
  SET_VOUCHER_STAGE (state, value) {
    state.stage = value
    setTimeout(() => {
      ToolsClass.scrollToTop()
    })
  },
  SET_VOUCHER_DATA (state, data) {
    if (typeof data !== 'object') {
      return
    }
    Object.assign(state.data, data)
  },
  SET_VOUCHER_LOADING (state, value) {
    state.loading = value
  },
  RESET_VOUCHER (state) {
    state.stage = 1
    state.data = ToolsClass.deepExtend(VOUCHER_MODEL)
    state.loading = false
  }
}
const actions = {
  goBack ({ state, commit }) {
    if (state.stage > 1) {
      commit('SET_VOUCHER_STAGE', state.stage - 1)
    }
    return Promise.resolve()
  },
  setVoucherData ({ commit }, data) {
    commit('SET_VOUCHER_DATA', data)
    return Promise.resolve()
  },
  checkVoucherStatus (context, data) {
    return apiClass.post(ENDPOINTS.voucher.check, data)
  },
  startVoucherPayment ({ state }) {
    const data = ToolsClass.deepExtend(state.data)
    delete data.product
    return apiClass.post(ENDPOINTS.voucher.startPayment, data)
  },
  resetVoucherTopup ({ commit }) {
    commit('RESET_VOUCHER')
    return Promise.resolve()
  }
}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
