import { mapActions, mapState } from 'vuex'
import { RESPONSE_STATUS } from '@/common/const/response.const'

export default {
  computed: {
    ...mapState({
      isLoading: state => state.newsletter.loading,
      newsletterStatus: state => state.newsletter.status
    }),
    isStatusUnsubscribe: function () {
      return this.newsletterStatus === RESPONSE_STATUS.ok
    }
  },
  methods: {
    ...mapActions([
      'sendNewsletterUnsubscribe'
    ]),
    goToTopup () {
      this.$router.push({ name: 'home' })
    }
  },
  mounted () {
    this.sendNewsletterUnsubscribe(this.$route.query)
  }
}
