import { DateClass } from '@/common/class/date.class'

export const PROMO_STATUS = {
  promoActive: 1,
  noPromo: -1,
  alreadyRewarded: -2,
  waitingForPayment: -3
}

export const PROMO_10_CONFIG = {
  dateStart: '2022-11-20 00:00:00',
  dateEnd: null
}

export const PROMO_ECO = {
  dateStart: '2022-12-08 12:00:00',
  dateEnd: '2023-01-31 23:59:59'
}

export const MONTH_FREE = {
  dateStart: '2024-07-01 00:00:00',
  dateEnd: '2024-09-30 23:59:59'
}

export const MONTH_FREE_LINKS = {
  pl: '/files/tmobile/Warunki_oferty_Pierwszy_miesiac_za_darmo_T_Mobile_na_karte.pdf',
  en: '/files/tmobile/Warunki_oferty_Pierwszy_miesiac_za_darmo_T_Mobile_na_karte.pdf',
  uk: '/files/tmobile/Warunki_oferty_Pierwszy_miesiac_za_darmo_T_Mobile_na_karte.pdf'
}

export const PROMO_TRAVEL = {
  dateStart: '2024-05-08 00:00:00',
  dateEnd: '2024-06-30 23:59:59'
}

export const PROMO_TRAVEL_LINKS = {
  pl: '/files/tmobile/Regulamin_konkursu_Podrozuj_Bardziej_T-Mobile.pdf',
  en: '/files/tmobile/Regulamin_konkursu_Podrozuj_Bardziej_T-Mobile.pdf',
  uk: '/files/tmobile/Regulamin_konkursu_Podrozuj_Bardziej_T-Mobile.pdf'
}

export const PROMO_EXTRA = {
  dateStart: '2024-11-04 12:00:00',
  dateEnd: '2024-12-16 23:59:59'
}

export const PROMO_EXTRA_LINKS = {
  pl: '/files/tmobile/Regulamin_promocji_T-Mobile_Heyah_20_zl_ekstra_za_doładowanie_za_min_50_zl.pdf',
  en: '/files/tmobile/Regulamin_promocji_T-Mobile_Heyah_20_zl_ekstra_za_doładowanie_za_min_50_zl.pdf',
  uk: '/files/tmobile/Regulamin_promocji_T-Mobile_Heyah_20_zl_ekstra_za_doładowanie_za_min_50_zl.pdf'
}

export const PROMO_4800 = {
  dateStart: '2023-04-28 00:00:00',
  dateEnd: '2023-07-31 23:59:59'
}

export const PROMO_4800_LINKS = {
  pl: '/files/tmobile/Regulamin_PL_Rok_Internetu_od_01_06_2023_do_31_07_2023.pdf',
  en: '/files/tmobile/Regulamin_EN_Rok_Internetu_od_01_06_2023_do_31_07_2023.pdf',
  uk: '/files/tmobile/Regulamin_UA_Rok_Internetu_od_01_06_2023_do_31_07_2023.pdf'
}

export const PROMO_10_LINKS = {
  pl: '/files/tmobile/regulamin_zyskujesz_10_procent_z_doladowania_tmobile_PL.pdf',
  en: '/files/tmobile/regulamin_zyskujesz_10_procent_z_doladowania_tmobile_EN.pdf',
  uk: '/files/tmobile/regulamin_zyskujesz_10_procent_z_doladowania_tmobile_UK.pdf'
}

export const PROMO_35_AUTO = {
  dateStart: '2023-10-02 12:00:00',
  dateEnd: '2023-10-31 23:59:59'
}
export const PROMO_35_AUTO_LINKS = {
  pl: '/files/tmobile/Regulamin_promocji_20_zl_ekstra_za_doladowanie_cykliczne_za_min_35_zl.pdf',
  en: '/files/tmobile/Regulamin_promocji_20_zl_ekstra_za_doladowanie_cykliczne_za_min_35_zl.pdf',
  uk: '/files/tmobile/Regulamin_promocji_20_zl_ekstra_za_doladowanie_cykliczne_za_min_35_zl.pdf'
}

export const PROMO_YEAR_CONFIG = {
  dateStart: '2024-05-08 00:00:00',
  dateEnd: '2024-09-30 23:59:59'
}

export const PROMO_YEAR_LINKS = {
  pl: '/files/tmobile/Warunki_oferty_Rok_Internetu_dla_uzytkownikow_T-Mobile_na_karte.pdf',
  en: '/files/tmobile/Warunki_oferty_Rok_Internetu_dla_uzytkownikow_T-Mobile_na_karte.pdf',
  uk: '/files/tmobile/Warunki_oferty_Rok_Internetu_dla_uzytkownikow_T-Mobile_na_karte.pdf'
}

export const PROMO_20_ZA_50 = {
  dateStart: '2023-11-02 12:00:00',
  dateEnd: '2023-11-19 23:59:59'
}
export const PROMO_20_ZA_50_LINKS = {
  pl: '/files/tmobile/Regulamin_promocji_20_zl_ekstra_za_doladowanie_za_min_50_zl_III.pdf',
  en: '/files/tmobile/Regulamin_promocji_20_zl_ekstra_za_doladowanie_za_min_50_zl_III.pdf',
  uk: '/files/tmobile/Regulamin_promocji_20_zl_ekstra_za_doladowanie_za_min_50_zl_III.pdf'
}

export const PROMO_GB_CONFIG = {
  v2: {
    dateStart: '2022-10-01 00:00:00',
    dateEnd: null
  }
}
export const PROMO_GB_LINKS = () => {
  if (DateClass.isDateActive(PROMO_GB_CONFIG.v2.dateStart, PROMO_GB_CONFIG.v2.dateEnd)) {
    return {
      pl: '/files/tmobile/GB_po_doladowaniu_PL.pdf',
      en: '/files/tmobile/GB_po_doladowaniu_EN.pdf',
      uk: '/files/tmobile/GB_po_doladowaniu_UK.pdf'
    }
  }
  return {}
}
