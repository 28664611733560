import eventBus from '@/common/events/eventBus'
import { EVENTS } from '@/common/const/events.const'

export default {
  mounted () {
    eventBus.$on(EVENTS.userSessionTimeOut, () => {
      this.$notify.info({
        message: this.$t('globalMessages.userSessionTimeOut')
      })
      this.$router.push({ name: 'login' })
    })
  },
  beforeDestroy () {
    eventBus.$off(EVENTS.userSessionTimeOut)
  }
}
