<template>
  <div v-if="bannersLength" class="banners-slider">
    <div class="banners-slider__container">
      <div :style="{ transform: getTranslateValue() }"
           :class="{ smooth: isSmoothSlideTransition() }"
           v-swipe
           @swipe.left="setNextSlideActive"
           @swipe.right="setPrevSlideActive"
           @mouseenter="stopSlideChangeTimeout()"
           @mouseleave="startSlideChangeTimeout()">
        <a v-for="(banner, index) in bannersList"
             :key="`banner_${index}`"
             :href="banner.url"
             :target="banner.target">
            <img :src="getBannerSrc(banner.img)" :alt="banner.alt" />
        </a>
      </div>
    </div>

    <div v-if="showSideControls()" class="banners-slider__side-controls">
      <a class="banners-slider__side-controls-prev" @click="setPrevSlideActive">
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'>
          <g><path d='M29,13l-12,12l12,12' stroke='#000000' stroke-width='6' stroke-linecap='round' fill='none'></path></g>
        </svg>
      </a>
      <a class="banners-slider__side-controls-next" @click="setNextSlideActive">
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'>
          <g><path d='M21,13l12,12l-12,12' stroke='#000000' stroke-width='6' stroke-linecap='round' fill='none'></path></g>
        </svg>
      </a>
    </div>

    <div v-if="showBottomControls()" class="banners-slider__bottom-controls">
      <a v-for="(banner, index) in activeBanners"
         :key="`control_${index}`"
         :class="{ active: isSlideActive(index) }"
         @click="setActiveSlide(index)">
      </a>
    </div>

    <div v-if="trackSlideProgress()" class="banners-slider__bottom-progress">
      <button type="button" @click="toggleSlideChangeTimeout" :class="{ paused: !slideChangeHandler }"></button>
      <div v-for="(banner, index) in activeBanners"
         :key="`control_${index}`"
         :class="{ active: isSlideActive(index) }"
         @click="setActiveSlide(index)">
        <div :style="{ width: getSlidePercentProgress(index) + '%' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import BannersMixin from '@/common/mixins/Topup/Banners.mixin'
import swipeDirective from '@/common/directives/swipe'

export default {
  name: 'BannersSlider',
  mixins: [BannersMixin],
  directives: {
    swipe: swipeDirective
  }
}
</script>

<style lang="scss" scoped>
$res-s: 576px;
$res-m: 768px;
$res-l: 992px;
$res-xl: 1200px;

.banners-slider {
  padding: 20px 10px 0;
  position: relative;

  @media (min-width: $res-m) {
    padding: 30px 0 0;
  }

  &__container {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;

    @media (min-width: $res-s) {
      width: 540px;
      height: 220px;
    }
    @media (min-width: $res-m) {
      width: 720px;
      height: 290px;
    }
    @media (min-width: $res-l) {
      width: 960px;
      height: 350px;
    }

    > div {
      display: flex;
      flex-wrap: nowrap;
      direction: ltr;
      -ms-touch-action: none;
      touch-action: none;

      &.smooth {
        transition: 0.2s all ease-in-out;
      }
      &.no-smooth {
        transition: none;
      }

      a {
        min-width: 100%;
        display: block;
        position: relative;

        @media (min-width: $res-s) {
          min-width: 540px;
          height: 220px;
        }
        @media (min-width: $res-m) {
          min-width: 720px;
          height: 290px;
        }
        @media (min-width: $res-l) {
          min-width: 960px;
          height: 350px;
        }

        > img {
          width: 100%;
        }
      }
    }
  }

  &__side-controls {
    a {
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: calc(50% - 12px);
      cursor: pointer;
      transition: 0.2s linear all;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 50%;

      @media (min-width: $res-s) {
        width: 40px;
        height: 40px;
        top: calc(50% - 20px);
      }

      @media (min-width: $res-m) {
        width: 50px;
        height: 50px;
        top: calc(50% - 25px);
      }

      svg {
        width: 30px;

        @media (min-width: $res-s) {
          width: 40px;
        }
        @media (min-width: $res-m) {
          width: 50px;
        }
      }
    }

    &-prev {
      left: 15px;

      &:hover {
        transform: translateX(-10px);
      }
    }

    &-next {
      right: 15px;

      &:hover {
        transform: translateX(10px);
      }
    }
  }

  &__bottom-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    a {
      width: 10px;
      height: 10px;
      margin: 0 3px;
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid black;

      &.active {
        background: #000000;
      }
    }
  }

  &__bottom-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    button {
      margin-right: 5px;
      width: 15px;
      height: 15px;
      display: inline-block;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 9 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,0h3v9h-3v-9z' fill='black'/%3E%3Cpath d='M6,0h3v9h-3v-9z' fill='black'/%3E%3C/svg%3E%0A");

      &.paused {
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 9 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='black' d='M1,0.2q0,-0.2,0.173,-0.1l6.654,4.3q0.173,0.1,0,0.2l-6.654,4.3q-0.173,0.1,-0.173,-0.1'/%3E%3C/g%3E%3C/svg%3E");
      }
    }

    div {
      width: 100%;
      height: 2px;
      cursor: pointer;
      border-radius: 0;
      background: #000000;
    }

    > div {
      max-width: 80px;
      background: #e2e2e2;
      margin: 0 5px;
    }
  }
}
</style>
