import { validateEmail } from '@/common/const/validators.const'
import { mapActions, mapGetters } from 'vuex'
const FORM_REF = 'remindPasswordForm'
export default {
  data () {
    const RULES = {
      email: [
        { required: true, message: this.$t('validation.fieldRequired'), trigger: 'blur' },
        { validator: validateEmail, message: this.$t('validation.emailNotValid'), trigger: 'blur' }
      ]
    }
    return {
      showConfirmation: false,
      showProcessError: false,
      form: {
        email: null
      },
      rules: RULES,
      formRef: FORM_REF
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isUserProcessing'
    })
  },
  methods: {
    ...mapActions([
      'doUserAccountRecoveryGenerateToken'
    ]),
    clearForm () {
      this.getForm().resetFields()
    },
    getForm () {
      return this.$refs[FORM_REF]
    },
    submit () {
      this.showConfirmation = false
      this.showProcessError = false
      this.getForm().validate((valid) => {
        if (!valid) {
          return false
        }

        const data = {
          email: this.form.email
        }
        this.doUserAccountRecoveryGenerateToken(data)
          .then(() => {
            this.clearForm()
            this.showConfirmation = true
          })
          .catch(() => {
            this.showProcessError = true
          })
      })
    }
  }
}
