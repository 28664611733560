import store from '@/common/vuex/store'
import { RESPONSE_STATUS } from '@/common/const/response.const'
import { PAYMENT_METHOD_STATUS } from '@/common/const/payment.const'
import { APP_ENV } from '@/common/const/env.const'

export const isUserLogged = () => {
  return store.state.user.data.status === RESPONSE_STATUS.ok
}

export const hasUserPaymentMethodSet = () => {
  return store.state.user.data.activePaymentMethod && store.state.user.data.activePaymentMethod.status === PAYMENT_METHOD_STATUS.active
}

export const hasUserTermsAgree = () => {
  return store.state.user.data.regulationsAgreed === true
}

export const LoggedGuard = function (to, from, next) {
  if (process.env.NODE_ENV === APP_ENV.development && sessionStorage && sessionStorage.getItem('debug')) {
    next()
    return
  }
  if (!isUserLogged()) {
    next({ name: 'home' })
    return
  }
  next()
}

export const userTermsRequiredGuard = function (to, from, next) {
  if (isUserLogged() && !hasUserTermsAgree()) {
    next({ name: 'userTerms' })
    return
  }
  next()
}

export const LoggedAutotopupGuard = function (to, from, next) {
  if (!isUserLogged()) {
    next({ name: 'singleTopup' })
    return
  }
  next()
}

export const NotLoggedGuard = function (to, from, next) {
  if (isUserLogged()) {
    next({ name: 'home' })
    return
  }
  next()
}

export const PaymentNotSetGuard = function (to, from, next) {
  if (hasUserPaymentMethodSet()) {
    next({ name: 'home' })
    return
  }
  next()
}

export const userTermsNotSetGuard = function (to, from, next) {
  if (hasUserTermsAgree()) {
    next({ name: 'home' })
    return
  }
  next()
}
