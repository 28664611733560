import { apiClass } from '../../class/api.class'
import { ENDPOINTS } from '../../const/endpoints.const'

const state = {
  stage: 1,
  data: null,
  transaction: null,
  loading: false
}

const mutations = {
  SET_TOPUP_STAGE (state, value) {
    state.stage = value
  },
  SET_TOPUP_DATA (state, data) {
    if (state.data === null) {
      state.data = {}
    }

    Object.assign(state.data, data)
  },
  SET_TOPUP_LOADING (state, value) {
    state.loading = value
  },
  SET_TOPUP_TRANSACTION (state, data) {
    state.transaction = data
  },
  RESET_TOPUP (state) {
    state.stage = 1
    state.data = null
    state.transaction = null
    state.loading = false
  }
}

const actions = {
  goBack ({ state, commit }) {
    if (state.stage > 1) {
      commit('SET_TOPUP_STAGE', state.stage - 1)
    }
  },
  setStage1Data ({ commit }, data) {
    commit('SET_TOPUP_DATA', data)
  },
  setStage2Data ({ commit }, data) {
    commit('SET_TOPUP_DATA', data)
  },
  sendTopup ({ commit }, data) {
    return apiClass.post(ENDPOINTS.phone.topup, data)
  },
  checkToken ({ commit }, token) {
    let endpoint = ENDPOINTS.phone.checkToken + '?token=' + token
    return apiClass.get(endpoint)
  },
  checkPhoneStatus (context, data) {
    return apiClass.post(ENDPOINTS.phone.checkPhone, data)
  },
  resetTopup ({ commit }) {
    commit('RESET_TOPUP')
  }
}

const getters = {
  getData (state) {
    return state.data
  },
  getTransaction (state) {
    return state.transaction
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
