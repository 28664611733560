import { TOPUP_FIRST_AREA, TOPUP_SECOND_AREA } from '@/enea/const/topup.const'

export const ENERGY_CALCULATION_STATUS = {
  ok: 'OK',
  operatorError: 'OPERATOR_ERROR',
  amountTooLow: 'AMOUNT_TO_LOW',
  codeError: 'CODE_ERROR',
  topupBlocked: 'TOPUP_BLOCKED'
}

export const ENERGY_CALCULATION_STATUS_LIST = {
  [ENERGY_CALCULATION_STATUS.ok]: 1,
  [ENERGY_CALCULATION_STATUS.operatorError]: -5,
  [ENERGY_CALCULATION_STATUS.amountTooLow]: -21,
  [ENERGY_CALCULATION_STATUS.codeError]: -22,
  [ENERGY_CALCULATION_STATUS.topupBlocked]: -23
}

export const ENERGY_TOPUP_MODEL = {
  code: '',
  codeVerify: '',
  phone: null,
  email: null,
  amount: null,
  amountSelect: '',
  payment: '',
  firstArea: TOPUP_FIRST_AREA,
  secondArea: TOPUP_SECOND_AREA,
  agreement: false,
  agreeShare: false,
  agreeContact: false,
  useEmail: true,
  useSms: false
}

export const ENERGY_CODE_LENGTH = 16

export const ENERGY_TOPUP_AREA_MAX = 100
export const ENERGY_TOPUP_FIRST_AREA = 50
export const ENERGY_TOPUP_SECOND_AREA = ENERGY_TOPUP_AREA_MAX - ENERGY_TOPUP_FIRST_AREA
