import { ToolsClass } from '@/common/class/tools.class'
import { apiClass } from '@/common/class/api.class'
import { ENDPOINTS } from '@/common/const/endpoints.const'
import { RESPONSE_STATUS } from '@/common/const/response.const'
import { AUTOTOPUP_DATA_MODEL } from '@/common/const/topup.const'

const state = {
  type: null,
  stage: 1,
  data: ToolsClass.deepExtend(AUTOTOPUP_DATA_MODEL),
  loading: false,
  authorised: false,
  regulations: null
}

const mutations = {
  SET_AUTOTOPUP_TYPE (state, value) {
    state.type = value
  },
  SET_AUTOTOPUP_STAGE (state, value) {
    state.stage = value
  },
  SET_AUTOTOPUP_DATA (state, data) {
    if (typeof data === 'object') {
      Object.assign(state.data, data)
    }
  },
  SET_AUTOTOPUP_SMS_CODE_ID (state, value) {
    state.data.smsCodeId = value
  },
  SET_AUTOTOPUP_CONTACT (state, data) {
    state.data.contact = data
  },
  SET_AUTOTOPUP_REGULATIONS_VERSION (state, value) {
    state.data.regulationsVersion = value
  },
  SET_AUTOTOPUP_LOADING (state, value) {
    state.loading = value
  },
  SET_AUTOTOPUP_AUTHORISED (state, value) {
    state.authorised = value
  },
  SET_AUTOTOPUP_REGULATIONS (state, data) {
    state.regulations = data
  },
  RESET_AUTOTOPUP (state) {
    state.type = null
    state.stage = 1
    state.data = ToolsClass.deepExtend(AUTOTOPUP_DATA_MODEL)
    state.loading = false
    state.authorised = false
  }
}

const actions = {
  saveAutotopup ({ commit, state }) {
    commit('SET_AUTOTOPUP_LOADING', true)
    return apiClass.post(ENDPOINTS.autotopup.add, state.data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          commit('RESET_AUTOTOPUP')
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_AUTOTOPUP_LOADING', false)
      })
  },
  updateAutotopup ({ commit }, data) {
    commit('SET_AUTOTOPUP_LOADING', true)
    return apiClass.put(ENDPOINTS.autotopup.update, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_AUTOTOPUP_LOADING', false)
      })
  },
  removeAutotopup ({ commit }, id) {
    commit('SET_AUTOTOPUP_LOADING', true)
    const url = ENDPOINTS.autotopup.remove.replace('{ID}', id)
    return apiClass.delete(url)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_AUTOTOPUP_LOADING', false)
      })
  },
  getAutotopupRegulations ({ commit }, { lang }) {
    let endpoint = ENDPOINTS.autotopup.getRegulations
    if (lang && lang.length) {
      endpoint = endpoint + '?language=' + lang
    }
    return apiClass.get(endpoint)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          commit('SET_AUTOTOPUP_REGULATIONS', response.body.regulations)
          commit('SET_AUTOTOPUP_REGULATIONS_VERSION', response.body.regulations.id)
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  resetAutotopup ({ commit }) {
    commit('RESET_AUTOTOPUP')
  },
  getAutotopupCheckPhone ({ commit, state }, data) {
    return apiClass.post(ENDPOINTS.autotopup.getCheckPhone, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  sendAutotopupAuthorizationCode ({ commit, state }) {
    commit('SET_AUTOTOPUP_LOADING', true)
    const data = {
      msisdn: state.data.contact.msisdn
    }
    return apiClass.post(ENDPOINTS.autotopup.createSmsCode, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          commit('SET_AUTOTOPUP_SMS_CODE_ID', response.body.smsCodeId)
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_AUTOTOPUP_LOADING', false)
      })
  },
  confirmAutotopupAuthorizationCode ({ commit, state }, data) {
    commit('SET_AUTOTOPUP_LOADING', true)
    return apiClass.post(ENDPOINTS.autotopup.confirmSmsCode, data)
      .then(response => {
        if (response.body && response.body.status === RESPONSE_STATUS.ok) {
          commit('SET_AUTOTOPUP_AUTHORISED', true)
          return Promise.resolve()
        }
        return Promise.reject(response.body.status)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_AUTOTOPUP_LOADING', false)
      })
  }
}

const getters = {
  getAutotopupData (state) {
    return state.data
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
