export const REGISTER_METHOD = {
  bank: 'BANK',
  fotoverify: 'PHOTO'
}

export const REGISTER_ERRORS = {
  registrationNotFound: 'REGISTRATION_NOT_FOUND',
  validationError: 'VALIDATION_ERROR',
  verificationError: 'VERIFICATION_ERROR',
  checkSimError: 'CHECK_SIM_ERROR',
  alreadyRegistered: 'ALREADY_REGISTERED',
  invalidIccidForMsisdn: 'INVALID_ICCID_FOR_MSISDN',
  msisdnInvalidOperator: 'MSISDN_INVALID_OPERATOR',
  unknownError: 'UNKNOWN_ERROR'
}

export const REGISTER_STATUS = {
  init: 'INIT',
  verificationInitError: 'VERIFICATION_INIT_ERROR',
  verificationStarted: 'VERIFICATION_STARTED',
  verificationPushReceived: 'VERIFICATION_PUSH_RECEIVED',
  verificationError: 'VERIFICATION_ERROR',
  verificationCompleted: 'VERIFICATION_COMPLETED',
  verificationRejected: 'VERIFICATION_REJECTED',
  operatorRegistrationPending: 'OPERATOR_REGISTRATION_PENDING',
  completed: 'COMPLETED',
  operatorRegistrationError: 'OPERATOR_REGISTRATION_ERROR'
}

export const REGISTER_STATUS_PENDING = [
  REGISTER_STATUS.init,
  REGISTER_STATUS.operatorRegistrationPending,
  REGISTER_STATUS.verificationStarted,
  REGISTER_STATUS.verificationCompleted,
  REGISTER_STATUS.verificationPushReceived
]
export const REGISTER_STATUS_SUCCESS = [
  REGISTER_STATUS.completed
]
export const REGISTER_STATUS_ERROR = [
  REGISTER_STATUS.operatorRegistrationError,
  REGISTER_STATUS.verificationInitError,
  REGISTER_STATUS.verificationError,
  REGISTER_STATUS.verificationRejected,
  REGISTER_ERRORS.registrationNotFound
]
export const REGISTER_STATUS_DESCRIPTION = {
  [REGISTER_STATUS.init]: 'Preces rejestracji karty SIM został poprawnie zainicjowany.',
  [REGISTER_STATUS.verificationInitError]: 'Nie udało się zainicjować weryfikacji danych osobowych.',
  [REGISTER_STATUS.verificationStarted]: 'Trwa weryfikacja danych osobowych.',
  [REGISTER_STATUS.verificationPushReceived]: 'Trwa przetwarzanie danych osobowych.',
  [REGISTER_STATUS.verificationError]: 'Nie udało się zweryfikować Twoich danych osobowych.',
  [REGISTER_STATUS.verificationCompleted]: 'Weryfikacja danych osobowych zakończona.',
  [REGISTER_STATUS.verificationRejected]: 'Weryfikacja Twoich danych osobowych zakończyła się błędem.',
  [REGISTER_STATUS.operatorRegistrationPending]: 'Trwa rejestracja karty SIM u operatora.',
  [REGISTER_STATUS.completed]: 'Proces rejestracji karty SIM został poprawnie zakończony.',
  [REGISTER_STATUS.operatorRegistrationError]: 'Nie udało się zarejestrowań karty SIM u operatora.',
  [REGISTER_ERRORS.registrationNotFound]: 'Nie znaleziono takiej rejestracji karty SIM.'
}
