import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showConfirmation: false,
      showProcessError: false
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isUserLoading'
    })
  },
  methods: {
    ...mapActions([
      'doUserAccountActivate'
    ]),
    goToLogin () {
      this.$router.push({ name: 'login' })
    },
    goToHomePage () {
      this.$router.push({ name: 'home' })
    }
  },
  mounted () {
    if (!this.token || !this.token.length) {
      this.goToHomePage()
      return
    }

    this.doUserAccountActivate(this.token)
      .then(() => {
        this.showConfirmation = true
      })
      .catch(() => {
        this.showProcessError = true
      })
  }
}
