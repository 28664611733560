import { mapActions, mapMutations } from 'vuex'
import { DateClass } from '@/common/class/date.class'

export default {
  methods: {
    ...mapMutations([
      'SET_HISTORY_FORM_DATA_FROM',
      'SET_HISTORY_FORM_DATA_TO'
    ]),
    ...mapActions([
      'getHistory'
    ])
  },
  mounted () {
    this.SET_HISTORY_FORM_DATA_FROM(DateClass.getPrevMonthFirstDay())
    this.SET_HISTORY_FORM_DATA_TO(DateClass.getCurrentDate())
    this.getHistory()
  }
}
