import { AUTO_TOPUP_TYPES } from '@/common/const/topup.const'
import { mapActions, mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations([
      'SET_AUTOTOPUP_TYPE'
    ]),
    ...mapActions([
      'resetAutotopup',
      'resetTopup'
    ]),
    goToTopupSingle () {
      this.$router.push({ name: 'singleTopup' })
    },
    goToTopupAuto () {
      this.$router.push({ name: 'autoTopup' })
    }
  },
  created () {
    this.resetTopup()
    this.resetAutotopup()
    this.SET_AUTOTOPUP_TYPE(AUTO_TOPUP_TYPES.date)
  }
}
