import { ToolsClass } from '@/common/class/tools.class'

export default (value, el, form, context) => {
  if (!value) {
    return true
  }
  const passwordMatchFieldName = el.getAttribute('password-match')
  if (!passwordMatchFieldName) {
    return false
  }
  let passwordMatchValue = ''
  ToolsClass.each(form.elements, element => {
    if (element.name === passwordMatchFieldName) {
      passwordMatchValue = element.value
    }
  })
  return value === passwordMatchValue
}
