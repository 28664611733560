import { AUTO_TOPUP_TYPE_DATE_CONDITION, AUTO_TOPUP_TYPES } from '@/common/const/topup.const'
import { DateClass } from '@/common/class/date.class'
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    contact: Object
  },
  data () {
    return {
      isLoading: false,
      showError: false,
      formErrors: false,
      topupConditions: AUTO_TOPUP_TYPE_DATE_CONDITION,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < DateClass.getDateInPast(1).getTime()
        }
      },
      form: {
        description: this.contact.description,
        condition: this.contact.recurringTopup ? this.contact.recurringTopup.recurringTopupType : null,
        amount: this.contact.recurringTopup ? this.contact.recurringTopup.amount : null,
        period: this.contact.recurringTopup && this.contact.recurringTopup.recurringTopupType === AUTO_TOPUP_TYPE_DATE_CONDITION.period ? this.contact.recurringTopup.day : 30,
        nextTopupDay: this.contact.recurringTopup && this.contact.recurringTopup.recurringTopupType === AUTO_TOPUP_TYPE_DATE_CONDITION.period ? DateClass.parseDate(this.contact.recurringTopup.nextTopupDate) : null,
        topupDay: this.contact.recurringTopup && this.contact.recurringTopup.recurringTopupType === AUTO_TOPUP_TYPE_DATE_CONDITION.day ? this.contact.recurringTopup.day : 10
      }
    }
  },
  computed: {
    isAutotopupSet: function () {
      return !!this.contact.recurringTopup
    },
    isConditionSet: function () {
      return this.form.condition !== null
    },
    isConditionPeriodSet: function () {
      return this.form.condition === AUTO_TOPUP_TYPE_DATE_CONDITION.period
    },
    isConditionDaySet: function () {
      return this.form.condition === AUTO_TOPUP_TYPE_DATE_CONDITION.day
    },
    isNameEmpty: function () {
      return !this.form.description || !this.form.description.length
    },
    isTopupAmountError: function () {
      return !this.form.amount
    },
    isTopupPeriodError () {
      return this.isConditionPeriodSet && !this.form.period
    },
    isNextTopupDayError () {
      return this.isConditionPeriodSet && !this.form.nextTopupDay
    },
    isTopupDayError () {
      return this.isConditionDaySet && !this.form.topupDay
    },
    topupDaysList: function () {
      let result = []
      for (let i = 1; i <= 28; i++) {
        result.push(i)
      }
      return result
    }
  },
  methods: {
    ...mapMutations([
      'SET_CONTACTS_FORM_DATA',
      'SET_CONTACTS_FORM_NAME',
      'RESET_AUTOTOPUP',
      'SET_AUTOTOPUP_TYPE',
      'SET_AUTOTOPUP_CONTACT'
    ]),
    ...mapActions([
      'updateAutotopup',
      'removeAutotopup',
      'updateContact',
      'removeContact'
    ]),
    formatPeriod () {
      let period = parseInt(this.form.period, 10)

      if (isNaN(period)) {
        this.form.period = null
        return
      }

      if (period < 1) {
        period = 1
      }

      if (period > 365) {
        period = 365
      }

      this.form.period = period
    },
    formatAmount () {
      let amount = parseInt(this.form.amount, 10)
      if (isNaN(amount)) {
        this.form.amount = null
        return
      }

      if (amount < this.$VUE_TOPUP_CONFIG.topupAmountMin) {
        amount = this.$VUE_TOPUP_CONFIG.topupAmountMin
      }

      if (amount > this.$VUE_TOPUP_CONFIG.topupAmountMax) {
        amount = this.$VUE_TOPUP_CONFIG.topupAmountMax
      }

      this.form.amount = amount
    },
    hideError () {
      this.showError = false
    },
    removeContactSubmit () {
      this.$confirm(this.$t('component.contacts.messges.removeConfirm'), this.$t('component.contacts.messges.removeHeader'), {
        confirmButtonText: this.$t('component.contacts.messges.removeConfirmButton'),
        cancelButtonText: this.$t('component.contacts.messges.removeCancelButton'),
        type: 'warning'
      })
        .then(() => {
          this.removeContact(this.contact.id)
            .then(() => {
              this.$notify.success({
                message: this.$t('component.contacts.messges.removeSuccess')
              })
              this.goBack()
            })
            .catch(() => {
              this.$notify.error({
                title: this.$t('component.contacts.messges.removeHeader'),
                message: this.$t('component.contacts.messges.removeError')
              })
            })
        })
        .catch(() => {})
    },
    removeAutotopupSubmit () {
      this.$confirm(this.$t('component.contactsEdit.messges.removeConfirm'), this.$t('component.contactsEdit.messges.removeHeader'), {
        confirmButtonText: this.$t('component.contactsEdit.messges.removeConfirmButton'),
        cancelButtonText: this.$t('component.contactsEdit.messges.removeCancelButton'),
        type: 'warning'
      })
        .then(() => {
          this.removeAutotopup(this.contact.recurringTopup.id)
            .then(() => {
              this.$notify.success({
                message: this.$t('component.contactsEdit.messges.removeSuccess')
              })
              this.goBack()
            })
            .catch(() => {
              this.$notify.error({
                title: this.$t('component.contactsEdit.messges.removeHeader'),
                message: this.$t('component.contactsEdit.messges.removeError')
              })
            })
        })
        .catch(() => {})
    },
    addAutotopup () {
      this.RESET_AUTOTOPUP()
      this.SET_AUTOTOPUP_TYPE(AUTO_TOPUP_TYPES.date)
      this.SET_AUTOTOPUP_CONTACT({
        msisdn: this.contact.msisdn,
        description: this.contact.description
      })
      this.$router.push({ name: 'autoTopup' })
    },
    submit () {
      this.formErrors = false
      if (this.isAutotopupSet) {
        if (!this.form.condition ||
          this.isNameEmpty ||
          this.isTopupAmountError ||
          this.isTopupPeriodError ||
          this.isNextTopupDayError ||
          this.isTopupDayError) {
          this.formErrors = true
          return
        }
      } else {
        if (this.isNameEmpty) {
          this.formErrors = true
          return
        }
      }

      this.SET_CONTACTS_FORM_DATA(this.contact)
      this.SET_CONTACTS_FORM_NAME(this.form.description)

      if (this.isAutotopupSet) {
        const autoTopupData = {
          id: this.contact.recurringTopup.id,
          day: this.isConditionPeriodSet ? this.form.period : this.form.topupDay,
          amount: this.form.amount,
          recurringTopupType: this.form.condition,
          nextTopupDate: this.isConditionPeriodSet ? DateClass.getFormattedDate(this.form.nextTopupDay, 'Y-m-d') : null
        }
        this.updateContact()
          .then(this.updateAutotopup(autoTopupData))
          .then(() => {
            this.$notify.success({
              message: this.$t('component.contactsEdit.messges.updateAutotopupSuccess')
            })
            this.goBack()
          })
          .catch(() => {
            this.$notify.error({
              message: this.$t('component.contactsEdit.messges.updateAutotopupError')
            })
          })
      } else {
        this.updateContact()
          .then(() => {
            this.$notify.success({
              message: this.$t('component.contactsEdit.messges.updateContactSuccess')
            })
            this.goBack()
          })
          .catch(() => {
            this.$notify.error({
              message: this.$t('component.contactsEdit.messges.updateContactError')
            })
          })
      }
    },
    goBack () {
      this.$router.push({ name: 'contacts' })
    }
  }
}
