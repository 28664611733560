import { ToolsClass } from '@/common/class/tools.class'

export default value => {
  if (!value) {
    return true
  }

  const simNumber = String(value)
  return ToolsClass.isSimNumberValid(simNumber)
}
