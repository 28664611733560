import { apiClass } from '@/common/class/api.class'
import { ENDPOINTS } from '@/common/const/endpoints.const'
import { TOPUP_RESPONSE_SUCCESS } from '@/common/const/topup.const'
import {
  BLIK_ID_LIST,
  CARD_ID_LIST,
  PAYMENT_GROUP,
  paywayGroupsList,
  WALLET_ID_LIST
} from '@/common/const/paywall.const'

const state = {
  loading: false,
  list: null
}
const mutations = {
  SET_PAYWALL_LOADING (state, value) {
    state.loading = value
  },
  SET_PAYWALL_GATEWAY_LIST (state, value) {
    state.list = value
  }
}
const actions = {
  getPaywallGatewayListFromApi ({ commit }) {
    commit('SET_PAYWALL_LOADING', true)
    return apiClass.get(ENDPOINTS.gateway.list)
      .then(response => {
        if (response.body.status === TOPUP_RESPONSE_SUCCESS) {
          commit('SET_PAYWALL_GATEWAY_LIST', response.body.gatewayList)
        }
        return Promise.resolve()
      })
      .finally(() => {
        commit('SET_PAYWALL_LOADING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}
const getters = {
  getPaywallGatewayList (state) {
    const result = []
    if (!state.list || typeof state.list !== 'object') {
      return result
    }
    return state.list
  },
  getPaymentGroupList (state) {
    if (state.loading) {
      return []
    }
    let paywayGroups = paywayGroupsList()

    const addPaywayToGroup = (payway) => {
      let matchedGroupType

      if (WALLET_ID_LIST.indexOf(payway.gatewayId) !== -1) {
        matchedGroupType = PAYMENT_GROUP.wallet
      }
      if (CARD_ID_LIST.indexOf(payway.gatewayId) !== -1) {
        matchedGroupType = PAYMENT_GROUP.creditCard
      }
      if (BLIK_ID_LIST.indexOf(payway.gatewayId) !== -1) {
        matchedGroupType = PAYMENT_GROUP.blik
      }
      if (!matchedGroupType) {
        matchedGroupType = PAYMENT_GROUP.fastTransfer
      }
      const paywayGroup = paywayGroups.find(group => group.type === matchedGroupType)
      if (paywayGroup) {
        paywayGroup.payways.push(payway)
      }
    }
    const getNotEmptyPaywayGroups = () => {
      const result = []
      paywayGroups.forEach(group => {
        if (group.payways.length) {
          result.push(group)
        }
      })
      return result
    }

    if (!state.list || !state.list.length) {
      return paywayGroups
    }

    state.list.forEach(payway => {
      addPaywayToGroup(payway)
    })
    return getNotEmptyPaywayGroups()
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
