import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    id: String | Number
  },
  data () {
    return {
      contact: null
    }
  },
  computed: {
    ...mapGetters({
      contacts: 'getContactsList'
    })
  },
  methods: {
    ...mapActions([
      'getContacts'
    ])
  },
  mounted () {
    if (!this.id) {
      this.$router.push({ name: 'contacts' })
    }
    this.getContacts()
      .then(() => {
        this.contact = this.contacts.find(contact => contact.id === parseInt(this.id, 10))
        if (!this.contact) {
          this.$router.push({ name: 'contacts' })
        }
      })
  }
}
