import { ToolsClass } from '@/common/class/tools.class'
import {
  ENERGY_CALCULATION_STATUS,
  ENERGY_CALCULATION_STATUS_LIST,
  ENERGY_TOPUP_AREA_MAX,
  ENERGY_TOPUP_MODEL
} from '@/common/const/energy.const'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      form: ToolsClass.deepExtend(ENERGY_TOPUP_MODEL),
      emailEmpty: true,
      phoneEmpty: true,
      formError: false,
      formSubmited: false,
      calculationError: false
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.energy.loading,
      formData: state => state.energy.formData,
      isDataChecked: state => state.energy.checkData !== null,
      isCodeVerified: state => state.energy.codeVerified,
      isCodeError: state => state.energy.codeError,
      calculationStatus: state => state.energy.calculationStatus,
      isManyZones: state => state.energy.checkData ? state.energy.checkData.numberOfZones > 1 : false
    }),
    checkAll: {
      get: function () {
        return this.form.agreement && this.form.agreeShare && this.form.agreeContact
      },
      set: function (value) {
        if (value) {
          this.form.agreement = true
          this.form.agreeShare = true
          this.form.agreeContact = true
        } else {
          this.form.agreement = false
          this.form.agreeShare = false
          this.form.agreeContact = false
        }
      }
    }
  },
  methods: {
    isTopupBlocked () {
      return this.calculationStatus && this.calculationStatus === ENERGY_CALCULATION_STATUS_LIST[ENERGY_CALCULATION_STATUS.topupBlocked]
    },
    formatAmount () {
      let amount = parseFloat(String(this.form.amount).replace(/\d(?=(\d{3})+\.)/g, '$& ').replace(',', '.'))

      if (isNaN(amount)) {
        this.form.amount = ''
        this.form.amountSelect = ''
        return
      }
      amount = amount.toFixed(2)

      if (amount < this.$VUE_TOPUP_CONFIG.topupAmountMin) {
        amount = this.$VUE_TOPUP_CONFIG.topupAmountMin
      }

      if (amount > this.$VUE_TOPUP_CONFIG.topupAmountMax) {
        amount = this.$VUE_TOPUP_CONFIG.topupAmountMax
      }
      this.form.amount = amount
      this.form.amountSelect = ''
    },
    isAmountError () {
      if (this.formSubmited) {
        return !this.isAmountValid()
      }

      if (this.form.amount === null) {
        return false
      }

      return !this.isAmountValid()
    },
    isAmountValid () {
      return this.form.amount !== null &&
        typeof parseFloat(this.form.amount) === 'number' &&
        parseFloat(this.form.amount) > 0
    },
    validateFirstArea () {
      this.form.firstArea = this.parseAreaValue(this.form.firstArea)
      this.calculateSecondArea()
    },
    validateSecondArea () {
      this.form.secondArea = this.parseAreaValue(this.form.secondArea)
      this.calculateFirstArea()
    },
    parseAreaValue (value) {
      value = parseInt(value, 10)
      if (value >= ENERGY_TOPUP_AREA_MAX || isNaN(value)) {
        value = ENERGY_TOPUP_AREA_MAX
      }
      if (value <= 0) {
        value = 0
      }
      return value
    },
    checkEmailEmpty () {
      if (this.form.email === null || !this.form.email.length) {
        this.emailEmpty = true
        return
      }
      this.emailEmpty = false
    },
    isEmailError () {
      if (this.formSubmited) {
        return !this.isEmailValid()
      }

      if (this.emailEmpty) {
        return false
      }

      return !this.isEmailValid()
    },
    checkPhoneEmpty () {
      if (this.form.phone === null || !this.form.phone.length) {
        this.phoneEmpty = true
        return
      }
      this.phoneEmpty = false
    },
    calculateFirstArea () {
      this.form.firstArea = ENERGY_TOPUP_AREA_MAX - this.form.secondArea
    },
    calculateSecondArea () {
      this.form.secondArea = ENERGY_TOPUP_AREA_MAX - this.form.firstArea
    },
    clearCode () {
      this.form = ToolsClass.deepExtend(ENERGY_TOPUP_MODEL)
      this.SET_ENERGY_CHECK_DATA(null)
      this.formError = false
      this.formSubmited = false
      this.emailEmpty = true
      this.phoneEmpty = true
    },
    isFirstAreaError () {
      return !this.isFirstAreaValid()
    },
    isFirstAreaValid () {
      return this.form.firstArea >= 0 && this.form.firstArea <= ENERGY_TOPUP_AREA_MAX
    },
    isSecondAreaError () {
      return !this.isSecondAreaValid()
    },
    isSecondAreaValid () {
      return this.form.secondArea >= 0 && this.form.secondArea <= ENERGY_TOPUP_AREA_MAX
    },
    isEmailValid () {
      if (!this.form.useEmail) {
        return true
      }
      return ToolsClass.isEmailValid(this.form.email)
    },
    isPhoneError () {
      if (this.formSubmited) {
        return !this.isPhoneValid()
      }

      if (this.phoneEmpty) {
        return false
      }

      return !this.isPhoneValid()
    },
    isTermsError () {
      if (this.formSubmited) {
        return !this.isTermsValid()
      }
      return false
    },
    isTermsValid () {
      return !!this.form.agreement
    },
    isPhoneValid () {
      if (!this.form.useSms) {
        return true
      }
      return ToolsClass.isPhoneNumberValid(this.form.phone)
    },
    isFormValid () {
      return this.isCodeVerified && this.isAmountValid() &&
        this.isFirstAreaValid() &&
        this.isSecondAreaValid() &&
        this.isEmailValid() &&
        this.isPhoneValid() &&
        this.isTermsValid()
    }
  }
}
