import store from '../vuex/store'

const DELAY = 60000

class SessionTimeClass {
  constructor (delay = DELAY) {
    this.interval = null
    this.delay = delay
  }

  startIntervalSessionCheck () {
    if (this.interval) {
      this.stopIntervalSessionCheck()
    }
    this.interval = setInterval(() => {
      this.checkUserSession()
    }, this.delay)
  }

  stopIntervalSessionCheck () {
    if (!this.interval) {
      return false
    }
    clearInterval(this.interval)
    this.interval = null
  }

  checkUserSession () {
    store.dispatch('checkUserSession')
      .catch(() => {})
  }
}

export let sessionTimeClass = new SessionTimeClass()
