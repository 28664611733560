import { TOPUP_PHONE_WITHOUT_PREFIX_LENGTH } from '@/common/const/topup.const'
import { REGEXPS } from '@/common/const/regexp.const'

const resolutionTablet = 768

export class ToolsClass {
  static deepExtend (value, dest, key) {
    if (value instanceof Array) {
      let newArr = []
      value.forEach((arrValue, index) => {
        this.deepExtend(arrValue, newArr, index)
      })
      addResult(newArr)
    } else if (value instanceof Date) {
      addResult(new Date(value))
    } else if (value instanceof Object) {
      let newObj = {}
      for (let key2 in value) {
        this.deepExtend(value[key2], newObj, key2)
      }
      addResult(newObj)
    } else {
      addResult(value)
    }
    return dest

    function addResult (result) {
      if (typeof dest === 'undefined') {
        dest = result
      } else {
        dest[key] = result
      }
    }
  }

  static isPhoneResolution () {
    return window.innerWidth < resolutionTablet
  }

  static cleanPhoneNumber (phone) {
    if (!phone) {
      return ''
    }
    return String(phone).replace(/-/g, '').replace(/ /g, '')
  }

  static cleanBlikCode (code) {
    if (!code) {
      return ''
    }
    return String(code).replace(/-/g, '').replace(/ /g, '')
  }

  static getPhoneNumberWithoutPrefix (phone) {
    if (!phone) {
      return ''
    }
    const phoneLength = String(phone).length
    if (phoneLength === TOPUP_PHONE_WITHOUT_PREFIX_LENGTH) {
      return phone
    }
    return phone.substr(2, TOPUP_PHONE_WITHOUT_PREFIX_LENGTH)
  }

  static isPhoneNumberValid (phone) {
    let cleanPhoneNumber = ToolsClass.cleanPhoneNumber(phone)
    return cleanPhoneNumber && typeof cleanPhoneNumber === 'string' && REGEXPS.phoneNumber.test(cleanPhoneNumber)
  }

  static isPasswordStrength (password) {
    const minLength = 8

    return password &&
      typeof password === 'string' &&
      password.length >= minLength &&
      REGEXPS.password.letters.test(password) &&
      REGEXPS.password.symbols.test(password) &&
      REGEXPS.password.numbers.test(password)
  }

  static isSimNumberValid (value) {
    return REGEXPS.simNumber.test(value)
  }

  static isEmailValid (email) {
    return email && typeof email === 'string' && REGEXPS.emailValidator.test(email)
  }

  static getCurrentYear () {
    return new Date().getFullYear()
  }

  static b64EncodeUnicode (str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([\dA-F]{2})/g,
      function toSolidBytes (match, p1) {
        return String.fromCharCode('0x' + p1)
      }))
  }

  static b64DecodeUnicode (str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  /**
   * Przewinięcie do elementu
   * @param {object} element
   * @param {number} [offset]
   * @param {string} [efect]
   */
  static scrollToElement (element, offset = 0, efect = 'smooth') {
    if (!element || typeof element !== 'object') {
      ToolsClass.scrollToTop()
      return
    }
    const positionTop = parseInt(element.getBoundingClientRect().top + window.scrollY + offset, 10)
    window.scrollTo({ top: positionTop, behavior: efect })
  }

  /**
   * @param {object} elem
   * @param {number} [offset]
   * @returns {Promise<unknown>}
   */
  static scrollToElementTop (elem, offset = 0) {
    if (!window || !window.scrollTo || !elem) {
      return
    }

    const rect = elem.getBoundingClientRect()
    const targetPosition = rect.top + window.scrollY + offset

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth'
    })

    return new Promise(resolve => {
      const scrollHandler = () => {
        if (window.scrollY === targetPosition) {
          window.removeEventListener('scroll', scrollHandler)
          resolve()
        }
      }
      window.addEventListener('scroll', scrollHandler)
    })
  }

  static scrollToTop () {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  static sendHeightToIframeParent () {
    const bodyHeight = document.getElementsByTagName('body')[0].offsetHeight + 20
    const data = {
      type: 'HEIGHT_CHANGE',
      message: bodyHeight + 'px'
    }
    window.parent.postMessage(JSON.stringify(data), '*')
  }

  /**
   * Metoda do iteracji zarówno po tablicach jak i obiektach
   * @param {array|object} iterable
   * @param {function} callback
   * @returns {void}
   */
  static each (iterable, callback) {
    if (typeof iterable !== 'object') {
      return
    }
    if (iterable instanceof Array) {
      iterable.forEach((value, index, array) => {
        callback(value, index, array)
      })
    } else {
      Object.keys(iterable).forEach(key => {
        callback(iterable[key], key, iterable)
      })
    }
  }

  /**
   * Metoda do generowania UUID
   * @returns {string}
   */
  static generateUUID () {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    // return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
  }
}
