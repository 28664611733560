import { ToolsClass } from '@/common/class/tools.class'
import { TOPUP_PHONE_WITHOUT_PREFIX_LENGTH } from '@/common/const/topup.const'

export default value => {
  if (!value) {
    return true
  }

  let phone = String(value)

  // czy numer ma dobrą długość
  if (ToolsClass.cleanPhoneNumber(phone).length < TOPUP_PHONE_WITHOUT_PREFIX_LENGTH) {
    return false
  }
  // czy numer jest prawidłowy
  return ToolsClass.isPhoneNumberValid(phone)
}
