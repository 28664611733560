import { apiClass } from '../../class/api.class'
import { ENDPOINTS } from '../../const/endpoints.const'

const state = {
  loading: false,
  errorData: null,
  statusData: null,
  redirectUrl: null,
  bankList: null
}
const mutations = {
  SET_REGISTERSIM_LOADING (state, value) {
    state.loading = value
  },
  SET_REGISTERSIM_ERROR_DATA (state, data) {
    state.errorData = data
  },
  SET_REGISTERSIM_STATUS_DATA (state, data) {
    state.statusData = data
  },
  SET_REGISTERSIM_REDIRECT_URL (state, value) {
    state.redirectUrl = value
  },
  SET_REGISTERSIM_BANK_LIST (state, data) {
    state.bankList = data
  }
}
const actions = {
  sendRegisterSimData ({ commit }, data) {
    commit('SET_REGISTERSIM_LOADING', true)
    commit('SET_REGISTERSIM_ERROR_DATA', null)
    return apiClass.post(ENDPOINTS.registerSim.sendRegisterData, data)
      .then(response => {
        commit('SET_REGISTERSIM_REDIRECT_URL', response.body.redirectUrl)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_REGISTERSIM_LOADING', false)
        commit('SET_REGISTERSIM_ERROR_DATA', error.body)
        return Promise.reject(error.body)
      })
  },
  getRegisterSimStatus ({ commit }, id) {
    commit('SET_REGISTERSIM_LOADING', true)
    const endpoint = ENDPOINTS.registerSim.getRegisterStatus.replace('{ID}', id)
    return apiClass.get(endpoint)
      .then(response => {
        commit('SET_REGISTERSIM_STATUS_DATA', response.body)
      })
      .catch(error => {
        commit('SET_REGISTERSIM_ERROR_DATA', error.body)
      })
      .finally(() => {
        commit('SET_REGISTERSIM_LOADING', false)
      })
  },
  getRegisterSimBankList ({ commit }) {
    commit('SET_REGISTERSIM_LOADING', true)
    const endpoint = ENDPOINTS.registerSim.getBankList
    return apiClass.get(endpoint)
      .then(response => {
        commit('SET_REGISTERSIM_BANK_LIST', response.body)
      })
      .finally(() => {
        commit('SET_REGISTERSIM_LOADING', false)
      })
  }
}
const getters = {
  getRegisterSimBanks (state) {
    if (!!state.bankList && Array.isArray(state.bankList) && !!state.bankList.length) {
      return state.bankList
    }
    return []
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
