export const OPERATORS_TOPUP = {
  plus: 'PLUS',
  plush: 'PLUSH',
  play: 'PLAY',
  heyah: 'HEYAH',
  tmobile: 'TMOBILE',
  a2mobile: 'A2MOBILE',
  orange: 'ORANGE',
  njumobile: 'NJUMOBILE',
  wrodzinie: 'WRODZINIE'
}
export const OPERATORS_TOPUP_ACTIVE = [
  OPERATORS_TOPUP.orange,
  OPERATORS_TOPUP.njumobile,
  OPERATORS_TOPUP.plus,
  OPERATORS_TOPUP.plush,
  OPERATORS_TOPUP.tmobile,
  OPERATORS_TOPUP.heyah,
  OPERATORS_TOPUP.play,
  OPERATORS_TOPUP.wrodzinie,
  OPERATORS_TOPUP.a2mobile
]
export const OPERATORS_TOPUP_AMOUNT_TYPE = {
  list: 'LIST',
  any: 'ANY'
}
export const OPERATORS_TOPUP_AMOUNTS = {
  [OPERATORS_TOPUP.orange]: {
    type: OPERATORS_TOPUP_AMOUNT_TYPE.list,
    values: [5, 10, 30, 40, 50, 100, 200]
  },
  [OPERATORS_TOPUP.njumobile]: {
    type: OPERATORS_TOPUP_AMOUNT_TYPE.list,
    values: [5, 10, 30, 40, 50, 100, 200]
  },
  [OPERATORS_TOPUP.plus]: {
    type: OPERATORS_TOPUP_AMOUNT_TYPE.any,
    min: 5,
    max: 500
  },
  [OPERATORS_TOPUP.plush]: {
    type: OPERATORS_TOPUP_AMOUNT_TYPE.any,
    min: 5,
    max: 500
  },
  [OPERATORS_TOPUP.tmobile]: {
    type: OPERATORS_TOPUP_AMOUNT_TYPE.any,
    min: 5,
    max: 500
  },
  [OPERATORS_TOPUP.heyah]: {
    type: OPERATORS_TOPUP_AMOUNT_TYPE.any,
    min: 5,
    max: 500
  },
  [OPERATORS_TOPUP.play]: {
    type: OPERATORS_TOPUP_AMOUNT_TYPE.any,
    min: 5,
    max: 500
  },
  [OPERATORS_TOPUP.wrodzinie]: {
    type: OPERATORS_TOPUP_AMOUNT_TYPE.any,
    min: 10,
    max: 250
  },
  [OPERATORS_TOPUP.a2mobile]: {
    type: OPERATORS_TOPUP_AMOUNT_TYPE.list,
    values: [20, 25, 30]
  }
}
export const OPERATORS_TOPUP_VALUE = {
  [OPERATORS_TOPUP.orange]: 'ORANGE',
  [OPERATORS_TOPUP.njumobile]: 'ORANGE',
  [OPERATORS_TOPUP.plus]: 'PLUS',
  [OPERATORS_TOPUP.plush]: 'PLUS',
  [OPERATORS_TOPUP.tmobile]: 'TMOBILE',
  [OPERATORS_TOPUP.heyah]: 'HEYAH',
  [OPERATORS_TOPUP.play]: 'PLAY',
  [OPERATORS_TOPUP.wrodzinie]: 'WRODZINIE',
  [OPERATORS_TOPUP.a2mobile]: 'A2MOBILE'
}

export const OPERATORS_ENERGY = {
  energa: 'ENERGA',
  eon: 'EON',
  enea: 'ENEA',
  tauron: 'TAURON',
  pge: 'PGE'
}

export const PRODUCTS_CODES = {
  xboxOne: 'XBOXONE',
  xboxGamePass: 'XBOXLIVE',
  playstationPlus: 'PLAYSTATIONPLUS',
  playstationStore: 'PLAYSTATIONSTORE',
  nintendo: 'NINTENDO',
  spotify: 'SPOTIFY',
  settlers: 'SETTLERS',
  allegro: 'ALLEGRO',
  empikCard: 'EMPIKCARD',
  empikGo: 'EMPIKGO',
  cdaPremium: 'CDAPREMIUM',
  player: 'PLAYER',
  justEat: 'JUSTEAT',
  twitch: 'TWITCH',
  roblox: 'ROBLOX'
}

export const PRODUCTS_CODES_GROUPS = {
  multimedia: {
    label: 'Multimedia',
    products: [
      PRODUCTS_CODES.spotify,
      PRODUCTS_CODES.player,
      PRODUCTS_CODES.twitch
    ]
  },
  games: {
    label: 'Gry',
    products: [
      PRODUCTS_CODES.xboxOne,
      PRODUCTS_CODES.xboxGamePass,
      PRODUCTS_CODES.playstationStore,
      PRODUCTS_CODES.nintendo,
      PRODUCTS_CODES.settlers,
      PRODUCTS_CODES.roblox
    ]
  },
  shopping: {
    label: 'Zakupy',
    products: [
      PRODUCTS_CODES.allegro,
      PRODUCTS_CODES.empikCard,
      PRODUCTS_CODES.empikGo,
      PRODUCTS_CODES.justEat
    ]
  }
}

export const PRODUCTS_CODES_VALUE = {
  [PRODUCTS_CODES.nintendo]: [
    { productId: 'PLPIN10860001', value: 70, name: 'Nintendo eShop Card 70' },
    { productId: 'PLPIN10860002', value: 120, name: 'Nintendo eShop Card 120' },
    { productId: 'PLPIN10860003', value: 250, name: 'Nintendo eShop Card 250' },
    { productId: 'PLPIN10860008', value: 370, name: 'Nintendo eShop Card 370' },
    { productId: 'PLPIN10860007', value: 500, name: 'Nintendo eShop Card 500' }
  ],
  [PRODUCTS_CODES.playstationPlus]: [
    { productId: 'PLPIN10820007', value: 100, name: 'SONYPLUS 3M', time: 3 },
    { productId: 'PLPIN10820008', value: 240, name: 'SONYPLUS 12M', time: 12 }
  ],
  [PRODUCTS_CODES.playstationStore]: [
    { productId: 'PLPIN10820002', value: 50, name: 'Sony PlayStation Network Card 50 PLN' },
    { productId: 'PLPIN10820003', value: 100, name: 'Sony PlayStation Network Card 100 PLN' },
    { productId: 'PLPIN10820005', value: 200, name: 'Sony PlayStation Network Card 200 PLN' }
  ],
  [PRODUCTS_CODES.settlers]: [
    { productId: 'PLPIN10870001', value: 40, name: 'Ubisoft (the Settlers)' },
    { productId: 'PLPIN10870002', value: 80, name: 'Ubisoft (the Settlers)' }
  ],
  [PRODUCTS_CODES.spotify]: [
    { productId: 'PLPIN10880001', value: 20, name: 'SPOTIFY 20 PLN', time: 1 },
    { productId: 'PLPIN10880002', value: 60, name: 'SPOTIFY 60 PLN', time: 3 },
    { productId: 'PLPIN10880003', value: 120, name: 'SPOTIFY 120 PLN', time: 6 }
  ],
  [PRODUCTS_CODES.xboxGamePass]: [
    { productId: 'PLPIN10750044', value: 84.90, name: 'Xbox Game Pass Core 3m', time: 3 },
    { productId: 'PLPIN10750046', value: 124.90, name: 'Xbox Game Pass Core 6m', time: 6 },
    { productId: 'PLPIN10750045', value: 249.90, name: 'Xbox Game Pass Core 12m', time: 12 }
  ],
  [PRODUCTS_CODES.xboxOne]: [
    { productId: 'PLPIN10750010', value: 20, name: 'MSXBOX 2016 20 PLN' },
    { productId: 'PLPIN10750011', value: 50, name: 'MSXBOX 2016 50 PLN' },
    { productId: 'PLPIN10750012', value: 70, name: 'MSXBOX 2016 70 PLN' },
    { productId: 'PLPIN10750013', value: 100, name: 'MSXBOX 2016 100 PLN' },
    { productId: 'PLPIN10750014', value: 200, name: 'MSXBOX 2016 200 PLN' }
  ],
  [PRODUCTS_CODES.allegro]: [
    { productId: 'PLPIN11370005', value: 50, name: 'Allegro 50' },
    { productId: 'PLPIN11370006', value: 100, name: 'Allegro 100' },
    { productId: 'PLPIN11370004', value: 200, name: 'Allegro 200' }
  ],
  [PRODUCTS_CODES.empikCard]: [
    { productId: 'PLPIN11540001', value: 25, name: 'eKarta Empik 25 PLN' },
    { productId: 'PLPIN11540002', value: 50, name: 'eKarta Empik 50 PLN' },
    { productId: 'PLPIN11540003', value: 100, name: 'eKarta Empik 100 PLN' },
    { productId: 'PLPIN11540004', value: 200, name: 'eKarta Empik 200 PLN' }
  ],
  [PRODUCTS_CODES.empikGo]: [
    { productId: 'PLPIN11540013', value: 39.99, name: 'EmpikGO Audiobook Ebook 1M', time: 1 },
    { productId: 'PLPIN11540014', value: 119.97, name: 'EmpikGO Audiobook Ebook 3M', time: 3 },
    { productId: 'PLPIN11540015', value: 189.99, name: 'EmpikGO Audiobook Ebook 6M', time: 6 },
    { productId: 'PLPIN11540016', value: 359.99, name: 'EmpikGO Audiobook Ebook 12M', time: 12 }
  ],
  [PRODUCTS_CODES.cdaPremium]: [
    { productId: 'PLPIN11440003', value: 25, name: 'CDA Premium 1M', time: 1 },
    { productId: 'PLPIN11440004', value: 70, name: 'CDA Premium 3M', time: 3 }
  ],
  [PRODUCTS_CODES.player]: [
    { productId: 'PLPIN11610001', value: 20, name: 'Player VOD' },
    { productId: 'PLPIN11610002', value: 40, name: 'Player VOD + TV' }
  ],
  [PRODUCTS_CODES.justEat]: [
    { productId: 'PLPIN11820001', value: 40, name: 'Pyszne.pl 40 zł' },
    { productId: 'PLPIN11820002', value: 50, name: 'Pyszne.pl 50 zł' },
    { productId: 'PLPIN11820003', value: 60, name: 'Pyszne.pl 60 zł' },
    { productId: 'PLPIN11820004', value: 70, name: 'Pyszne.pl 70 zł' },
    { productId: 'PLPIN11820005', value: 100, name: 'Pyszne.pl 100 zł' },
    { productId: 'PLPIN11820006', value: 200, name: 'Pyszne.pl 200 zł' }
  ],
  [PRODUCTS_CODES.twitch]: [
    { productId: 'PLPIN11620004', value: 70, origValue: 15, name: 'Twitch Digital Code 15 EUR' },
    { productId: 'PLPIN11620005', value: 116, origValue: 25, name: 'Twitch Digital Code 25 EUR' },
    { productId: 'PLPIN11620006', value: 232, origValue: 50, name: 'Twitch Digital Code 50 EUR' }
  ],
  [PRODUCTS_CODES.roblox]: [
    { productId: 'PLPIN11630001', value: 50, name: 'Roblox 50 zł' },
    { productId: 'PLPIN11630002', value: 100, name: 'Roblox 100 zł' }
  ]
}

export const PRODUCTS_SONY = [
  PRODUCTS_CODES.playstationPlus,
  PRODUCTS_CODES.playstationStore
]

export const OPERATORS_LOGO = {
  [OPERATORS_TOPUP.orange]: 'orange.svg',
  [OPERATORS_TOPUP.njumobile]: 'njumobile.svg',
  [OPERATORS_TOPUP.plus]: 'plus.svg',
  [OPERATORS_TOPUP.plush]: 'plush.svg',
  [OPERATORS_TOPUP.tmobile]: 'tmobile.svg',
  [OPERATORS_TOPUP.heyah]: 'heyah.svg',
  [OPERATORS_TOPUP.play]: 'play.svg',
  [OPERATORS_TOPUP.wrodzinie]: 'wrodzinie.svg',
  [OPERATORS_TOPUP.a2mobile]: 'a2mobile.svg',
  [OPERATORS_ENERGY.pge]: 'pge.svg',
  [OPERATORS_ENERGY.tauron]: 'tauron.svg',
  [OPERATORS_ENERGY.enea]: 'enea.svg',
  [OPERATORS_ENERGY.energa]: 'energa.svg',
  [OPERATORS_ENERGY.eon]: 'eon.svg',
  [PRODUCTS_CODES.nintendo]: 'Nintendo.png',
  [PRODUCTS_CODES.playstationPlus]: 'PlayStationPlus.png',
  [PRODUCTS_CODES.playstationStore]: 'PlayStationStore.png',
  [PRODUCTS_CODES.settlers]: 'settlers.svg',
  [PRODUCTS_CODES.spotify]: 'spotify.svg',
  [PRODUCTS_CODES.xboxGamePass]: 'xbox-game-pass.png',
  [PRODUCTS_CODES.xboxOne]: 'xbox-one.svg',
  [PRODUCTS_CODES.allegro]: 'allegro.svg',
  [PRODUCTS_CODES.empikCard]: 'empik-card.svg',
  [PRODUCTS_CODES.empikGo]: 'empik-go.svg',
  [PRODUCTS_CODES.cdaPremium]: 'cdapremium.svg',
  [PRODUCTS_CODES.player]: 'player.svg',
  [PRODUCTS_CODES.justEat]: 'pyszne.svg',
  [PRODUCTS_CODES.twitch]: 'twitch.svg',
  [PRODUCTS_CODES.roblox]: 'roblox.svg'
}

export const OPERATORS_NAME = {
  [OPERATORS_ENERGY.pge]: 'PGE Polska Grupa Energetyczna S.A.',
  [OPERATORS_ENERGY.tauron]: 'Tauron Polska Energia S.A.',
  [OPERATORS_ENERGY.enea]: 'Enea S.A.',
  [OPERATORS_ENERGY.energa]: 'Grupa Kapitałowa Energa',
  [OPERATORS_ENERGY.eon]: 'E.ON Polska S.A.'
}
