<template>
  <div class="maintenance-global" v-if="isMaintenanceGlobalInfo() && isOpen">
    <div class="maintenance-global-content">
      <a class="close" href="" @click.prevent="close"></a>
      <h4>Uwaga!</h4>
      <p>W związku z koniecznością wykonania czynności serwisowych, w dniach:</p>
      <ul>
        <li v-for="(maintenance, index) in maintenanceList" :key="index">
          od <strong>{{ getFormattedDate(maintenance.start) }}</strong> do <strong>{{ getFormattedDate(maintenance.end) }}</strong>
        </li>
      </ul>
      <p>nastąpi przerwa w dostępie do zakupu doładowań.</p>
    </div>
  </div>
</template>

<script>
import { DateClass } from '@/common/class/date.class'
import {
  MAINTENANCE_GLOBAL,
  MAINTENANCE_GLOBAL_INFO_END,
  MAINTENANCE_GLOBAL_INFO_START
} from '@/common/const/maintenance.const'

export default {
  name: 'MaintenanceInfo',
  data () {
    return {
      isOpen: true,
      maintenanceList: MAINTENANCE_GLOBAL
    }
  },
  methods: {
    close () {
      this.isOpen = false
    },
    isMaintenanceGlobalInfo () {
      if (!MAINTENANCE_GLOBAL_INFO_START && !MAINTENANCE_GLOBAL_INFO_END) {
        return false
      }
      return DateClass.isDateActive(MAINTENANCE_GLOBAL_INFO_START, MAINTENANCE_GLOBAL_INFO_END)
    },
    getFormattedDate (value) {
      const date = new Date(Date.parse(value))
      return DateClass.getFormattedDate(date)
    }
  }
}
</script>

<style scoped lang="scss">
.maintenance-global {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .maintenance-global-content {
    background-color: #f2f2f2;
    border: 1px solid #d6d6d6;
    padding: 20px 20px;
    margin: 40px 20px 0;
    width: calc(100% - 40px);
    max-width: 700px;

    @media (min-width: 992px) {
      padding: 20px 40px;
    }

    a.close {
      float: right;
      width: 14px;
      height: 14px;
      display: inline-block;
      background: url(/images/common/close.png) no-repeat center center;
      background-size: contain;
    }

    h4 {
      margin: 0 0 10px;
      line-height: 1.4rem;
    }

    p {
      margin: 0 0 10px;
      line-height: 1.4rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      margin: 0 0 10px;
    }
  }
}
</style>
