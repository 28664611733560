import { AUTO_TOPUP_TYPE_DATE_CONDITION } from '@/common/const/topup.const'
import { mapMutations, mapState } from 'vuex'
import { DateClass } from '@/common/class/date.class'
import { GoogleAnalytics } from '@/common/class/ga.class'

export default {
  data () {
    return {
      isLoading: false,
      showError: false,
      formErrors: false,
      topupConditions: AUTO_TOPUP_TYPE_DATE_CONDITION,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < DateClass.getDateInPast(1).getTime()
        }
      },
      form: {
        condition: null,
        period: 30,
        amount: 50,
        nextTopupDay: new Date(),
        topupDay: 10
      }
    }
  },
  computed: {
    ...mapState({
      autotopup: state => state.autotopup.data
    }),
    isConditionSet: function () {
      return this.form.condition !== null
    },
    isConditionPeriodSet: function () {
      return this.form.condition === AUTO_TOPUP_TYPE_DATE_CONDITION.period
    },
    isConditionDaySet: function () {
      return this.form.condition === AUTO_TOPUP_TYPE_DATE_CONDITION.day
    },
    isTopupAmountError: function () {
      return !this.form.amount
    },
    isTopupPeriodError () {
      return this.isConditionPeriodSet && !this.form.period
    },
    isNextTopupDayError () {
      return this.isConditionPeriodSet && !this.form.nextTopupDay
    },
    isTopupDayError () {
      return this.isConditionDaySet && !this.form.topupDay
    },
    topupDaysList: function () {
      let result = []
      for (let i = 1; i <= 28; i++) {
        result.push(i)
      }
      return result
    }
  },
  methods: {
    ...mapMutations([
      'SET_AUTOTOPUP_STAGE',
      'SET_AUTOTOPUP_DATA'
    ]),
    hideError () {
      this.showError = false
    },
    formatPeriod () {
      let period = parseInt(this.form.period, 10)

      if (isNaN(period)) {
        this.form.period = null
        return
      }

      if (period < 1) {
        period = 1
      }

      if (period > 365) {
        period = 365
      }

      this.form.period = period
    },
    formatAmount () {
      let amount = parseInt(this.form.amount, 10)
      if (isNaN(amount)) {
        this.form.amount = null
        return
      }

      if (amount < this.$VUE_TOPUP_CONFIG.topupAmountMin) {
        amount = this.$VUE_TOPUP_CONFIG.topupAmountMin
      }

      if (amount > this.$VUE_TOPUP_CONFIG.topupAmountMax) {
        amount = this.$VUE_TOPUP_CONFIG.topupAmountMax
      }

      this.form.amount = amount
    },
    submit () {
      this.formErrors = false

      if (!this.form.condition) {
        this.showError = true
        return
      }

      if (this.isTopupAmountError || this.isTopupPeriodError || this.isNextTopupDayError || this.isTopupDayError) {
        this.formErrors = true
        return
      }
      const formData = {
        day: this.isConditionPeriodSet ? this.form.period : this.form.topupDay,
        amount: this.form.amount,
        recurringTopupType: this.form.condition,
        nextTopupDate: this.isConditionPeriodSet ? DateClass.getFormattedDate(this.form.nextTopupDay, 'Y-m-d') : null
      }
      GoogleAnalytics.addFormSubmitEvent()
      this.SET_AUTOTOPUP_DATA(formData)
      this.SET_AUTOTOPUP_STAGE(2)
    }
  },
  mounted () {
    if (this.autotopup && this.autotopup.recurringTopupType) {
      this.form.condition = this.autotopup.recurringTopupType
      this.form.amount = this.autotopup.amount
      if (this.isConditionPeriodSet) {
        this.form.period = this.autotopup.day
        this.form.nextTopupDay = DateClass.parseDate(this.autotopup.nextTopupDate)
      } else {
        this.form.topupDay = this.autotopup.day
      }
    }
  }
}
