export const NOTIFY_TYPE = {
  info: 'info',
  success: 'success',
  error: 'error'
}

export const NOTIFY_POSITIONS = {
  topCenter: 'top-center',
  topRight: 'top-right'
}

const DEFAULT_OPTIONS = {
  position: NOTIFY_POSITIONS.topCenter,
  type: NOTIFY_TYPE.info,
  canClose: true,
  autoClose: 3000,
  onClose: () => {}
}

export class Notifier {
  static show (options) {
    return new Notify(options)
  }
}

class Notify {
  #notifyElement
  #notifyText
  #notifyClose

  constructor (options) {
    this.#notifyElement = document.createElement('div')
    this.#notifyText = document.createElement('p')
    this.#notifyClose = document.createElement('button')
    this.#notifyElement.classList.add('notify')

    this.#notifyClose.addEventListener('click', () => {
      this.removeSingleNotify()
    })

    requestAnimationFrame(() => {
      this.#notifyElement.classList.add('show')
    })

    Object.entries({ ...DEFAULT_OPTIONS, ...options }).forEach(([key, value]) => {
      this[key] = value
    })
  }

  set position (position) {
    const wrapper = document.querySelector(`.notify-wrapper[data-position="${position}"]`) || createNotifyWrapper(position)
    this.#notifyElement.append(this.#notifyText, this.#notifyClose)
    wrapper.append(this.#notifyElement)
  }

  set text (text) {
    this.#notifyText.innerHTML = text
  }

  set type (type) {
    this.#notifyElement.classList.add(`notify-${type}`)
  }

  set autoClose (value) {
    if (value === false) return
    setTimeout(() => {
      this.removeSingleNotify()
    }, value)
  }

  set canClose (value) {
    value ? this.#notifyElement.classList.add('can-close') : this.#notifyElement.classList.remove('can-close')
  }

  removeSingleNotify () {
    const wrapper = this.#notifyElement.parentElement
    this.#notifyElement.classList.remove('show')
    this.#notifyElement.classList.add('is-hiding')
    this.#notifyElement.addEventListener('animationend', () => {
      this.#notifyElement.remove()
      this.onClose()
      if (wrapper.childElementCount === 0) {
        wrapper.remove()
      }
    })
  }
}

function createNotifyWrapper (position) {
  const container = document.createElement('div')
  container.classList.add('notify-wrapper')
  container.dataset.position = position
  document.body.appendChild(container)
  return container
}
