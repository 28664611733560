import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      stage: state => state.autotopup.stage
    })
  },
  methods: {
    isStageActive (stage) {
      return this.stage === stage
    }
  }
}
