const actions = {
  click: 'click',
  clicked: 'clicked',
  select: 'select',
  selected: 'selected',
  show: 'show',
  hide: 'hide'
}

export class PiwikClass {
  config = null
  observer = null
  debug = false

  constructor (config, debug = false) {
    this.config = config
    this.debug = debug
    this.showWarningIfNeeded()
  }

  showWarningIfNeeded () {
    if (typeof _paq === 'undefined') {
      console.warn('The _paq variable is not defined, check that the matomo script has been loaded correctly')
      window._paq = []
    }
  }

  findElement (conf) {
    let result = []
    let container = document
    if (!conf.element) {
      return result
    }
    if (conf.container) {
      container = container.querySelector(conf.container)
    }
    result = container.querySelectorAll(conf.element)
    if (conf.text) {
      result = [...result].filter(elmn => elmn.textContent.trim() === conf.text)
    }
    return result
  }

  addClickListeners () {
    this.config.forEach(conf => {
      if (conf.action !== actions.click && conf.action !== actions.select) {
        return
      }
      const elementArray = this.findElement(conf)
      if (!elementArray.length) {
        if (conf.func) {
          delete conf.func
          if (this.debug) {
            console.info('Element not exist anymore, removing listener function from: ' + conf.element + (conf.text ? ', text: ' + conf.text : ''))
          }
        }
        return
      }
      elementArray.forEach(element => {
        this.addClickListenerToElement(conf, element)
      })
    })
  }

  addClickListenerToElement (conf, element) {
    if (element.data && element.data.hasEventListener) {
      return
    }
    if (!conf.func && conf.action === actions.click) {
      conf.func = () => {
        this.trackEvent(conf.category, actions.clicked, conf.name)
      }
    }
    if (!conf.func && conf.action === actions.select) {
      conf.func = event => {
        if (event.target.checked) {
          this.trackEvent(conf.category, actions.selected, conf.name)
        }
      }
    }
    element.addEventListener(actions.click, conf.func)
    if (!element.data) {
      element.data = {}
    }
    element.data.hasEventListener = true
    if (this.debug) {
      console.info('Click listener added to element: ' + conf.element + (conf.text ? ', text: ' + conf.text : ''))
    }
  }

  removeClickListeners () {
    this.config.forEach(conf => {
      if (conf.action !== actions.click && conf.action !== actions.select) {
        return
      }
      const elementArray = this.findElement(conf)
      if (!elementArray.length || !conf.func) {
        return
      }
      elementArray.forEach(element => {
        element.removeEventListener(actions.click, conf.func)
        if (this.debug) {
          console.info('Click listener removed from element: ' + conf.element)
        }
      })
    })
  }

  checkVisibilityListeners () {
    this.config.forEach(conf => {
      if (conf.action !== actions.show && conf.action !== actions.hide) {
        return
      }
      if (!conf.resultsArray) {
        conf.resultsArray = []
      }
      const elementArray = this.findElement(conf)
      if (elementArray.length < conf.resultsArray.length) {
        if (conf.action === actions.hide) {
          for (let i = 0; i < conf.resultsArray.length - elementArray.length; i++) {
            this.trackEvent(conf.category, conf.action, conf.name)
          }
        }
      }
      if (elementArray.length > conf.resultsArray.length) {
        if (conf.action === actions.show) {
          for (let i = 0; i < elementArray.length - conf.resultsArray.length; i++) {
            this.trackEvent(conf.category, conf.action, conf.name)
          }
        }
      }
      conf.resultsArray = [...elementArray]
    })
  }

  startMutationObserver (elementToObserve) {
    elementToObserve = elementToObserve || document.body
    this.observer = new MutationObserver(() => {
      if (this.debug) {
        console.info('DOM changed')
      }
      this.addClickListeners()
      this.checkVisibilityListeners()
    })
    this.observer.observe(elementToObserve, { childList: true, subtree: true })
    if (this.debug) {
      console.info('DOM observation started on element: ' + this.observeEl)
    }
  }

  endMutationObserver () {
    if (this.observer) {
      this.observer.disconnect()
      if (this.debug) {
        console.info('DOM observation diconnected')
      }
    }
  }

  init (elementToObserve) {
    if (typeof MutationObserver === 'undefined') {
      return
    }
    this.startMutationObserver(elementToObserve)
    this.addClickListeners()
  }

  disable () {
    this.endMutationObserver()
    this.removeClickListeners()
  }

  trackEvent (category, action, name, value) {
    this.sendEvent('trackEvent', category, action, name, value)
  }

  sendEvent (type, category, action, name, value) {
    const eventData = [type, category, action]
    if (name) {
      eventData.push(name)
      if (value) {
        eventData.push(value)
      }
    }
    window._paq.push(eventData)
    if (this.debug) {
      console.log('Event sent, eventData => type: ' + type + ', category: ' + category + ', action: ' + action + ', name: ' + name + ', value: ' + value)
    }
  }
}
