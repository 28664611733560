const state = {
  lang: null
}

const mutations = {
  SET_LANGUAGE (state, value) {
    state.lang = value
  }
}

export default {
  state,
  mutations
}
