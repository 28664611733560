import { apiClass } from '@/common/class/api.class'
import { ENDPOINTS } from '@/common/const/endpoints.const'

const state = {
  loading: false,
  status: null
}

const mutations = {
  SET_NEWSLETTER_LOADING (state, value) {
    state.loading = value
  },
  SET_NEWSLETTER_STATUS (state, value) {
    state.status = value
  }
}

const actions = {
  sendNewsletterUnsubscribe ({ commit, state }, data) {
    const url = ENDPOINTS.user.newsletterUnsubscribe
      .replace('{EMAIL}', data.email)
      .replace('{HASH}', data.hash)
    commit('SET_NEWSLETTER_LOADING', true)
    return apiClass.get(url)
      .then(response => {
        commit('SET_NEWSLETTER_STATUS', response.data.status)
      })
      .finally(() => {
        commit('SET_NEWSLETTER_LOADING', false)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
