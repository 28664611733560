export const STATUS_OK = {
  status: 1
}
export const STATUS_ERROR = {
  status: -6
}
export const PAYMENT_OK = {
  status: 1,
  paymentStatus: 1
}
export const PAYMENT_WAIT = {
  status: 1,
  paymentStatus: 0
}
export const PAYMENT_ERROR = {
  status: 1,
  paymentStatus: -1
}

// USER
export const USER_NOT_LOGGED = {
  status: -12,
  loginUrl: 'https://doladuj.plus.pl/api/plksso/start'
}
export const USER_LOGGED = {
  status: 1,
  logoutUrl: 'https://doladuj-plus-test.blue.pl/api/test/plksso/end',
  email: 'mac.webcoder@gmail.com',
  firstName: 'Maciej',
  lastName: 'Walczak',
  lastMsisdn: '48790417154',
  lastAmount: 30.00,
  activePaymentMethod: {
    expirationDate: '2022-11-30 23:59',
    cardData: {
      validityYear: '2022',
      validityMonth: '11',
      issuer: 'VISA',
      mask: '4000'
    },
    status: 'ACTIVE'
  },
  regulationsAgreed: true
}
export const USER_NOT_LOGGED_INFO = STATUS_ERROR
export const USER_LOGGED_INFO = {
  status: 1,
  email: 'mac.webcoder@gmail.com'
}
export const USER_UPDATE_SUCCESS = STATUS_OK
export const USER_UPDATE_ERROR = STATUS_ERROR
export const USER_LOGIN_SUCCESS = {
  status: 1,
  userId: 1
}
export const USER_LOGIN_ERROR = STATUS_ERROR
export const USER_LOGOUT_SUCCESS = STATUS_OK
export const USER_LOGOUT_ERROR = STATUS_ERROR
export const USER_REGISTER_SUCCESS = STATUS_OK
export const USER_REGISTER_ERROR = STATUS_ERROR
export const USER_DELETE_SUCCESS = STATUS_OK
export const USER_DELETE_ERROR = STATUS_ERROR
export const USER_ACTIVATE_SUCCESS = STATUS_OK
export const USER_ACTIVATE_ERROR = STATUS_ERROR
export const USER_ACCOUNT_RECOVERY_SUCCESS = STATUS_OK
export const USER_ACCOUNT_RECOVERY_ERROR = STATUS_ERROR
export const USER_ACCOUNT_RECOVERY_CHECK_SUCCESS = STATUS_OK
export const USER_ACCOUNT_RECOVERY_CHECK_ERROR = STATUS_ERROR
export const USER_ACCOUNT_RECOVERY_SET_PASSWORD_SUCCESS = STATUS_OK
export const USER_ACCOUNT_RECOVERY_SET_PASSWORD_ERROR = STATUS_ERROR

// CONTACTS
export const USER_CONTACTS_EMPTY = {
  contacts: [],
  status: 1
}
export const USER_CONTACTS_LIST = {
  contacts: [
    {
      id: 1,
      msisdn: '48601001001',
      description: 'Radek',
      recurringTopup: {
        id: 1,
        day: 90,
        threshold: 20,
        amount: 25,
        recurringTopupType: 'DAY_INTERVAL',
        nextTopupDate: '2020-01-01'
      }
    },
    {
      id: 2,
      msisdn: '48602002002',
      description: 'Marek',
      recurringTopup: {
        id: 2,
        day: 25,
        threshold: 10,
        amount: 50,
        recurringTopupType: 'DAY_OF_MONTH',
        nextTopupDate: null
      }
    },
    {
      id: 3,
      msisdn: '48603003003',
      description: 'Paweł',
      recurringTopup: null
    }
  ],
  status: 1
}
export const USER_CONTACT_ADD_SUCCESS = {
  contractId: 12,
  status: 1
}
export const USER_CONTACT_ADD_ERROR = {
  contractId: 12,
  status: 1
}
export const USER_CONTACT_UPDATE_SUCCESS = STATUS_OK
export const USER_CONTACT_UPDATE_ERROR = STATUS_ERROR
export const USER_CONTACT_DELETE_SUCCESS = STATUS_OK
export const USER_CONTACT_DELETE_ERROR = STATUS_ERROR

// HISTORY
export const USER_TRANSACTIONS_EMPTY = {
  status: 1,
  transactions: []
}
export const USER_TRANSACTIONS_LIST = {
  status: 1,
  transactions: [
    {
      date: '2020-09-24 12:14',
      orderId: 'YY936PPAM2',
      paymentId: '1',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-28 10:27',
      orderId: 'YY936PPHHX',
      paymentId: '2',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-24 12:14',
      orderId: 'YY936PPAM2',
      paymentId: '3',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-28 10:27',
      orderId: 'YY936PPHHX',
      paymentId: '4',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-24 12:14',
      orderId: 'YY936PPAM2',
      paymentId: '5',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-28 10:27',
      orderId: 'YY936PPHHX',
      paymentId: '6',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-24 12:14',
      orderId: 'YY936PPAM2',
      paymentId: '7',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-28 10:27',
      orderId: 'YY936PPHHX',
      paymentId: '8',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-24 12:14',
      orderId: 'YY936PPAM2',
      paymentId: '9',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-28 10:27',
      orderId: 'YY936PPHHX',
      paymentId: '10',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-24 12:14',
      orderId: 'YY936PPAM2',
      paymentId: '11',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-28 10:27',
      orderId: 'YY936PPHHX',
      paymentId: '12',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-24 12:14',
      orderId: 'YY936PPAM2',
      paymentId: '13',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-28 10:27',
      orderId: 'YY936PPHHX',
      paymentId: '14',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-24 12:14',
      orderId: 'YY936PPAM2',
      paymentId: '15',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-28 10:27',
      orderId: 'YY936PPHHX',
      paymentId: '16',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-24 12:14',
      orderId: 'YY936PPAM2',
      paymentId: '17',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    },
    {
      date: '2020-09-28 10:27',
      orderId: 'YY936PPHHX',
      paymentId: '18',
      amount: 10.00,
      paymentStatus: 0,
      products: [
        {
          msisdn: '48601001001',
          amount: 10.00,
          status: 0
        }
      ]
    }
  ]
}

// NEWSLETTER
export const NEWSLETTER_UNSUBSCRIBE_SUCCESS = STATUS_OK
export const NEWSLETTER_UNSUBSCRIBE_ERROR = STATUS_ERROR

// PROMO
export const PROMO_GET_FOR_ORDER_SUCCESS = {
  status: 1,
  promoStatus: 1,
  rewardsGranted: 3,
  rewardsLimit: 2,
  email: 'test@autopay.pl'
}
export const PROMO_GET_FOR_ORDER_NO_PROMO = {
  status: 1,
  promoStatus: -2
}
export const PROMO_GET_FOR_ORDER_ERROR = STATUS_ERROR

// PHONE
export const PHONE_CHECKPHONE_SUCCESS = STATUS_OK
export const PHONE_CHECKTOKEN_SUCCESS = STATUS_OK
export const PHONE_TOPUP_SUCCESS = {
  status: 1,
  paymentLink: 'https://testpay.autopay.eu/payment/continue/9I79FVFT/MC7NQKNK',
  orderId: 'YY936PP5F9'
}
export const PHONE_TOPUP_ERROR = STATUS_ERROR

// AUTOTOPUP
export const AUTOTOPUP_ADD_SUCCESS = STATUS_OK
export const AUTOTOPUP_ADD_ERROR = STATUS_ERROR
export const AUTOTOPUP_UPDATE_SUCCESS = STATUS_OK
export const AUTOTOPUP_UPDATE_ERROR = STATUS_ERROR
export const AUTOTOPUP_REMOVE_SUCCESS = STATUS_OK
export const AUTOTOPUP_REMOVE_ERROR = STATUS_ERROR
export const AUTOTOPUP_REGULATIONS = {
  'status': 1,
  'regulations': {
    'id': 123,
    'url': 'https://testpay.autopay.eu/payment'
  }
}

// CREDIT CARD
export const CREDIT_CARD_ADD_SUCCESS = STATUS_OK
export const CREDIT_CARD_ADD_ERROR = STATUS_ERROR
export const CREDIT_CARD_PAYMENT_OK = PAYMENT_OK
export const CREDIT_CARD_PAYMENT_WAIT = PAYMENT_WAIT
export const CREDIT_CARD_PAYMENT_ERROR = PAYMENT_ERROR

// PAYWALL
export const PAYWALL_LIST = {
  'status': 1,
  'gatewayList': [
    {
      'gatewayId': 106,
      'gatewayName': 'PG płatność testowa',
      'iconUrl': 'https://platnosci.autopay.pl/pomoc/grafika/106.gif'
    },
    { 'gatewayId': 509, 'gatewayName': 'BLIK', 'iconUrl': 'https://platnosci.autopay.pl/pomoc/grafika/509.gif' },
    {
      'gatewayId': 1500,
      'gatewayName': 'PBC płatność testowa',
      'iconUrl': 'https://platnosci.autopay.pl/pomoc/grafika/1500.gif'
    },
    {
      'gatewayId': 1503,
      'gatewayName': 'PBC płatność automatyczna',
      'iconUrl': 'https://platnosci.autopay.pl/pomoc/grafika/1503.png'
    },
    {
      'gatewayId': 1899,
      'gatewayName': 'PG płatność testowa PIS',
      'iconUrl': 'https://platnosci.autopay.pl/pomoc/grafika/106.png'
    }]
}

// AUTOTOPUP ORANGE
export const AUTOTOPUP_ORANGE_CHECKPHONE_SUCCESS = {
  status: 1,
  operator: 'ORANGE'
}
export const AUTOTOPUP_ORANGE_CREATE_SMS_CODE_SUCCESS = {
  status: 1,
  smsCodeId: 1
}
export const AUTOTOPUP_ORANGE_CONFIRM_SMS_CODE_SUCCESS = STATUS_OK
export const AUTOTOPUP_ORANGE_MOBILE_RECURRING_TOPUP_SUCCESS = STATUS_OK
