import { ToolsClass } from '@/common/class/tools.class'

export const validateEmail = (rule, value, callback) => {
  if (!ToolsClass.isEmailValid(value)) {
    callback(new Error('E-mail not valid'))
    return
  }
  callback()
}

export const validatePhone = (rule, value, callback) => {
  if (!ToolsClass.isPhoneNumberValid(value)) {
    callback(new Error('Phone number not valid'))
    return
  }
  callback()
}

export const validateCheckbox = (rule, value, callback) => {
  if (value !== true) {
    callback(new Error('Field is required'))
    return
  }
  callback()
}

export const validatePassword = function (rule, value, callback) {
  if (value !== this.form.password) {
    callback(new Error('Passwords do not match'))
    return
  }
  callback()
}

export const validatePasswordStrength = (rule, value, callback) => {
  if (!ToolsClass.isPasswordStrength(value)) {
    callback(new Error('Password too weak'))
    return
  }
  callback()
}
