import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      userData: state => state.user.data
    }),
    user: function () {
      return [{
        email: this.userData.email
      }]
    }
  },
  methods: {
    ...mapActions([
      'doUserAccountRemove'
    ]),
    changePassword () {
      this.$router.push({ name: 'changePasswordForLoggedUser' })
    },
    removeAccount () {
      this.$confirm(this.$t('component.userData.account.messages.removeConfirm'), this.$t('component.userData.account.messages.removeHeader'), {
        confirmButtonText: this.$t('component.userData.account.messages.removeConfirmButton'),
        cancelButtonText: this.$t('component.userData.account.messages.removeCancelButton'),
        type: 'info'
      })
        .then(() => {
          this.doUserAccountRemove()
            .then(() => {
              this.$notify.success({
                message: this.$t('component.userData.account.messages.removeSuccess')
              })
              this.$router.push({ name: 'singleTopup' })
            })
            .catch(() => {
              this.$notify.error({
                message: this.$t('component.userData.account.messages.removeError')
              })
            })
        })
        .catch(() => {})
    }
  }
}
