export const BANNERS_VISIBILITY = {
  all: 'ALL',
  logged: 'LOGGED',
  notLogged: 'NOTLOGGED'
}

export const BANNERS_TARGET = {
  blank: '_blank',
  self: '_self'
}

export const BANNERS_CONFIG_COMMON = {
  rotationTime: 3000,
  path: '',
  images: null,
  defaultLang: 'pl'
}
